<template>
  <div id="listado-generico">
    <div class="mt-2"></div>

    <div>
      <v-data-table
        :headers="encabezados"
        :items="itemsFiltrados"
        :items-per-page="itemsPorPagina"
        :loading="isLoading"
        loading-text="Cargando datos... Por favor espere"
        class="elevation-1"
      >
        <template v-slot:top>
          <div class="mx-4">
            <v-row>
              <v-text-field
                v-model="busqueda"
                label="Buscar"
                ref="txtBuscar"
                :append-icon="iconoCrear"
                @click:append="crear()"
              ></v-text-field>
            </v-row>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" v-if="esRegistrar" @click="seleccionar(item)"
            >mdi-pencil</v-icon
          >
          <v-icon color="primary" v-else @click="seleccionar(item)"
            >mdi-share</v-icon
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "listado-generico",
  data() {
    return {
      busqueda: "",
      iconoCrear: "mdi-plus",
      isLoading: false,
    };
  },
  props: {
    items: Array,
    esRegistrar: Boolean, //Propiedad que decide si mostrar el boton editar o el boton de seleccionar.
    encabezados: Array,
    itemsPorPagina: Number,
  },
  computed: {
    rows() {
      return this.items.length;
    },
    itemsFiltrados: function() {
      return this.items.filter((item) => {
        return item.descripcion
          .toUpperCase()
          .includes(this.busqueda.toUpperCase());
      });
    },
  },
  methods: {
    crear() {
      this.$emit("crearItem", null);
    },
    seleccionar(itemParam) {
      this.$emit("seleccionarItem", itemParam);
    },
  },
  watch: {
    items: function(val) {
      this.isLoading = val.length > 0 ? false : true;
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
    },
  },
};
</script>
