<template>
  <v-container id="pacientes">
    <h1>Pacientes</h1>

    <v-layout>
      <RegistroCita
        :dialogRegistroCitas.sync="dialogRegistroCitas"
        :paciente="paciente"
        :camposPacienteDeshabilitados="true"
        @cerrarModalCitas="cerrarModalCitas"
      ></RegistroCita>
    </v-layout>

    <v-layout>
      <RegistroPacientes
        :paciente="paciente"
        :dialogPaciente="dialogPaciente"
        @cerrarModalPaciente="cerrarModalPaciente"
        @limpiarPaciente="limpiarPaciente"
        @onPacienteGuardado="onPacienteGuardado"
      ></RegistroPacientes>
    </v-layout>

    <v-layout>
      <ListadoPacientes
        @crearPaciente="crearPaciente"
        @editarPaciente="editarPaciente"
        @asignarTurno="asignarTurno"
        @registrarCita="registrarCita"
        @onPacienteEliminado="onPacienteEliminado"
        @onBuscarPaciente="buscarPaciente"
        @mostrarConsultasAnteriores="mostrarConsultasAnteriores"
        @resaltarPaciente="resaltarPaciente"
        :esRegistrar="true"
        :itemsPorPagina="itemsPorPagina"
        :encabezados="encabezados"
        :pacientes="pacientes"
      ></ListadoPacientes>
    </v-layout>

    <v-layout>
      <ConsultasAnteriores
        :paciente="paciente"
        :dialogConsultasAnteriores="dialogConsultasAnteriores"
        :historiasClinicas="historiasClinicas"
        @cerrarModalConsultasAnteriores="cerrarModalConsultasAnteriores"
        @onHistoriaClinicaCancelada="onHistoriaClinicaCancelada"
      ></ConsultasAnteriores>
    </v-layout>
  </v-container>
</template>

<script>
//COMPONENTS
import ConsultasAnteriores from "@/components/historiasClinicasGroup/ConsultasAnteriores.vue";
import HistoriasClinicas from "@/components/historiasClinicasGroup/HistoriasClinicas.vue";
import ListadoPacientes from "./ListadoPacientes.vue";
import RegistroCita from "@/components/cita/RegistroCita.vue";
import RegistroPacientes from "./RegistroPacientes.vue";
import Overlay from "../helpers/Overlay.vue";

import MainLayout from "@/layouts/MainLayout";
import axios from "@/shared/jwtAxios.js";
import swal from "sweetalert";
import store from "@/state/index.js";

export default {
  components: {
    ConsultasAnteriores,
    HistoriasClinicas,
    ListadoPacientes,
    RegistroCita,
    RegistroPacientes,
    Overlay,
  },
  name: "pacientes",

  created() {
    this.getPacientes("");
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  data() {
    return {
      //PACIENTES
      benched: 0, //PROPIEDAD UTILIZADA PARA EL V-VIRTUAL-SCROLL. FUNCIONA PARA MEJORAR EL PERFORMANCE DE DICHO CONTROL.
      url: this.$apiConfig.pacientesUrl,
      citaUrl: this.$apiConfig.citaUrl,
      turnosUrl: this.$apiConfig.turnosUrl,
      dialogPaciente: false,
      esRegistrar: true,
      itemsPorPagina: 50,
      pacientes: [],
      encabezados: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" },
        { text: "Apellidos", value: "apellidos" },
        { text: "Seguro Médico", value: "seguroMedico.descripcion" },
        { text: "", value: "actions", sortable: false },
      ],
      paciente: {
        id: "",
        nombre: "",
        primerApellido: "",
        cedula: "",
        sexo: "",
        fechaNacimiento: "",
        ocupacion: "",
        celular: "",
        telefono: "",
        direccion: "",
        correoElectronico: "",
        numeroAfiliado: "",
        estadoCivil: {
          id: 0,
          descripcion: "",
        },
        seguroMedico: {
          id: 0,
          descripcion: "",
        },
      },
      //CITAS
      citas: [],
      dialogRegistroCitas: false,
      //CONSULTAS ANTERIORES
      dialogConsultasAnteriores: false,
      //HISTORIA CLINICA
      historiaClinicaUrl: this.$apiConfig.historiasClinicasUrl,
      dialogHistoriaClinica: false,
      historiasClinicas: [],
      historiaClinica: {
        motivoConsulta: "",
        historiaEnfermedad: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        antecedentesQuirurgicos: "",
        antecedentesFamiliares: "",
        antecedentesGinecologicos: "",
        antecedentesOtros: "",
        sonografias: "",
        radiografias: "",
        laboratorios: "",
        otrosEstudios: "",
        peso: "",
        talla: "",
        temperatura: "",
        tensionArterial: "",
        frecuenciaRespiratoria: "",
        inspeccionGeneral: "",
        medicamentosIndicados: [],
        laboratoriosIndicados: [],
        imagenesIndicadas: [],
      },
    };
  },
  methods: {
    //PACIENTES
    cerrarModalPaciente() {
      this.dialogPaciente = false;
    },

    crearPaciente() {
      this.limpiarPaciente();
      this.esRegistrar = true;
      this.mostrarModalPaciente();
    },

    editarPaciente(pacienteParam) {
      this.paciente = pacienteParam;
      this.paciente.fechaNacimiento = new Date(this.paciente.fechaNacimiento)
        .toISOString()
        .substr(0, 10);
      this.esRegistrar = false;
      this.mostrarModalPaciente();
    },

    limpiarPaciente() {
      this.paciente = {
        id: "",
        cuentaId: 0,
        nombre: "",
        primerApellido: "",
        cedula: "",
        sexo: "",
        fechaNacimiento: "",
        ocupacion: "",
        celular: "",
        telefono: "",
        direccion: "",
        correoElectronico: "",
        numeroAfiliado: "",
        estadoCivil: {
          id: 0,
          descripcion: "",
        },
        seguroMedico: {
          id: 0,
          descripcion: "",
        },
      };
    },

    mostrarModalPaciente() {
      this.dialogPaciente = true;
    },

    //CITAS
    cerrarModalCitas() {
      this.dialogRegistroCitas = false;
    },

    registrarCita(pacienteParam) {
      this.paciente = pacienteParam;
      this.dialogRegistroCitas = true;
    },

    getPacientes(busqueda) {
      this.$store.commit("activateViewOverlay", true);
      axios
        .get(this.url + "/getPacientesByCuenta", {
          params: {
            cuentaId: this.$store.state.cuentaId,
            nombrePaciente: busqueda,
            cedula: busqueda  
          },
        })
        .then((response) => {
          this.pacientes = response.data || [];
          this.$store.commit("activateViewOverlay", false);
        })
        .catch((error) => {
          this.$store.commit("activateViewOverlay", false);
          window.console.log(error.title);
        });
    },

    buscarPaciente(busqueda) {
      this.getPacientes(busqueda);
    },

    //HISTORIAS CLINICAS
    cerrarModalHistoriaClinica() {
      this.dialogHistoriaClinica = false;
    },

    asignarTurno(pacienteParam) {
      let turnoPacienteRequest = {
        cuentaId: store.state.cuentaId,
        pacienteId: pacienteParam.id,
      };
      axios
        .post(this.turnosUrl, turnoPacienteRequest)
        .then(() => {
          swal({
            title: "Información",
            text: "Turno asignado correctamente.",
            icon: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            swal({
              title: "Aviso",
              text: error.response.data,
              icon: "warning",
            });
          } else {
            window.console.log(error.message);
          }
        });
    },
    resaltarPaciente(pacienteParam) {
      axios
        .post(`${this.url}/resaltarPaciente/${pacienteParam.id}`)
        .then(() => {
          if (pacienteParam.resaltado === true) pacienteParam.resaltado = false;
          else pacienteParam.resaltado = true;
          swal({
            title: "Información",
            text: "¡Datos guardados correctamente!",
            icon: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            swal({
              title: "Aviso",
              text: error.response.data,
              icon: "warning",
            });
          } else {
            window.console.log(error.message);
          }
        });
    },

    limpiarHistoriaClinica() {
      this.historiaClinica = {
        motivoConsulta: "",
        historiaEnfermedad: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        antecedentesQuirurgicos: "",
        antecedentesFamiliares: "",
        antecedentesGinecologicos: "",
        antecedentesOtros: "",
        sonografias: "",
        radiografias: "",
        laboratorios: "",
        otrosEstudios: "",
        peso: "",
        talla: "",
        temperatura: "",
        tensionArterial: "",
        frecuenciaCardiaca: "",
        frecuenciaRespiratoria: "",
        inspeccionGeneral: "",
        medicamentosIndicados: [],
        laboratoriosIndicados: [],
        imagenesIndicadas: [],
      };
    },

    mostrarModalHistoriaClinica() {
      this.dialogHistoriaClinica = true;
    },

    onPacienteGuardado() {
      this.getPacientes("");
    },

    onPacienteEliminado() {
      this.getPacientes("");
    },

    onHistoriaClinicaCancelada() {
      this.getConsultasAnteriores();
    },

    //CONSULTAS ANTERIORES
    cerrarModalConsultasAnteriores() {
      this.dialogConsultasAnteriores = false;
    },

    getConsultasAnteriores(pacienteParam) {
      axios
        .get(this.historiaClinicaUrl + "/getByPaciente/" + pacienteParam.id)
        .then(({ data }) => {
          this.historiasClinicas = data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    mostrarConsultasAnteriores(pacienteParam) {
      this.dialogConsultasAnteriores = true;
      this.getConsultasAnteriores(pacienteParam);
    },
  },
};
</script>
