const modosImpresion = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

const colors = {
  PRIMARY: "#0070AD",
  SECONDARY: "#c52121",
  ACCENT: "#706BBA",
  ERROR: "#c52121",
  INFO: "#2196F3",
  SUCCESS: "#4CAF50",
  WARNING: "#FFC107",
};

const contextoPlantillaIndicacion = {
  MEDICAMENTO: "Medicamentos",
  IMAGEN: "Imagenes",
  LABORATORIO: "Laboratorios",
};

const tipoMensajeEnum = {
  ERROR: "error",
  SUCCESS: "success",
};

const estadoCita = {
  CANCELADA: "CANCELADA",
  CONFIRMADA: "CONFIRMADA",
  PENDIENTE: "PENDIENTE",
  REALIZADA: "REALIZADA",
};

const tipoPlan = {
  GRATIS: "GRATIS",
  BASICO: "BASICO",
  AVANZADO: "AVANZADO",
};

const extensionesArchivos = {
  WORD: ".docx",
  DOC: ".doc",
  EXCEL: ".xlsx",
  PPOINT: ".pptx",
  PDF: ".pdf",
  RTF: ".rtf",
  PNG: ".png",
  JPG: ".jpg",
  JPEG: ".jpeg",
  GIF: ".gif",
  RAW: ".raw",
  TIFF: ".tiff",
  PSD: ".psd",
  TXT: ".txt",
  JFIF: ".jfif",
};

const grupoArchivo = {
  DOCUMENTOS: "DOCUMENTOS",
  IMAGENES: "IMAGENES",
};

const iconosOnline = {
  WORD: "https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_word-256.png",
  EXCEL:
    "https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_excel-256.png",
  PPOINT:
    "https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_powerpoint-256.png",
  PDF: "https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-256.png",
  TXT: "https://cdn2.iconfinder.com/data/icons/file-types-3/32/file_TXT-512.png",
};

export {
  modosImpresion,
  colors,
  contextoPlantillaIndicacion,
  tipoMensajeEnum,
  estadoCita,
  tipoPlan,
  extensionesArchivos,
  iconosOnline,
  grupoArchivo,
};
