<template>
  <v-container id="expediente-digital">
    <v-row>
      <subir-archivos
        :paciente="paciente"
        @onArchivosSubidos="onArchivosSubidos"
      ></subir-archivos>
    </v-row>

    <v-row>
      <v-container>
        <v-row>
          <v-col>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(grupo, i) in gruposArchivosComputed"
                :key="i"
              >
                <v-expansion-panel-header>
                  {{ grupo }}
                </v-expansion-panel-header>

                <v-row class="d-flex" justify="center">
                  <v-col
                    cols="3"
                    v-for="archivo in getArchivosByGrupo(grupo)"
                    :key="archivo.id"
                  >
                    <v-expansion-panel-content>
                      <v-card
                        :ripple="false"
                        :img="getRutaArchivo(archivo.ruta)"
                        height="200px"
                        width="200px"
                        @contextmenu.prevent="showContextMenu($event, archivo)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row> </template
                      ></v-card>

                      <v-menu
                        v-model="showMenu"
                        :position-x="x"
                        :position-y="y"
                        absolute
                        offset-y
                      >
                        <v-list>
                          <v-list-item @click="abrirArchivo">
                            <v-list-item-title>Ver</v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title @click="eliminarArchivo"
                              >Eliminar</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <label>{{ archivo.comentario }}</label>
                    </v-expansion-panel-content>
                  </v-col>
                </v-row>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="text-center">
      <v-dialog v-model="dialog" max-width="800">
        <img :src="documentoSeleccionado" ref="contenidoImagen" alt="" />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import JwtAxios from "@/shared/jwtAxios";
import SubirArchivos from "./SubirArchivos.vue";
import { getFileExtension, archivoEsDocumento } from "@/utility/stringHelpers";
import { extensionesArchivos, iconosOnline } from "@/utility/enumerations";

export default {
  name: "expediente-digital",
  props: {
    paciente: Object,
  },
  components: {
    SubirArchivos,
  },
  mounted: async function () {
    await this.getArchivos();
  },
  data() {
    return {
      dialog: false,
      showMenu: false,
      archivoSeleccionadoEsDocumento: false,
      expediente: {
        archivos: [],
      },
      rutasArchivosShow: [],
      documentoSeleccionado: "",
      archivoSeleccionado: {},
      x: 0,
      y: 0,
    };
  },
  methods: {
    async getArchivos() {
      try {
        let response = await JwtAxios.get(
          this.$apiConfig.expedienteDigitalUrl + "/" + this.paciente.id,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
              "Access-Control-Allow-Headers": "Content-Type",
            },
          }
        );
        if (response.status === 200) {
          this.expediente = null;
          this.expediente = response.data;
        }
      } catch (error) {
        window.console.log("Error: " + error);
      }
    },
    getArchivosByGrupo(grupoArchivo) {
      return this.expediente.archivos
        .filter((archivo) => archivo.grupo === grupoArchivo)
        .map((archivo) => archivo);
    },
    getRutaArchivo(ruta) {
      let rutaFinal = "";
      switch (this.getExtensionArchivo(ruta)) {
        case extensionesArchivos.WORD:
        case extensionesArchivos.DOC:
        case extensionesArchivos.RTF:
          rutaFinal = iconosOnline.WORD;
          break;
        case extensionesArchivos.EXCEL:
          rutaFinal = iconosOnline.EXCEL;
          break;
        case extensionesArchivos.PPOINT:
          rutaFinal = iconosOnline.PPOINT;
          break;
        case extensionesArchivos.PDF:
          rutaFinal = iconosOnline.PDF;
          break;
        case extensionesArchivos.TXT:
          rutaFinal = iconosOnline.TXT;
          break;
        case extensionesArchivos.PNG:
        case extensionesArchivos.JPG:
        case extensionesArchivos.JPEG:
        case extensionesArchivos.GIF:
        case extensionesArchivos.RAW:
        case extensionesArchivos.TIFF:
        case extensionesArchivos.JFIF:
          rutaFinal = ruta;
          break;
      }
      return rutaFinal;
    },
    getExtensionArchivo(ruta) {
      return getFileExtension(ruta);
    },
    abrirArchivo() {
      if (
        archivoEsDocumento(
          this.getExtensionArchivo(this.archivoSeleccionado.ruta)
        )
      ) {
        window.open(this.archivoSeleccionado.ruta, "_blank").focus();
      } else {
        this.dialog = true;
        this.documentoSeleccionado = this.archivoSeleccionado.ruta;
      }
    },
    eliminarArchivo() {
      try {
        this.$pregunta("Desea eliminar este archivo?").then(async (si) => {
          if (si) {
            let response = await JwtAxios.post(
              this.$apiConfig.expedienteDigitalUrl +
                "/eliminarArchivo/" +
                `${this.archivoSeleccionado.id}`
            );
            if (response.status === 200) {
              this.$success("Archivo eliminado correctamente.");
              await this.getArchivos();
            }
          }
        });
      } catch (e) {
        window.console.log(e);
      }
    },
    showContextMenu(e, archivo) {
      this.archivoSeleccionado = archivo;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    onArchivosSubidos() {
      this.getArchivos();
      //this.$emit("onArchivosSubidos");
    },
  },
  computed: {
    gruposArchivosComputed() {
      const gruposArchivos = new Set();
      this.expediente.archivos.forEach((archivo) =>
        gruposArchivos.add(archivo.grupo)
      );
      return Array.from(gruposArchivos);
    },
  },
};
</script>

<style scoped>
img {
  object-fit: cover;
}
</style>
