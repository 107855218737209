<template>
  <v-container>
    <mensaje v-bind:mensaje="mensajeError" v-bind:tipo-mensaje="tipoMensaje"></mensaje>
    <v-form
      @submit.prevent="guardar"
      lazy-validation
      ref="formCambiarContrasena"
    >
      <v-text-field
        outlined
        dense
        ref="txtNuevaContrasena"
        prepend-icon="lock"
        :type="inputType"
        label="Nueva contraseña"
        v-model="nuevaContrasena"
        :rules="nuevaContrasenaRules"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        prepend-icon="lock"
        :type="inputType"
        label="Confirmar nueva contraseña"
        v-model="confirmarNuevaContrasena"
        :rules="confirmarNuevaContrasenaRules"
      ></v-text-field>
      <v-checkbox
        v-model="mostrarContrasena"
        label="Mostrar contraseña"
      ></v-checkbox>
      <v-col class="text-right">
        <v-btn outlined rounded color="primary" @click="guardar">Guardar</v-btn>
      </v-col>
    </v-form>
    <overlay :overlay="overlay"></overlay>
  </v-container>
</template>

<script>
import axios from "axios";
import Mensaje from "../helpers/Mensaje.vue";
import Overlay from "../helpers/Overlay.vue";

export default {
  name: "cambiar-contrasena",
  components: {
    Mensaje,
    Overlay,
  },
  mounted() {
    this.$refs.txtNuevaContrasena.focus();
  },
  data() {
    return {
      nuevaContrasena: "",
      confirmarNuevaContrasena: "",
      mostrarContrasena: false,
      inputType: "password",
      nuevaContrasenaRules: [(v) => !!v || "La contraseña es requerida."],
      confirmarNuevaContrasenaRules: [
        (v) => !!v || "Digite una contraseña de verificación.",
        (v) => v === this.nuevaContrasena || "Las contraseñas no coinciden.",
      ],
      overlay: false,
      mensajeError: "",
      tipoMensaje: ""
    };
  },
  methods: {
    guardar() {
      if (this.$refs.formCambiarContrasena.validate()) {
        let contrasenaRequest = {
          email: localStorage.getItem("@email"),
          contrasena: this.nuevaContrasena,
        };
        axios
          .put(
            `${this.$apiConfig.usuariosUrl}/CambiarContrasena`,
            contrasenaRequest
          )
          .then(() => {
            contrasenaRequest = null;
            localStorage.removeItem("@email");
            this.onCambiarContrasena();
            this.mensajeError = "";
          })
          .catch((error) => {
            window.console.log(error.response.data);
            this.$emit("onCambiarContrasena", 2);
            this.tipoMensaje = "error";
            this.mensajeError = "Error al intentar actualizar su contraseña.";
          });
      }
    },
    onCambiarContrasena() {
      this.overlay = true;
    },
  },
  watch: {
    mostrarContrasena: function(value) {
      if (value) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
          this.$emit("onCambiarContrasena", 1);
        }, 4000);
    },
  },
};
</script>

<style></style>
