<template>
  <v-container id="configuracion-reporte">
    <v-card class="pa-2">
      <v-radio-group v-model="modoImpresion">
        <v-row>
          <v-col md="4">
            <v-radio @click="seleccionarModo(1)" id="vertical" label="Vertical" value="vertical"></v-radio>
            <v-icon :size="iconSize">mdi-crop-portrait</v-icon>
          </v-col>
          <v-col md="4">
            <v-radio
              @click="seleccionarModo(2)"
              id="horizontal"
              label="Horizontal"
              value="horizontal"
            ></v-radio>
            <v-icon :size="iconSize">mdi-crop-landscape</v-icon>
          </v-col>
        </v-row>
      </v-radio-group>
      <v-card-subtitle>
        <p class="body-1">
          <b>Nota:</b> esta configuración es válida para los reportes de las indicaciones (Imágenes, laboratorios, medicamentos, etc.)
        </p>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import axios from "../../shared/jwtAxios.js";
import { modosImpresion } from "../../utility/enumerations";
import store from "../../state/index.js";


export default {
  name: "configuracion-reporte",
  created() {
    this.getConfiguracionReporte();
  },
  data() {
    return {
      iconSize: 200,
      modoImpresion: null,
      id: 0,
      landscape: null
    };
  },
  methods: {
    guardar() {
      let configuracion = {
        id: this.id,
        cuentaId: store.getters.getCuentaId,
        landscape: this.landscape
      };
      axios
        .post(
          `${this.$apiConfig.configuracionUrl}/postConfiguracionReporte`,
          configuracion
        )
        .then(() => {
          this.$datosGuardados();
          this.getConfiguracionReporte();
        })
        .catch(error => {
          window.console.log(error);
        });
    },
    seleccionarModo(numero) {
      switch (numero) {
        case 1:
          this.landscape = false;
          break;
        case 2:
          this.landscape = true;
          break;
        default:
          break;
      }
      this.guardar();
    },
    getConfiguracionReporte() {
      store.dispatch("getConfiguracionReporte").then(response => {
        store.commit("setConfiguracionReporte", response.data);
        this.id = store.state.configuracionReporte.id;
        this.landscape = store.state.configuracionReporte.landscape;
      });
    }
  },
  watch: {
    landscape: function(newValue) {
      this.modoImpresion =
        newValue == true ? modosImpresion.HORIZONTAL : modosImpresion.VERTICAL;
    }
  }
};
</script>

<style></style>
