<template>
  <v-dialog v-model="dialog" max-width="600px" lazy-validation @click:outside="cerrarModal()">
    <v-simple-table fixed-header height="300px">
      <template v-slot:top>
        <v-col>
          <h3>Plantillas</h3>
          <v-text-field ref="txtBuscar" label="Buscar" v-model="busqueda"></v-text-field>
        </v-col>
      </template>

      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Descripción</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in plantillasFiltradas" :key="item.id">
            <td>{{ item.descripcion }}</td>
            <td>
              <v-icon align-right color="primary" @click="seleccionarItem(item)">mdi-share</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-dialog>
</template>

<script>
import swal from "sweetalert";
import jwtAxios from "../../../../shared/jwtAxios";
import { contextoPlantillaIndicacion } from "../../../../utility/enumerations.js";

export default {
  name: "listado-plantillas",
  props: {
    contexto: "",
    dialog: false
  },
  data() {
    return {
      plantillas: [],
      url: "",
      busqueda: ""
    };
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrarModal");
    },
    get(url) {
      if (url !== null && url !== undefined) {
        jwtAxios
          .get(`${url}/${this.$store.getters.getCuentaId}`)
          .then(response => {
            this.plantillas = response.data;
          })
          .catch(this.mostrarError);
      } else {
        window.console.log("url vacía.");
        swal({
          title: "Advertencia!",
          text: "La fuente de datos no existe.",
          icon: "warning",
          buttons: {
            confirm: {
              text: "Si",
              value: true,
              closeModal: true
            }
          }
        });
      }
    },
    mostrarError(error) {
      window.console.log(error);
      this.$errorCargarDatos();
    },
    seleccionarItem(item) {
      this.$emit("onItemSeleccionado", item);
      this.cerrarModal();
      this.busqueda = "";
    },
    focusBuscar() {
      this.$refs.txtBuscar.$refs.input.focus();
    }
  },
  computed: {
    plantillasFiltradas() {
      return this.plantillas.filter(p => {
        return p.descripcion
          .toLowerCase()
          .includes(this.busqueda.toLowerCase());
      });
    }
  },
  watch: {
    contexto: function(value) {
      switch (value) {
        case contextoPlantillaIndicacion.MEDICAMENTO:
          this.url = this.$apiConfig.plantillaIndicacionMedicamentos;
          break;
        case contextoPlantillaIndicacion.IMAGEN:
          this.url = this.$apiConfig.plantillaIndicacionImagenes;
          break;
        case contextoPlantillaIndicacion.LABORATORIO:
          this.url = this.$apiConfig.plantillaIndicacionLaboratorios;
          break;
      }
    },
    dialog: function(value) {
      if (value) {
        setTimeout(() => {
          this.focusBuscar();
        });
      }
    },
    url: {
      handler: function(value) {
        this.get(value);
      },
      deep: true
    }
  }
};
</script>

<style></style>
