<template>
  <div class="login-register-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "login-register-layout",
};
</script>

<style scoped>
.login-register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
