<template>
  <v-container id="container">
    <v-row>
      <v-col cols="8" md="6" sm="6">
        <div class="logo-container">
          <img :src="logo" class="logo" alt />
          <p class="app-description"></p>
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-card elevation="10">
          <v-stepper alt-labels v-model="step">
            <v-stepper-header>
              <v-stepper-step step="1" editable :complete="step > 1">
                Registro
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" editable :complete="step > 2">
                Verificación
              </v-stepper-step>

              <v-divider></v-divider>
              
            </v-stepper-header>

            <v-stepper-items>
              <!--REGISTRO-->
              <v-stepper-content step="1">
                <v-form
                  @submit.prevent="registrar"
                  ref="formregistrar"
                  lazy-validation
                >
                  <v-container id="containerregistrar">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="nombreRules"
                          prepend-icon="person"
                          v-model="usuario.nombre"
                          label="Nombre"
                          id="txtNombre"
                          ref="txtNombre"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          dense
                          :rules="apellidoRules"
                          v-model="usuario.apellido"
                          prepend-icon="person"
                          label="Apellido"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-text-field
                      outlined
                      dense
                      :rules="telefonoRules"
                      v-model="usuario.telefono"
                      v-mask="'(###)-###-####'"
                      prepend-icon="phone"
                      label="Teléfono"
                    ></v-text-field>

                    <v-text-field
                      outlined
                      dense
                      :rules="emailRules"
                      v-model="usuario.email"
                      prepend-icon="email"
                      type="email"
                      label="E-mail"
                    ></v-text-field>

                    <mensaje
                      v-bind:mensaje="mensajeError"
                      v-bind:tipo-mensaje="tipoMensaje"
                    ></mensaje>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          dense
                          prepend-icon="lock"
                          :type="inputType"
                          :rules="contrasenaRules"
                          v-model="usuario.contrasena"
                          label="Contraseña"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          dense
                          prepend-icon="lock"
                          :type="inputType"
                          :rules="confirmarContrasenaRules"
                          label="Confirmar contraseña"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-checkbox
                      v-model="mostrarContrasena"
                      label="Mostrar contraseña"
                    ></v-checkbox>

                    <v-row justify="space-between">
                      <v-col>
                        <v-btn @click="login" text color="primary"
                          >Ir al login</v-btn
                        >
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-col>
                        <v-btn
                          outlined
                          rounded
                          block
                          type="submit"
                          color="primary"
                          >Registrarse</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>

              <!--CODIGO VERIFICADOR-->
              <v-stepper-content step="2">
                <verificar-codigo
                  @onCodigoVerificado="onCodigoVerificado"
                ></verificar-codigo>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <overlay :overlay="this.$store.getters.viewOverlay"></overlay>
  </v-container>
</template>

<script>
import LoginRegisterLayout from "@/layouts/LoginRegisterLayout.vue";
import VerificarCodigo from "./VerificarCodigo.vue";
import Mensaje from "../helpers/Mensaje.vue";
import Overlay from "../helpers/Overlay.vue";
import store from "@/state/index.js";
import axios from "axios";
import { tipoMensajeEnum, tipoPlan } from "@/utility/enumerations";
import { emailRegex, telefonoRegex } from "@/utility/validaciones";
const Logo = require("@/assets/logo1.png");

export default {
  name: "registrar",
  components: {
    LoginRegisterLayout,
    VerificarCodigo,
    Mensaje,
    Overlay,
  },
  mounted() {
    this.$emit("update:layout", LoginRegisterLayout);
    this.$refs.txtNombre.focus();
    this.getPlanes();
  },
  data() {
    return {
      step: 1,
      planesStep: 1,
      mostrarContrasena: false,
      inputType: "password",
      mensajeError: "",
      tipoMensaje: "",
      usuario: {
        nombre: "",
        apellido: "",
        email: "",
        contrasena: "",
        telefono: "",
      },
      logo: Logo,
      cuentaRequest: {
        nombre: "",
        apellido: "",
        email: "",
        codigo: "",
        plandId: 0,
        telefono: "",
      },
      usuarioRequest: {
        cuentaId: 0,
        nombre: "",
        apellido: "",
        email: "",
        contrasena: "",
        telefono: "",
      },
      planes: [],
      planSeleccionado: {},

      //VALIDACIONES
      nombreRules: [(v) => !!v || "El nombre es requerido."],
      apellidoRules: [(v) => !!v || "El apellido es requerido."],
      emailRules: [
        (v) => !!v || "El E-mail es requerido.",
        (v) => emailRegex.test(v) || "Digite un E-mail válido.",
      ],
      telefonoRules: [
        (v) => !!v || "El teléfono es requerido.",
        (v) => telefonoRegex.test(v) || "Digite un teléfono válido.",
      ],
      contrasenaRules: [(v) => !!v || "La contraseña es requerida."],
      confirmarContrasenaRules: [
        (v) => !!v || "Digite una contraseña de verificación.",
        (v) => v === this.usuario.contrasena || "Las contraseñas no coinciden.",
      ],
    };
  },
  methods: {
    registrar() {
      if (this.$refs.formregistrar.validate()) {
        this.$store
          .dispatch("Registrar", this.usuario)
          .then(() => {
            this.step = 2;
          })
          .catch((error) => {
            this.mostrarMensaje(error.response.data, tipoMensajeEnum.ERROR);
            window.console.log(error);
          });
      }
    },
    setUsuarioRequest() {
      this.usuarioRequest.nombre = this.usuario.nombre;
      this.usuarioRequest.apellido = this.usuario.apellido;
      this.usuarioRequest.email = this.usuario.email;
      this.usuarioRequest.contrasena = this.usuario.contrasena;
    },
    setCuentaRequest() {
      this.cuentaRequest.nombre = this.usuario.nombre;
      this.cuentaRequest.apellido = this.usuario.apellido;
      this.cuentaRequest.email = this.usuario.email;
      this.cuentaRequest.telefono = this.usuario.telefono;
	  
	  let plan = null;
	  if(this.planes !== null || this.planes !== undefined){
		  plan = this.planes.find(p => p.descripcion.toLowerCase() === tipoPlan.GRATIS.toLowerCase());
	  }
	  this.cuentaRequest.planId = plan === null ? 1 : plan.id;
    },

    login() {
      this.$router.push("login");
    },
    onCodigoVerificado() {
	     this.guardar();
    },
    onPagoExitoso() {
      this.guardar();
    },
    async guardar() {
      try {
        store.commit("activateViewOverlay", true);
        this.setCuentaRequest();
        
        window.console.log(this.cuentaRequest)

        let cuentaResponse = await axios.post(
          this.$apiConfig.cuentasUrl,
          this.cuentaRequest
        );
        this.setUsuarioRequest();
        this.usuarioRequest.cuentaId = cuentaResponse.data;

        window.console.log(this.usuarioRequest)


        await axios.post(
          `${this.$apiConfig.usuariosUrl}/Registro`,
          this.usuarioRequest
        );
        store.commit("activateViewOverlay", false);
        await this.$router.push("login").catch((error) => error);
        localStorage.clear();
      } catch (error) {
		  store.commit("activateViewOverlay", false);
        this.mostrarMensaje(error.response, tipoMensajeEnum.ERROR);
        window.console.log(error);
      }
    },
    getPlanes() {
      axios
          .get(this.$apiConfig.planesUrl)
          .then((response) => {
            this.planes = response.data;
            window.console.log(this.planes)
          })
          .catch((error) => {
            this.$errorCargarDatos();
            window.console.log(error);
          });
    },
    onPlanSeleccionado(plan) {
      this.planSeleccionado = plan;
      this.planesStep = 2;
    },
    mostrarMensaje(mensaje, tipo) {
      this.tipoMensaje = tipo;
      this.mensajeError = mensaje;
    },
  },
  watch: {
    mostrarContrasena: function (value) {
      if (value) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
  },
};
</script>
