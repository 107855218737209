import Vue from "vue";
import Router from "vue-router";

//COMPONENTES
import Home from "@/components/Home.vue";
import Imagenes from "@/components/indicaciones/Imagenes.vue";
import Laboratorios from "@/components/indicaciones/Laboratorios.vue";
import Medicamentos from "@/components/indicaciones/Medicamentos.vue";
import SegurosMedicos from "@/components/SegurosMedicos.vue";
import Configuracion from "@/views/ConfiguracionView.vue";
import Pacientes from "@/components/pacientesGroup/Pacientes.vue";
import Especialidades from "@/views/EspecialidadesView.vue";
import CentrosMedicos from "@/views/CentrosMedicosView.vue";
import Login from "@/components/cuentas/Login.vue";
import Registrar from "@/components/cuentas/Registrar.vue";
import RolesUsuarios from "@/views/RolesUsuariosView.vue";
import Usuarios from "@/views/UsuariosView.vue";
import Articulo from "@/views/ArticulosView.vue";
import Facturacion from "@/components/facturas/facturacion.vue";
import ListadoFacturas from "@/components/facturas/ListadoFacturas.vue";
import PacientesEnTurno from "@/components/pacientesGroup/PacientesEnTurno.vue";
import PacientesCitados from "@/components/pacientesGroup/PacientesCitados.vue";
import Planes from "@/components/cuentas/planes/Planes.vue";
import ConfirmarDeclinarCita from "@/components/cita/ConfirmarDeclinarCita";
import NotFound from "@/components/NotFound";
import PaypalCheckout from "@/components/cuentas/PaypalCheckout";

function autorizado(to, from, next) {
  try {
    if (!window.localStorage.getItem("@jwtToken")) {
      next("/login");
    }
    next();
  } catch (error) {
    window.console.log("Error al intentar navegar: " + error);
  }
}

const routes = [
  {
    path: "*",
    component: NotFound,
  },
  {
    path: "/",
    beforeEnter: autorizado,
    component: Home,
  },
  {
    path: "/home",
    name: "home",
    beforeEnter: autorizado,
    component: Home,
  },
  {
    path: "/articulos",
    name: "articulos",
    beforeEnter: autorizado,
    component: Articulo,
  },
  {
    path: "/facturacion",
    name: "facturacion",
    beforeEnter: autorizado,
    component: Facturacion,
  },
  {
    path: "/listado-facturas",
    name: "listado-facturas",
    beforeEnter: autorizado,
    component: ListadoFacturas,
  },
  {
    path: "/imagenes",
    name: "imagenes",
    beforeEnter: autorizado,
    component: Imagenes,
  },
  {
    path: "/laboratorios",
    name: "laboratorios",
    beforeEnter: autorizado,
    component: Laboratorios,
  },
  {
    path: "/medicamentos",
    name: "medicamentos",
    beforeEnter: autorizado,
    component: Medicamentos,
  },
  {
    path: "/seguros-medicos",
    name: "segurosmedicos",
    beforeEnter: autorizado,
    component: SegurosMedicos,
  },
  {
    path: "/configuracion",
    name: "configuracion",
    beforeEnter: autorizado,
    component: Configuracion,
  },
  {
    path: "/pacientes",
    name: "pacientes",
    beforeEnter: autorizado,
    component: Pacientes,
  },
  {
    path: "/especialidades",
    name: "especialidades",
    beforeEnter: autorizado,
    component: Especialidades,
  },
  {
    path: "/pacientes-en-turno",
    name: "pacientes-en-turno",
    beforeEnter: autorizado,
    component: PacientesEnTurno,
  },
  {
    path: "/pacientes-citados",
    name: "pacientes-citados",
    beforeEnter: autorizado,
    component: PacientesCitados,
  },
  {
    path: "/centros-medicos",
    name: "centrosmedicos",
    beforeEnter: autorizado,
    component: CentrosMedicos,
  },

  {
    path: "/roles",
    name: "roles",
    beforeEnter: autorizado,
    component: RolesUsuarios,
  },
  {
    path: "/usuarios",
    name: "usuarios",
    beforeEnter: autorizado,
    component: Usuarios,
  },
  {
    path: "/planes",
    name: "planes",
    component: Planes,
    beforeEnter: autorizado,
  },
  {
    path: "/confirmar-cita/:citaId",
    name: "confirmar-cita",
    component: ConfirmarDeclinarCita,
  },
  {
    path: "/registrar-nueva-cuenta-royal",
    name: "registrar",
    component: Registrar,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/paypal",
    name: "paypal",
    component: PaypalCheckout,
  },
];

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  routes: routes,
  // mode: "history",
});
