<template>
  <div class="justify-center">
    <Navbar></Navbar>
    <v-main>
      <v-container>
        <v-row v-if="onLine === false"
          ><p>Revise su conexión de internet.</p></v-row
        >
        <slot></slot>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { isOnline } from "../utility/internetHelpers.js";

export default {
  name: "main-layout",
  components: {
    Navbar,
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  data() {
    return {
      layout: `div`,
      onLine: isOnline(),
      showBackOnline: false,
    };
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
  },
  watch: {
    onLine: function (newValue) {
      if (newValue) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
  },
};
</script>
