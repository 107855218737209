<template>
  <v-container grid-list-md>
    <v-card elevation="0" class="mt-2">
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Motivo de Consulta</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-layout
                v-for="historiaClinica in historiasClinicas"
                :key="historiaClinica.id"
                row
                grid-list-md
              >
                <v-layout>
                  <p>{{ historiaClinica.fecha }}</p></v-layout
                >
                <v-layout>
                  <v-flex><p>Motivo Consulta</p></v-flex>
                  <v-flex>
                    <p>{{ historiaClinica.motivoConsulta }}</p>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Antecedentes</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  row
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout row>
                    <v-flex
                      v-for="antecedente in historiaClinica.antecedentes"
                      :key="antecedente.id"
                    >
                      <v-textarea
                        disabled
                        sm12
                        md3
                        v-if="antecedente.multilinea"
                        :label="antecedente.nombre"
                        v-model="antecedente.valor"
                        :append-icon="
                          antecedente.sinPatologiaAparente
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank'
                        "
                        outlined
                      ></v-textarea>
                      <v-text-field
                        v-else
                        sm12
                        disabled
                        md6
                        :label="antecedente.nombre"
                        v-model="antecedente.valor"
                        :append-icon="
                          antecedente.sinPatologiaAparente
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank'
                        "
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              >Estudios Realizados</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  row
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout row>
                    <v-flex
                      v-for="estudioRealizado in historiaClinica.estudiosRealizados"
                      :key="estudioRealizado.id"
                    >
                      <v-textarea
                        disabled
                        sm12
                        md3
                        v-if="estudioRealizado.multilinea"
                        :label="estudioRealizado.nombre"
                        v-model="estudioRealizado.valor"
                        :append-icon="
                          estudioRealizado.sinPatologiaAparente
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank'
                        "
                        outlined
                      ></v-textarea>
                      <v-text-field
                        v-else
                        sm12
                        disabled
                        md6
                        :label="estudioRealizado.nombre"
                        v-model="estudioRealizado.valor"
                        :append-icon="
                          estudioRealizado.sinPatologiaAparente
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank'
                        "
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Examen Físico</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  row
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout row>
                    <v-flex
                      v-for="examenFisicoItem in historiaClinica.examenFisico"
                      :key="examenFisicoItem.id"
                    >
                      <v-textarea
                        disabled
                        sm12
                        md3
                        v-if="examenFisicoItem.multilinea"
                        :label="examenFisicoItem.nombre"
                        v-model="examenFisicoItem.valor"
                        :append-icon="
                          examenFisicoItem.sinPatologiaAparente
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank'
                        "
                        outlined
                      ></v-textarea>
                      <v-text-field
                        v-else
                        sm12
                        disabled
                        md6
                        :label="examenFisicoItem.nombre"
                        v-model="examenFisicoItem.valor"
                        :append-icon="
                          examenFisicoItem.sinPatologiaAparente
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank'
                        "
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Diagnósticos</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  column
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout
                    row
                    v-for="diagnostico in historiaClinica.diagnosticos"
                    :key="diagnostico.id"
                  >
                    <v-flex> {{ diagnostico.descripcion }} </v-flex>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Medicamentos</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  column
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout
                    row
                    v-for="medicamentoIndicado in historiaClinica.medicamentosIndicados"
                    :key="medicamentoIndicado.id"
                  >
                    <v-flex>
                      {{ medicamentoIndicado.descripcion }}
                    </v-flex>
                    <v-flex>
                      {{ medicamentoIndicado.uso }}
                    </v-flex>
                    <v-flex>
                      {{ medicamentoIndicado.via }}
                    </v-flex>
                    <v-flex>
                      {{ medicamentoIndicado.frecuencia }}
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Laboratorios</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  column
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout
                    row
                    v-for="laboratorioIndicado in historiaClinica.laboratoriosIndicados"
                    :key="laboratorioIndicado.id"
                  >
                    {{ laboratorioIndicado.descripcion }}
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Imágenes</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  column
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout
                    row
                    v-for="imagenIndicada in historiaClinica.imagenesIndicadas"
                    :key="imagenIndicada.id"
                  >
                    {{ imagenIndicada.descripcion }}
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Formularios</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-layout
                  row
                  wrap
                  v-for="historiaClinica in historiasClinicas"
                  :key="historiaClinica.id"
                >
                  <v-layout>
                    <p>{{ historiaClinica.fecha }}</p></v-layout
                  >
                  <v-layout row>
                    <v-flex
                      v-for="formulario in historiaClinica.formularios"
                      :key="formulario.id"
                    >
                      <v-layout
                        v-for="item in formulario.items"
                        :key="item.id"
                        row
                      >
                        <v-flex>
                          <h4>
                            {{ item.nombre }}
                          </h4>
                          <v-layout row>
                            <v-flex
                              v-for="subItem in item.subItems"
                              :key="subItem.id"
                              :class="{ lg5custom: item.subItems.length > 4 }"
                            >
                              <v-textarea
                                v-if="subItem.multilinea"
                                :label="subItem.nombre"
                                v-model="subItem.valor"
                                :append-icon="
                                  subItem.sinPatologiaAparente
                                    ? 'mdi-checkbox-marked'
                                    : 'mdi-checkbox-blank'
                                "
                                outlined
                              ></v-textarea>
                              <v-text-field
                                v-else
                                :label="subItem.nombre"
                                v-model="subItem.valor"
                                :append-icon="
                                  subItem.sinPatologiaAparente
                                    ? 'mdi-checkbox-marked'
                                    : 'mdi-checkbox-blank'
                                "
                                outlined
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "resumen-historias-clinicas",
  mounted() {},
  data() {
    return {
      historiasClinicas: [
        {
          id: "1",
          motivoConsulta: "mot 1",
          fecha: "15/02/22",
          antecedentes: [
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
          ],
          estudiosRealizados: [
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
          ],
          examenFisico: [
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
          ],
          medicamentosIndicados: [
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
          ],
          laboratoriosIndicados: [
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
          ],
          imagenesIndicadas: [
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
          ],
        },
        {
          id: "2",
          motivoConsulta: "mot 2",
          fecha: "20/02/20",
          antecedentes: [
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
          ],
          estudiosRealizados: [
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
          ],
          examenFisico: [
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
            {
              nombre: "Ant 1",
              valor: "ant val",
              multilinea: 0,
              sinPatologiaAparente: 1,
            },
          ],
          medicamentosIndicados: [
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
            {
              descripcion: "Ant 1",
              dosis: "ant val",
              via: "ant val",
              uso: "ant val",
            },
          ],

          laboratoriosIndicados: [
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
          ],
          imagenesIndicadas: [
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
            {
              descripcion: "Ant 1",
            },
          ],
        },
      ],
    };
  },
  props: {
    paciente: Object,
  },
  computed: {},
  methods: {},
};
</script>
