import Vue from "vue";
import Vuetify from "vuetify";

import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";

import { colors } from "../utility/enumerations.js";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.PRIMARY, //'#00ACC1',//'#00BCD4',//'#0070AD',//'#004387',//'#016b6d',
        secondary: colors.SECONDARY,
        accent: colors.ACCENT, //'#8c9eff',
        error: colors.ERROR,
        info: colors.INFO,
        success: colors.SUCCESS,
        warning: colors.WARNING,
      },
      dark: {
        primary: colors.PRIMARY, //'#00ACC1',//'#00BCD4',//'#0070AD',//'#004387',//'#016b6d',
        secondary: colors.SECONDARY,
        accent: colors.ACCENT, //'#8c9eff',
        error: colors.ERROR,
        info: colors.INFO,
        success: colors.SUCCESS,
        warning: colors.WARNING,
      },
    },
  },
});
