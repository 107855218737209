<template>
  <div id="medicamentos">
    <h1>Medicamentos</h1>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-form @submit.prevent="guardar" ref="formmedicamento" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="headline">Registro de medicamentos</span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                dense
                outlined
                :rules="descripcionRules"
                label="Descripción"
                v-model="medicamento.descripcion"
              ></v-text-field>
              <v-text-field
                dense
                outlined
                label="Precio"
                prefix="$"
                v-model.number="medicamento.precio"
              ></v-text-field>
              <v-checkbox
                label="Activo"
                v-model="medicamento.activo"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded type="submit" color="primary">
                <v-icon left>mdi-check</v-icon>Guardar
              </v-btn>
              <v-btn outlined rounded color="error" @click="cerrarModal()">
                <v-icon left>mdi-close</v-icon>Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <listado-generico
      @crearItem="crear"
      @seleccionarItem="editar"
      :esRegistrar="true"
      :itemsPorPagina="itemsPorPagina"
      :encabezados="encabezados"
      :items="medicamentos"
    ></listado-generico>
  </div>
</template>

<script>
import MainLayout from "../../layouts/MainLayout";
import ListadoGenerico from "./ListadoGenerico.vue";
import jwtAxios from "../../shared/jwtAxios";

export default {
  components: {
    ListadoGenerico,
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  created() {
    this.get();
  },
  name: "medicamentos",
  data() {
    return {
      medicamento: {
        id: 0,
        cuentaId: 0,
        descripcion: "",
        precio: 0,
        activo: true,
      },
      encabezados: [
        { text: "Descripcion", value: "descripcion" },
        { text: "", value: "actions", sortable: false },
      ],
      nombreModal: "registroMedicamentosModal",
      medicamentos: [],
      itemsPorPagina: 5,
      dialog: false,

      //VALIDACIONES
      descripcionRules: [
        (valido) => !!valido || "La descripción es requerida.",
      ],
    };
  },
  methods: {
    get() {
      jwtAxios
        .get(`${this.$apiConfig.medicamentosUrl}/${this.$store.state.cuentaId}`)
        .then((response) => {
          this.medicamentos = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    crear() {
      this.limpiar();
      this.mostrarModal();
    },
    actualizar() {
      jwtAxios
        .put(this.$apiConfig.medicamentosUrl, this.medicamento)
        .then(() => {
          this.cerrarModal();
          this.$datosGuardados();
          this.get();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      try {
        if (this.$refs.formmedicamento.validate()) {
          if (this.medicamento.id > 0) {
            this.actualizar();
          } else {
            this.medicamento.cuentaId = Number.parseInt(
              this.$store.state.cuentaId
            );
            jwtAxios
              .post(this.$apiConfig.medicamentosUrl, this.medicamento)
              .then(() => {
                this.cerrarModal();
                this.$datosGuardados();
                this.get();
              })
              .catch((error) => {
                this.$errorGuardarDatos();
                window.console.log(error);
              });
          }
        }
      } catch (ex) {
        window.console.log(ex);
      }
    },
    editar(medicamentoParam) {
      this.medicamento = medicamentoParam;
      this.mostrarModal();
    },
    limpiar() {
      this.medicamento = {
        id: 0,
        descripcion: "",
        precio: 0,
        activo: true,
      };
    },
    mostrarModal() {
      this.dialog = true;
    },
    cerrarModal() {
      this.dialog = false;
      this.limpiar();
      this.$refs.formmedicamento.resetValidation();
    },
  },
};
</script>
