<template>
  <div>
    <registro-centros-medicos
      ref="registroCentro"
      v-bind:dialog.sync="dialog"
      @cerrarModal="cerrarModal"
      :centroMedicoSeleccionado="centroMedico"
    ></registro-centros-medicos>

    <listado-generico
      @crearItem="crear"
      @seleccionarItem="editar"
      :esRegistrar="true"
      :itemsPorPagina="itemsPorPagina"
      :encabezados="encabezados"
      :items="centrosMedicos"
    ></listado-generico>
  </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import RegistroCentrosMedicos from "../components/medicos/RegistroCentrosMedicos.vue";
import ListadoGenerico from "../components/indicaciones/ListadoGenerico.vue";
import jwtAxios from "../shared/jwtAxios.js";

export default {
  name: "centro-medico",
  components: {
    ListadoGenerico,
    RegistroCentrosMedicos,
  },
  created() {
    this.get();
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  data() {
    return {
      url: this.$apiConfig.centrosMedicosUrl,
      dialog: false,
      centroMedico: {},
      iconoCrear: "mdi-plus",
      encabezados: [
        { text: "Descripcion", value: "descripcion" },
        { text: "Dirección", value: "direccion" },
        { text: "Teléfono", value: "telefono" },
        { text: "", value: "actions", sortable: false },
      ],
      centrosMedicos: [],
      itemsPorPagina: 5,
    };
  },
  methods: {
    get() {
      jwtAxios
        .get(this.url)
        .then((response) => {
          this.centrosMedicos = response.data;
        })
        .catch((error) => {
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
    crear() {
      this.mostrarModal();
    },
    mostrarModal() {
      this.dialog = true;
    },
    cerrarModal() {
      this.dialog = false;
      this.$refs.registroCentro.$refs.formCentroMedico.resetValidation();
      this.get();
    },
    editar(centroMedicoParam) {
      this.centroMedico = centroMedicoParam;
      this.mostrarModal();
    },
  },
};
</script>
