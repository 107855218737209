<template>
  <v-container id="subir-archivos">
    <v-row>
      <v-col sm="10" md="10">
        <v-form @submit:prevent="guardar" ref="form" lazy-validation>
          <v-file-input
            accept="image/*, .docx, .doc, .pptx, .pdf, .rtf"
            label="Seleccione los archivos"
            multiple
            chips
            color="pink"
            v-model="archivosSeleccionados"
            @change="addFiles"
          ></v-file-input>
        </v-form>
      </v-col>

      <v-col sm="2" md="2">
        <v-btn
          @click="guardar"
          :disabled="puedeSubirArchivos === false"
          color="primary"
          outlined
          rounded
          >Subir archivos</v-btn
        ></v-col
      >
    </v-row>

    <v-row v-if="subiendoArchivos">
      <v-progress-linear v-model="progreso" height="25">
        <strong>{{ Math.ceil(progreso) }}%</strong>
      </v-progress-linear>
    </v-row>

    <v-row>
      <v-col cols="3" v-for="(file, i) in archivosSeleccionados" :key="i">
        <img
          :ref="'file'"
          :img="imagenSeleccionada"
          class="portrait"
          :alt="file.name"
          height="200px"
          width="200px"
          @contextmenu.prevent="showContextMenu($event, i)"
        />
        <v-text-field
          placeholder="Comentario"
          v-model="file.comentario"
        ></v-text-field>

        <v-menu
          v-model="showMenu"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-list>
            <v-list-item>
              <v-list-item-title @click="eliminarArchivo"
                >Eliminar</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JwtAxios from "@/shared/jwtAxios";
import { getFileExtension } from "../../utility/stringHelpers";
import { extensionesArchivos, iconosOnline } from "../../utility/enumerations";

export default {
  name: "expediente-digital",
  props: {
    paciente: Object,
  },
  data() {
    return {
      archivosSeleccionados: [],
      archivosShow: [],
      comentarios: [],
      archivosRules: [(value) => !value || value.size < 10000000000000000000],
      showMenu: false,
      archivosSeleccionadoIndex: 0,
      imagenSeleccionada: null,
      x: 0,
      y: 0,
      puedeSubirArchivos: false,
      subiendoArchivos: false,
      progreso: 0,
    };
  },
  methods: {
    guardar() {
      let context = this;
      if (this.$refs.form.validate()) {
        this.archivosSeleccionados.map((archivo) => {
          this.comentarios.push(archivo.comentario);
          delete archivo.comentario;
          return archivo;
        });

        const formData = new FormData();
        formData.append("pacienteId", this.paciente.id);

        for (let i = 0; i < this.archivosSeleccionados.length; i++) {
          formData.append("archivos", this.archivosSeleccionados[i]);
        }

        for (let i = 0; i < this.comentarios.length; i++) {
          formData.append(
            "comentarios",
            this.comentarios[i] === undefined ? "" : this.comentarios[i]
          );
        }
        context.subiendoArchivos = true;

        JwtAxios.post(this.$apiConfig.expedienteDigitalUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            context.progreso = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        })
          .then(function () {
            context.subiendoArchivos = false;
            context.$success("Archivos subidos satisfactoriamente.");
            context.$emit("onArchivosSubidos");
            context.archivosSeleccionados = [];
            context.comentarios = [];
            context.archivosShow = [];
          })
          .catch(function (e) {
            context.subiendoArchivos = false;
            window.console.log(e);
          });
      }
    },
    eliminarArchivo() {
      this.archivosSeleccionados.splice(this.archivosSeleccionadoIndex, 1);
    },
    addFiles() {
      if (this.archivosSeleccionados !== null) {
        this.archivosSeleccionados.forEach((file, f) => {
          this.archivosShow[f] = new FileReader();
          this.archivosShow[f].onloadend = (e) => {
            let fileData = this.archivosShow[f].result;
            let imgRef = this.$refs.file[f];

            imgRef.src = fileData;

            switch (getFileExtension(file.name)) {
              case extensionesArchivos.WORD:
              case extensionesArchivos.DOC:
              case extensionesArchivos.RTF:
                imgRef.src = iconosOnline.WORD;
                break;
              case extensionesArchivos.EXCEL:
                imgRef.src = iconosOnline.EXCEL;
                break;
              case extensionesArchivos.PPOINT:
                imgRef.src = iconosOnline.PPOINT;
                break;
              case extensionesArchivos.PDF:
                imgRef.src = iconosOnline.PDF;
                break;
              case extensionesArchivos.TXT:
                imgRef.src = iconosOnline.PDF;
                break;
              case extensionesArchivos.PNG:
              case extensionesArchivos.JPG:
              case extensionesArchivos.JPEG:
              case extensionesArchivos.GIF:
              case extensionesArchivos.RAW:
              case extensionesArchivos.TIFF:
                imgRef.src = fileData;
                break;
            }
          };
          this.archivosShow[f].readAsDataURL(this.archivosSeleccionados[f]);
        });
      }
    },
    showContextMenu(e, archivoIndex) {
      this.archivosSeleccionadoIndex = archivoIndex;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
  watch: {
    paciente: function (newValue) {
      return newValue;
    },
    archivosSeleccionados: {
      handler(newValue) {
        if (newValue !== null && newValue.length) {
          this.puedeSubirArchivos = true;
        } else {
          this.puedeSubirArchivos = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
img {
  max-height: 150px;
  max-width: 150px;
}
</style>
