import Vue from "vue";
import Vuex from "vuex";
import { parseJwt, tokenAlive } from "@/utility/jwtHelper";
import axios from "axios";
import ApiConfig from "@/utility/apiConfig.js";
import jwtAxios from "@/shared/jwtAxios.js";
import { dateNow } from "@/utility/dateHelpers.js";

Vue.use(Vuex);

const apiConfig = new ApiConfig();

export default new Vuex.Store({
  state: {
    configuracion: {
      id: 0,
      cuentaId: 0,
      temaOscuro: false,
    },
    configuracionReporte: {
      id: 0,
      landscape: null,
    },
    loginData: {},
    viewOverlay: false,
    usuarioRegistro: {},
    medicamentos: [],
    laboratorios: [],
    imagenes: [],
    formulariosPersonalizadosItems: [],

    fechaIndicacionMedicamentos:
      localStorage.getItem("@fechaIndicacionMedicamentos") || dateNow(),
    fechaIndicacionLaboratorios:
      localStorage.getItem("@fechaIndicacionLaboratorios") || dateNow(),
    fechaIndicacionImagenes:
      localStorage.getItem("@fechaIndicacionImagenes") || dateNow(),

    observacionIndicacionMedicamentos:
      localStorage.getItem("@observacionIndicacionMedicamentos") || "",
    observacionIndicacionLaboratorios:
      localStorage.getItem("@observacionIndicacionLaboratorios") || "",
    observacionIndicacionImagenes:
      localStorage.getItem("@observacionIndicacionImagenes") || "",

    usuarioId: localStorage.getItem("@usuarioId") || 0,
    usuario: localStorage.getItem("@usuario") || {},
    cuentaId: localStorage.getItem("@cuentaId") || 0,
    medicoId: localStorage.getItem("@medicoId") || 0,
    jwtToken: localStorage.getItem("@jwtToken") || "",
    refreshToken: localStorage.getItem("@refreshToken") || "",
    codigoVerificador: localStorage.getItem("@codigoVerificador") || "",

    rolUsuario: localStorage.getItem("@rolUsuario") || "",
    email: localStorage.getItem("@email") || 0,
    planSeleccionado: localStorage.getItem("@planSeleccionado") || {},

    //INDICACIONES DE HISTORIA CLINICA
    historiaId: localStorage.getItem("@hcId") || "0", //ID DE LA HISTORIA QUE SE ESTÁ REVISANDO.

    reporteImagenes: localStorage.getItem("@reporteImagenes") || "",
    reporteLaboratorios: localStorage.getItem("@reporteLaboratorios") || "",
    reporteMedicamentos: localStorage.getItem("@reporteMedicamentos") || "",
    reporteHistoriaClinica:
      localStorage.getItem("@reporteHistoriaClinica") || "",
  },
  actions: {
    Login(context, login) {
      return new Promise((resolve, reject) => {
        context.commit("login", login);
        context.commit("activateViewOverlay", true);
        axios
          .post(apiConfig.loginUrl, login)
          .then((response) => {
            if (response) {
              context.commit("setJwtToken", response.data.token);
              context.commit("setRefreshToken", response.data.refreshToken);
              context.commit("setUsuarioId", response.data.uId);
              context.commit(
                "setRolUsuario",
                JSON.parse(JSON.stringify(parseJwt(context.state.jwtToken)))
                  .role
              );
              context.commit("setMedicoId", response.data.medicoId);
              context.commit("setCuentaId", response.data.cuentaId);

              context.commit(
                "setReporteImagenes",
                response.data.reporteImagenes
              );
              context.commit(
                "setReporteLaboratorios",
                response.data.reporteLaboratorios
              );
              context.commit(
                "setReporteMedicamentos",
                response.data.reporteMedicamentos
              );
              context.commit(
                "setReporteHistoriaClinica",
                response.data.reporteHistoriaClinica
              );
              context.commit("activateViewOverlay", false);
              resolve(response);
            }
          })
          .catch((error) => {
            context.commit("activateViewOverlay", false);
            localStorage.clear();
            reject(error);
          });
      });
    },
    Registrar(context, usuario) {
      return new Promise((resolve, reject) => {
        context.commit("activateViewOverlay", true);
        axios
          .post(`${apiConfig.usuariosUrl}/Admision`, usuario)
          .then((response) => {
            context.commit("setCodigoVerificador", response.data);
            context.commit("setUsuario", usuario);
            context.commit("activateViewOverlay", false);
            resolve(response);
          })
          .catch((error) => {
            context.commit("activateViewOverlay", false);
            reject(error);
          });
      });
    },
    getPerfilMedico(context) {
      return new Promise((resolve, reject) => {
        jwtAxios
          .get(`${apiConfig.medicosUrl}/${parseInt(context.state.usuarioId)}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getConfiguracionReporte({ getters }) {
      return new Promise((resolve, reject) => {
        jwtAxios
          .get(
            `${apiConfig.configuracionUrl}/getConfiguracionReporte/${getters.getCuentaId}`
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            window.console.error(error);
            reject(error);
          });
      });
    },
  },
  getters: {
    jwtToken: (state) => {
      return state.jwtToken;
    },
    refreshToken: (state) => {
      return state.refreshToken;
    },
    getCuentaId: (state) => {
      return Number.parseInt(state.cuentaId);
    },
    usuario: (state) => {
      return JSON.parse(JSON.stringify(state.usuario));
    },
    rolUsuario: (state) => {
      return state.rolUsuario;
    },
    isTokenAlive: (state) => {
      return tokenAlive(parseJwt(state.jwtToken).exp);
    },
    viewOverlay: (state) => {
      return state.viewOverlay;
    },
    nombreAbreviado: (state) => {
      let token = parseJwt(state.jwtToken);
      return `${token.nombre} ${token.apellido.charAt(0)}.`;
    },
    nombreUsuario: (state) => {
      let token = parseJwt(state.jwtToken);
      return `${token.nombre} ${token.apellido}`;
    },
    getMedicoId: (state) => {
      return parseJwt(state.jwtToken).mId;
    },
    getFirmaDoctor: (state) => {
      parseJwt(state.jwtToken).firma || "";
    },
    historiaId: (state) => {
      return state.historiaId || 0;
    },
  },

  mutations: {
    login(state, loginData) {
      state.loginData = loginData;
    },
    Registrar(state, usuario) {
      state.usuarioRegistro = usuario;
    },
    setTema(state, configuracion) {
      state.configuracion = configuracion;
    },
    setConfiguracionReporte(state, _configuracionReporte) {
      state.configuracionReporte = _configuracionReporte;
    },
    setUsuario(state, usuario) {
      localStorage.setItem("@usuario", usuario);
      state.usuario = usuario;
    },
    setRolUsuario(state, rolUsuario) {
      localStorage.setItem("@rolUsuario", rolUsuario);
      state.rolUsuario = rolUsuario;
    },
    setJwtToken(state, token) {
      localStorage.setItem("@jwtToken", token);
      state.jwtToken = token;
    },
    setRefreshToken(state, refreshToken) {
      localStorage.setItem("@refreshToken", refreshToken);
      state.refreshToken = refreshToken;
    },
    setCodigoVerificador(state, codigo) {
      localStorage.setItem("@codigoVerificador", codigo);
      state.codigoVerificador = codigo;
    },
    setUsuarioId(state, usuarioId) {
      localStorage.setItem("@usuarioId", usuarioId);
      state.usuarioId = usuarioId;
    },
    setMedicoId(state, medicoId) {
      localStorage.setItem("@medicoId", medicoId);
      state.medicoId = medicoId;
    },
    setCuentaId(state, cuentaId) {
      localStorage.setItem("@cuentaId", cuentaId);
      state.cuentaId = cuentaId;
    },
    setEmail(state, email) {
      localStorage.setItem("@email", email);
      state.email = email;
    },
    activateViewOverlay(state, activate) {
      state.viewOverlay = activate;
    },
    getPerfilMedico(tienePerfil) {
      localStorage.setItem("@tienePerfilMedico", tienePerfil);
    },
    //HISTORIA CLINICA
    setHistoriaClinicaActualId(state, historiaId) {
      state.historiaId = historiaId;
      localStorage.setItem("@hcId", historiaId);
    },
    setMedicamentos(state, _medicamentos) {
      state.medicamentos = _medicamentos;
    },
    setLaboratorios(state, _laboratorios) {
      state.laboratorios = _laboratorios;
    },
    setImagenes(state, _imagenes) {
      state.imagenes = _imagenes;
    },
    setFormulariosPersonalizadosItems(state, _formulariosPersonalizadosItems) {
      state.formulariosPersonalizadosItems = _formulariosPersonalizadosItems;
    },
    setPlanSeleccionado(state, _planSeleccionado) {
      localStorage.setItem("@planSeleccionado", _planSeleccionado);
      state.planSeleccionado = _planSeleccionado;
    },
    setFechaIndicacionMedicamentos(state, fechaIndicacionMedicamentos) {
      localStorage.setItem(
        "@fechaIndicacionMedicamentos",
        fechaIndicacionMedicamentos
      );
      state.fechaIndicacionMedicamentos = fechaIndicacionMedicamentos;
    },
    setFechaIndicacionLaboratorios(state, fechaIndicacionLaboratorios) {
      localStorage.setItem(
        "@fechaIndicacionLaboratorios",
        fechaIndicacionLaboratorios
      );
      state.fechaIndicacionLaboratorios = fechaIndicacionLaboratorios;
    },
    setFechaIndicacionImagenes(state, fechaIndicacionImagenes) {
      localStorage.setItem("@fechaIndicacionImagenes", fechaIndicacionImagenes);
      state.fechaIndicacionImagenes = fechaIndicacionImagenes;
    },

    setObservacionIndicacionMedicamentos(
      state,
      observacionIndicacionMedicamentos
    ) {
      localStorage.setItem(
        "@observacionIndicacionMedicamentos",
        observacionIndicacionMedicamentos
      );
      state.observacionIndicacionMedicamentos =
        observacionIndicacionMedicamentos;
    },
    setObservacionIndicacionLaboratorios(
      state,
      observacionIndicacionLaboratorios
    ) {
      localStorage.setItem(
        "@observacionIndicacionLaboratorios",
        observacionIndicacionLaboratorios
      );
      state.observacionIndicacionLaboratorios =
        observacionIndicacionLaboratorios;
    },
    setObservacionIndicacionImagenes(state, observacionIndicacionImagenes) {
      localStorage.setItem(
        "@observacionIndicacionImagenes",
        observacionIndicacionImagenes
      );
      state.observacionIndicacionImagenes = observacionIndicacionImagenes;
    },

    setReporteImagenes(state, reporteImagenes) {
      localStorage.setItem("@reporteImagenes", reporteImagenes);
      state.reporteImagenes = reporteImagenes;
    },
    setReporteLaboratorios(state, reporteLaboratorios) {
      localStorage.setItem("@reporteLaboratorios", reporteLaboratorios);
      state.reporteLaboratorios = reporteLaboratorios;
    },
    setReporteMedicamentos(state, reporteMedicamentos) {
      localStorage.setItem("@reporteMedicamentos", reporteMedicamentos);
      state.reporteMedicamentos = reporteMedicamentos;
    },
    setReporteHistoriaClinica(state, reporteHistoriaClinica) {
      localStorage.setItem("@reporteHistoriaClinica", reporteHistoriaClinica);
      state.reporteHistoriaClinica = reporteHistoriaClinica;
    },

    clearHistoriaClinicaActualId(state) {
      state.historiaId = "";
      localStorage.setItem("@hcId", "");
    },
    clearMedicamentos(state) {
      state.medicamentos = [];
    },
    clearLaboratorios(state) {
      state.laboratorios = [];
    },
    clearImagenes(state) {
      state.imagenes = [];
    },
    clearFormulariosPersonalizadosItems(state) {
      state.formulariosPersonalizadosItems = [];
    },
    clearFechaIndicacionMedicamentos() {
      localStorage.setItem("@fechaIndicacionMedicamentos", dateNow());
    },
    clearFechaIndicacionLaboratorios() {
      localStorage.setItem("@fechaIndicacionLaboratorios", dateNow());
    },
    clearFechaIndicacionImagenes() {
      localStorage.setItem("@fechaIndicacionImagenes", dateNow());
    },
    clearObservacionIndicacionMedicamentos() {
      localStorage.setItem("@observacionIndicacionMedicamentos", "");
    },
    clearObservacionIndicacionLaboratorios() {
      localStorage.setItem("@observacionIndicacionLaboratorios", "");
    },
    clearObservacionIndicacionImagenes() {
      localStorage.setItem("@observacionIndicacionImagenes", "");
    },
  },
});
