<template>
  <div id="registro-citas">
    <RegistroPacientes
      :paciente="paciente"
      :dialogPaciente="dialogPaciente"
      @cerrarModalPaciente="cerrarModalPaciente"
    ></RegistroPacientes>

    <v-dialog
      v-model="dialogRegistroCitas"
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
    >
      <v-form
        @submit.prevent="guardar"
        ref="form"
        @click:outside="cerrarModal()"
        lazy-validation
      >
        <v-card>
          <v-system-bar height="62" color="primary" dark>
            <v-btn icon dark @click="cerrarModal()">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title color="primary">
              <span :class="`text-body-1`" color="primary" style="color: white">
                Registro de Cita | {{ paciente.nombre }}
                {{ paciente.primerApellido }}
              </span>
              <v-icon style="color: white" @click="mostrarModalPaciente()"
                >mdi-pencil</v-icon
              >
            </v-toolbar-title>
          </v-system-bar>

          <v-container fluid>
            <v-layout row wrap>
              <v-flex ma-2 md5 sm12>
                <v-layout>
                  <v-flex>
                    <v-text-field
                      outlined
                      dense
                      class="upperCase"
                      :rules="[rules.required, rules.onlyLetters]"
                      label="Nombre"
                      v-model="paciente.nombre"
                      :disabled="camposPacienteDeshabilitados"
                    ></v-text-field>
                  </v-flex>

                  <v-flex ml-2>
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required, rules.onlyLetters]"
                      class="upperCase"
                      label="Apellidos"
                      v-model="paciente.primerApellido"
                      :disabled="camposPacienteDeshabilitados"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-text-field
                    outlined
                    dense
                    v-model="paciente.celular"
                    :rules="[rules.required]"
                    label="Celular"
                    v-mask="mascaraTelefono"
                    :disabled="camposPacienteDeshabilitados"
                  ></v-text-field>
                </v-layout>
                <v-layout>
                  <v-flex>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          label="Seleccionar fecha"
                          ref="menu"
                          v-model="cita.fecha"
                          :rules="[rules.required]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        no-title
                        scrollable
                        v-model="cita.fecha"
                        :rules="rules.required"
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="menu2 = false"
                        @change="onFechaChanged()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex ml-2>
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="cita.hora"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="cita.hora"
                          label="Seleccionar horario"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="cita.hora"
                        full-width
                        @click:minute="$refs.menu.save(cita.hora)"
                      ></v-time-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md5 sm12>
                <v-container fluid>
                  <v-card elevation="0">
                    <v-card-title>
                      <p>Horarios con pacientes citados</p>
                    </v-card-title>
                    <v-layout
                      mt-1
                      fluid
                      v-for="(cita, i) in citasPorFecha"
                      :key="i"
                    >
                      <v-flex ml-3 md9>
                        <v-layout>
                          <div>{{ cita.paciente.nombreCompleto }}</div>
                        </v-layout>
                        <v-layout>
                          <div>{{ cita.fechaHora | horaFilter }}</div>
                        </v-layout>
                      </v-flex>
                      <v-flex md3>
                        <v-btn
                          color="secondary"
                          outlined
                          rounded
                          small
                          @click="eliminar(cita)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <p>
                        Total pacientes citados
                        <strong>{{ this.citasPorFecha.length }}</strong>
                      </p>
                    </v-card-actions>
                  </v-card>
                </v-container>
              </v-flex>
            </v-layout>

            <v-layout mt-6 v-if="citasExistentes">
              <v-flex mt-6>
                <p>Citas registradas del paciente</p>
                <v-card outlined v-for="(cita, i) in citas" :key="i" cols="12">
                  <v-card-title class="subtitle">
                    <v-layout row wrap>
                      <v-flex md8 sm12>
                        <div>{{ cita.fechaHora | fechaFilter }}</div>
                        <div class="text-caption">
                          Estado: {{ cita.estado }}
                        </div>
                      </v-flex>
                      <v-flex>
                        <v-btn
                          width="125"
                          v-if="cita.estado === 'PENDIENTE'"
                          color="primary"
                          outlined
                          rounded
                          small
                          @click="recordar(cita)"
                        >
                          <v-icon left>mdi-bell</v-icon> Recordar
                        </v-btn>

                        <v-btn
                          width="125"
                          ml-6
                          color="secondary"
                          outlined
                          rounded
                          small
                          @click="eliminar(cita)"
                        >
                          <v-icon left>mdi-close</v-icon> Eliminar
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout v-else>
              <v-container>
                <br />
                <v-divider></v-divider>
                <br />
                <p style="text-align: center">
                  El paciente no tiene citas registradas
                </p>
              </v-container>
            </v-layout>
          </v-container>

          <v-footer fixed>
            <v-spacer></v-spacer>
            <v-btn width="125" outlined rounded type="submit" color="primary">
              <v-icon left>mdi-check</v-icon>Guardar
            </v-btn>
            <v-btn
              width="125"
              outlined
              rounded
              color="error"
              @click="cerrarModal()"
            >
              <v-icon left>mdi-close</v-icon>Cerrar
            </v-btn>
          </v-footer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<style>
@import url("../../../src/css/myCss.css");
</style>
<script>
import jwtAxios from "@/shared/jwtAxios.js";
import moment from "moment";
import RegistroPacientes from "@/components/pacientesGroup/RegistroPacientes.vue";
import * as alert from "@/utility/alert.js";
import { telefonoMask } from "../../utility/validaciones.js";

export default {
  components: {
    RegistroPacientes,
  },
  name: "registro-citas",
  data() {
    return {
      url: this.$apiConfig.citaUrl,
      cantidadPacientesCitados: 0,
      dialogPaciente: false,
      mascaraTelefono: telefonoMask,
      menu: false,
      menu2: false,
      cita: {
        id: 0,
        estado: "",
        fecha: "",
        hora: "",
        activo: true,
        paciente: null,
        medicoId: 0,
      },
      tabCita: null,
      citas: [],
      citasPorFecha: [],
      rules: {
        required: (valido) => !!valido || this.$camposRequeridosMessage,
      },
    };
  },
  props: {
    dialogRegistroCitas: { type: Boolean, default: false },
    paciente: Object,
    camposPacienteDeshabilitados: { type: Boolean, default: false },
  },
  computed: {
    citasExistentes: function () {
      return this.citas && this.citas.length;
    },
  },
  mounted() {
    this.getCitasByPaciente();
  },
  watch: {
    paciente: function (val) {
      if (val) {
        if (val.id.length > 0) {
          this.getCitasByPaciente();
        }
      }
    },
  },
  methods: {
    onFechaChanged() {
      this.getCantidadPacientesCitados();
      this.getCitasByFecha();
    },
    cerrarModal() {
      this.$emit("cerrarModalCitas");
      this.$refs.form.resetValidation();
    },
    cerrarModalPaciente() {
      this.dialogPaciente = false;
    },
    getCitasByFecha() {
      window.console.log(Number(this.$store.state.medicoId));
      if (this.cita !== null && this.cita !== undefined) {
        jwtAxios
          .get(this.url + "/getByFecha/", {
            params: {
              fecha: this.cita.fecha,
              medicoId: this.$store.state.medicoId,
            },
          })
          .then(({ data }) => {
            this.citasPorFecha = data;
            window.console.log(data);
          })
          .catch((error) => {
            window.console.log(error);
            this.$errorCargarDatos();
          });
      }
    },
    getCitasByPaciente() {
      if (
        this.paciente !== null &&
        this.paciente !== undefined &&
        this.paciente.id !== ""
      ) {
        jwtAxios
          .get(this.url + "/getByPaciente/" + this.paciente.id)
          .then(({ data }) => {
            this.citas = data;
          })
          .catch((error) => {
            window.console.log(error);
            this.$errorCargarDatos();
          });
      }
    },
    getCantidadPacientesCitados() {
      jwtAxios
        .get(this.url + "/getCantidadByFecha/" + this.cita.fecha)
        .then(({ data }) => {
          this.cantidadPacientesCitados = data;
          window.console.log(this.cantidadPacientesCitados);
        })
        .catch((error) => {
          window.console.log(error);
          this.$errorCargarDatos();
        });
    },
    eliminar(cita) {
      alert.eliminarCitaQuestionAlert2().then((eliminar) => {
        if (eliminar) {
          jwtAxios
            .post(this.url + "/eliminarCita/" + cita.id)
            .then(() => {
              this.getCitasByPaciente();
              this.$datosGuardados();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
      });
    },
    limpiarCampos() {},
    async recordar(cita) {
      let response = await jwtAxios.post(
        this.$apiConfig.citaUrl + "/recordar/" + cita.id
      );
      if (response.status === 200) {
        this.$success(response.data);
      }
    },
    guardar() {
      if (this.cita.fecha.length > 0) {
        if (this.cita.hora.length > 0) {
          this.cita.cuentaId = this.$store.state.cuentaId;
          this.cita.paciente = this.paciente;
          this.cita.medicoId = this.$store.state.medicoId;
          jwtAxios
            .post(this.url, this.cita)
            .then(() => {
              this.$datosGuardados();
              this.getCitasByPaciente();
              this.$forceUpdate();
              this.limpiarCampos();
            })
            .catch((error) => {
              window.console.log(error);
            });
        } else this.$seleccionarHoraAlert();
      } else this.$seleccionarFechaAlert();
    },
    mostrarModalPaciente() {
      this.dialogPaciente = true;
    },
  },
  filters: {
    fechaFilter: function (date) {
      return moment(date).format("DD/MM/YYYY, h:mm a");
    },
    horaFilter: function (date) {
      return moment(date).format("h:mm a");
    },
  },
};
</script>
