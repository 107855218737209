<template>
  <v-container>
    <v-row>
      <h1>Listado de facturas</h1>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" md="2" sm="12" xs="12">
        <v-menu
          ref="menuFechaDesde"
          v-model="menuFechaDesde"
          :return-value.sync="fechaDesde"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="fechaDesde"
              label="Fecha desde"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker v-model="fechaDesde" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuFechaDesde = false"
              >Cancelar</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.menuFechaDesde.save(fechaDesde)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2" sm="12" xs="12">
        <v-menu
          ref="menuFechaHasta"
          v-model="menuFechaHasta"
          :return-value.sync="fechaHasta"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              label="Fecha hasta"
              v-model="fechaHasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker no-title scrollable v-model="fechaHasta">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuFechaHasta = false"
              >Cancelar</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.menuFechaHasta.save(fechaHasta)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="1" sm="12" xs="12" align="right">
        <v-btn @click="getFacturas()" outlined dense color="primary" rounded>
          <v-icon>mdi-magnify</v-icon>Buscar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="busqueda"
          label="Buscar facturas por paciente"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          disable-sort
          :headers="facturaHeaders"
          :items="facturasFiltradas"
          item-key="id"
          group-by="paciente.id"
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th>
              <v-icon @click="toggle">{{
                isOpen ? "mdi-minus" : "mdi-plus"
              }}</v-icon>
              {{ items[0].paciente.nombreCompleto }}
            </th>
            <th>{{ items[0].fecha }}</th>
            <th>
              {{
                new Intl.NumberFormat("en-IN", {
                  maximumFractionDigits: 2,
                }).format(items[0].sumTotalDiferencia)
              }}
            </th>
          </template>

          <template v-slot:item="{ item }">
            <v-data-table
              hide-default-footer
              :headers="facturaDetalleHeaders"
              :items="item.articulos"
            ></v-data-table>
          </template>

          <template v-slot:body.append>
            <tr :class="{ 'v-data-table__mobile-table-row': isMobile }">
              <td
                :colspan="`${isMobile ? 6 : 2}`"
                :class="{ 'v-data-table__mobile-row': isMobile }"
              >
                <h3>
                  Total facturado:
                  {{ totalFacturado }}
                </h3>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainLayout from "../../layouts/MainLayout.vue";
import { dateNow, toNormalDate } from "../../utility/dateHelpers.js";
import { toMiles } from "../../utility/numberHelper.js";
import axios from "../../shared/jwtAxios.js";

export default {
  name: "listado-facturas",
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  data() {
    return {
      busqueda: "",
      fechaDesde: dateNow(),
      fechaHasta: dateNow(),
      menuFechaDesde: false,
      menuFechaHasta: false,
      facturas: [],
      facturaHeaders: [
        {
          text: "Paciente",
          value: "paciente.nombreCompleto",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "Total",
          value: "sumTotalDiferencia",
        },
      ],
      facturaDetalleHeaders: [
        {
          text: "Descripción",
          value: "descripcion",
        },
        {
          text: "Cantidad",
          value: "cantidad",
        },
        {
          text: "Precio",
          value: "precio",
        },
        {
          text: "Diferencia",
          value: "diferencia",
        },
        {
          text: "Total",
          value: "total",
        },
      ],
    };
  },
  methods: {
    getFacturas() {
      axios
        .get(`${this.$apiConfig.facturasUrl}/getListadoFacturas`, {
          params: {
            cuentaId: this.$store.getters.getCuentaId,
            desde: this.fechaDesde,
            hasta: this.fechaHasta,
          },
        })
        .then((response) => {
          this.facturas = response.data.map((factura) => {
            factura.fecha = toNormalDate(factura.fecha);
            factura.totalDiferencia = toMiles(factura.totalDiferencia);

            return factura;
          });
        })
        .catch((error) => {
          this.$errorCargarDatos();
          window.console.error(error.response);
        });
    },
  },
  computed: {
    facturasFiltradas: function () {
      return this.facturas.filter((factura) => {
        return factura.paciente.nombreCompleto
          .toUpperCase()
          .includes(this.busqueda.toUpperCase());
      });
    },
    totalFacturado() {
      return toMiles(
        this.facturas === null ||
          this.facturas === undefined ||
          this.facturas.length == 0
          ? 0
          : this.facturas.reduce(
              (n, { totalDiferencia }) =>
                n + parseFloat(totalDiferencia.replace(/,/g, "")),
              0
            )
      );
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
};
</script>

<style></style>
