<template>
  <v-container class="fill-height fluid d-flex justify-center mb-6">
    <v-card>
      <v-card-title>
        <h2>Confirmación de cita</h2>
      </v-card-title>
      <v-container>
        <v-card-text>
          <v-row>
            <h3>{{ medico.firma }}</h3>
          </v-row>
          <br />
          <br />
          <v-row> Paciente: {{ paciente.nombreCompleto || "No data" }} </v-row>
          <v-row>
            Fecha: {{ cita.fecha || "No data" }} Hora:
            {{ cita.hora || "No data" }}
          </v-row>
          <v-row> Estado: {{ cita.estado }} </v-row>
        </v-card-text>

        <v-card-actions
          class="text-right"
          v-if="cita.estado === estado.PENDIENTE"
        >
          <v-btn v-if="!citaProcesada" color="primary" @click="confirmarCita"
            ><v-icon>mdi-check</v-icon>Confirmar cita</v-btn
          >
          <v-btn v-if="!citaProcesada" color="error" @click="cancelarCita"
            ><v-icon>mdi-close</v-icon>Cancelar cita</v-btn
          >
          <div v-if="citaProcesada">
            {{ mensajeCita }}
          </div>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { estadoCita } from "@/utility/enumerations";
import { toDDMMYYDate, toTwelveHourFormat } from "../../utility/dateHelpers.js";
export default {
  name: "confirmar-declinar-cita",
  async mounted() {
    await this.getData();
  },
  data() {
    return {
      medicoId: 0,
      pacienteId: "",
      citaId: 0,
      medico: {},
      paciente: {},
      cita: {},
      url: this.$apiConfig.citaUrl,
      mensajeCita: "",
      citaProcesada: false,
      estado: estadoCita,
    };
  },
  methods: {
    async getCitaId() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.$route.params.citaId);
        }, 1000);
      });
    },
    async confirmarCita() {
      try {
        this.$confirmarCitaQuestionAlert().then(async (si) => {
          if (si) {
            let citaId = await this.getCitaId();
            let response = await axios.post(
              this.$apiConfig.citaUrl + "/confirmar/" + citaId
            );
            if (response.status === 200) {
              this.citaProcesada = true;
              this.mensajeCita = "Cita confirmada satisfactoriamente.";
              await this.getData();
            }
          }
        });
      } catch (e) {
        window.console.log(e);
      }
    },
    async cancelarCita() {
      try {
        this.$cancelarCitaQuestionAlert().then(async (si) => {
          if (si) {
            let citaId = await this.getCitaId();
            let response = await axios.post(
              this.$apiConfig.citaUrl + "/cancelar/" + citaId
            );
            if (response.status === 200) {
              this.citaProcesada = true;
              this.mensajeCita = "Cita cancelada satisfactoriamente.";
              await this.getData();
            }
          }
        });
      } catch (e) {
        window.console.log(e);
      }
    },
    async getData() {
      try {
        let citaResponse = await axios.get(
          this.$apiConfig.citaUrl + "/" + (await this.getCitaId())
        );
        this.cita = citaResponse.data;
        this.cita.fecha = toDDMMYYDate(this.cita.fecha);
        this.cita.hora = toTwelveHourFormat(this.cita.hora);

        let medicoResponse = await axios.get(
          this.$apiConfig.medicosUrl + "/getById/" + this.cita.medicoId
        );
        this.medico = medicoResponse.data;
        let pacienteResponse = await axios.get(
          this.$apiConfig.pacientesUrl + "/" + this.cita.paciente_Id
        );
        this.paciente = pacienteResponse.data;
      } catch (ex) {
        window.console.log(ex);
      }
    },
  },
};
</script>

<style scoped></style>
