<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" @click:outside="cerrarModal()">
      <v-form @submit.prevent="guardar" ref="formRolUsuario" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Registro de roles</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              outlined
              dense
              :rules="descripcionRules"
              label="Descripción"
              v-model="rolUsuario.descripcion"
            ></v-text-field>
            <v-checkbox label="Activo" v-model="rolUsuario.activo"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined rounded type="submit" color="primary">
              <v-icon left>mdi-check</v-icon>Guardar
            </v-btn>
            <v-btn outlined rounded color="error" @click="cerrarModal()">
              <v-icon left>mdi-close</v-icon>Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import jwtAxios from "../../shared/jwtAxios.js";

export default {
  name: "registro-roles-usuarios",
  data() {
    return {
      rolUsuario: {
        id: 0,
        descripcion: "",
        activo: true,
      },
      //VALIDACIONES
      descripcionRules: [
        (valido) => !!valido || "La descripción es requerida.",
      ],
    };
  },
  props: {
    dialog: { type: Boolean, default: false },
    rolUsuarioSeleccionado: {},
  },
  methods: {
    actualizar() {
      jwtAxios
        .put(this.$apiConfig.rolesUsuariosUrl, this.rolUsuario)
        .then(() => {
          this.cerrarModal();
          this.$datosGuardados();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.formRolUsuario.validate()) {
        if (this.rolUsuario.id) {
          this.actualizar();
        } else {
          jwtAxios
            .post(this.$apiConfig.rolesUsuariosUrl, this.rolUsuario)
            .then(() => {
              this.cerrarModal();
              this.$datosGuardados();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
        this.$emit("update:dialog", false);
      }
    },
    limpiar() {
      this.rolUsuario = {
        id: 0,
        descripcion: "",
        activo: true,
      };
    },
    cerrarModal() {
      this.$emit("cerrarModal");
      this.limpiar();
      this.$refs.formRolUsuario.resetValidation();
    },
  },
  watch: {
    rolUsuarioSeleccionado: function(value) {
      this.rolUsuario = value;
    },
  },
};
</script>
