const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const telefonoRegex = /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const soloLetrasRegex = /^[a-zA-Z\s]*$/;
const soloLetrasyÑRegex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
const soloNumerosRegex = /^\d+ $ /;

const telefonoMask = '(###)-###-####';
const cedulaMask = '###-#######-#';

export {
    emailRegex,
    telefonoRegex,
    soloLetrasRegex,
    soloLetrasyÑRegex,
    soloNumerosRegex,
    telefonoMask,
    cedulaMask
}
