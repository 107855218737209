<template>
  <v-container>
    <v-layout>
      <HistoriasClinicas
        :paciente="paciente"
        :dialogHistoriaClinica="dialogHistoriaClinica"
        :historiaClinica="historiaClinica"
        @cerrarModalHistoriaClinica="cerrarModalHistoriaClinica"
        ref="historiaClinica"
        :key="historiaClinicaKey"
      ></HistoriasClinicas>
    </v-layout>

    <v-layout>
      <!--MODAL CONSULTAS ANTERIORES-->
      <v-dialog
        v-model="dialogConsultasAnteriores"
        transition="dialog-bottom-transition"
        hide-overlay
        persistent
        max-width="750px"
      >
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-app-bar dense dark color="primary">
              <v-btn icon dark @click="cerrarModal()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title dense>Consultas Anteriores</v-toolbar-title>
            </v-app-bar>
            <v-container>
              <v-row dense>
                <v-btn
                  @click="crearNuevaConsulta()"
                  text
                  rounded
                  color="primary"
                >
                  <v-icon left>mdi-check</v-icon>Crear Nueva Consulta
                </v-btn>
                <br />
                <br />
              </v-row>
              <v-row v-if="historiasExistentes" dense>
                <v-col
                  v-for="(historiaClinica, i) in historiasClinicas"
                  :key="i"
                  cols="12"
                >
                  <v-card outlined>
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title class="subtitle-1">
                          <p class="filter">
                            {{ historiaClinica.fecha | moment }}
                          </p>
                        </v-card-title>

                        <v-card-subtitle
                          v-text="historiaClinica.motivoConsulta"
                        ></v-card-subtitle>

                        <v-card-actions>
                          <v-btn
                            width="100"
                            color="primary"
                            outlined
                            rounded
                            small
                            @click="abrirHistoriaClinica(historiaClinica)"
                          >
                            <v-icon left>mdi-eye</v-icon>Abrir
                          </v-btn>
                          <v-btn
                            width="100"
                            color="secondary"
                            outlined
                            rounded
                            small
                            @click="cancelarHistoriaClinica(historiaClinica.id)"
                          >
                            <v-icon left>mdi-close</v-icon>Eliminar
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-container>
                  <p style="text-align: center">
                    El paciente seleccionado no ha consultado previamente
                  </p>
                  <br />
                </v-container>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "@/shared/jwtAxios.js";
import moment from "moment";
import swal from "sweetalert";
import HistoriasClinicas from "@/components/historiasClinicasGroup/HistoriasClinicas.vue";

export default {
  components: {
    HistoriasClinicas,
  },
  name: "consultas-anteriores",
  data() {
    return {
      url: this.$apiConfig.historiasClinicasUrl,
      diagnosticoUrl: this.$apiConfig.diagnosticoUrl,
      dialogHistoriaClinica: false,
      historiaClinica: {
        motivoConsulta: "",
        historiaEnfermedad: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        antecedentesQuirurgicos: "",
        antecedentesFamiliares: "",
        antecedentesGinecologicos: "",
        antecedentesOtros: "",
        sonografias: "",
        radiografias: "",
        laboratorios: "",
        otrosEstudios: "",
        peso: "",
        talla: "",
        temperatura: "",
        tensionArterial: "",
        frecuenciaCardiaca: "",
        frecuenciaRespiratoria: "",
        inspeccionGeneral: "",
        diagnosticos: [],
        medicamentosIndicados: [],
        laboratoriosIndicados: [],
        imagenesIndicados: [],
        fechaIndicacionMedicamentos: "",
        fechaIndicacionLaboratorios: "",
        fechaIndicacionImagenes: "",
        observacionIndicacionMedicamentos: "",
        observacionIndicacionLaboratorios: "",
        observacionIndicacionImagenes: "",
      },
      historiaClinicaKey: 0, //PROPIEDAD UTILIZADA PARA FORZAR EL RE-RENDER DEL COMPONENTE
      confirmacionCancelarHistoriaDialog: false,
    };
  },
  props: {
    paciente: Object,
    dialogConsultasAnteriores: Boolean,
    encabezados: Array,
    historiasClinicas: Array,
  },
  computed: {
    historiasExistentes: function () {
      return this.historiasClinicas.length > 0;
    },
  },
  methods: {
    abrirHistoriaClinica(historiaClinica) {
      historiaClinica.paciente = this.paciente;
      this.getDiagnosticosPorHistoriaClinica(historiaClinica);
      this.$store.commit("setHistoriaClinicaActualId", historiaClinica.id);
      this.limpiarIndicaciones();
      this.limpiarFormulariosPersonalizadosItems();
      this.historiaClinica = historiaClinica;
      this.mostrarModalHistoriaClinica();
    },
    cancelarHistoriaClinica(historiaId) {
      let context = this;
      swal({
        text: "¿Desea eliminar esta consulta?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((deseaBorrar) => {
        if (deseaBorrar) {
          axios
            .delete(
              `${this.$apiConfig.historiasClinicasUrl}/cancelar/${historiaId}`
            )
            .then(() => {
              context.$emit("onHistoriaClinicaCancelada");
              this.$datosGuardados();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
      });
    },
    cerrarModal() {
      this.$emit("cerrarModalConsultasAnteriores");
      this.$refs.form.resetValidation();
      this.$store.commit("clearFechaIndicacionMedicamentos");
      this.$store.commit("clearFechaIndicacionLaboratorios");
      this.$store.commit("clearFechaIndicacionImagenes");
    },
    cerrarModalHistoriaClinica() {
      this.dialogHistoriaClinica = false;
      this.limpiarHistoriaClinica();
      this.$store.commit("clearFechaIndicacionMedicamentos");
      this.$store.commit("clearFechaIndicacionLaboratorios");
      this.$store.commit("clearFechaIndicacionImagenes");
      this.$store.commit("clearObservacionIndicacionMedicamentos");
      this.$store.commit("clearObservacionIndicacionLaboratorios");
      this.$store.commit("clearObservacionIndicacionImagenes");
    },
    crearNuevaConsulta() {
      this.limpiarHistoriaClinicaId();
      this.limpiarHistoriaClinica();
      this.mostrarModalHistoriaClinica();
      this.cerrarModal();
    },
    getDiagnosticosPorHistoriaClinica(historiaClinica) {
      axios
        .get(
          this.diagnosticoUrl + "/getByHistoriaClinica/" + historiaClinica.id
        )
        .then(({ data }) => {
          this.historiaClinica.diagnosticos = data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    limpiarPaciente() {
      this.paciente = {
        id: "",
        nombre: "",
        primerApellido: "",
        cedula: "",
        sexo: "",
        fechaNacimiento: "",
        ocupacion: "",
        celular: "",
        telefono: "",
        direccion: "",
        correoElectronico: "",
        numeroAfiliado: "",
        estadoCivil: {
          id: 0,
          descripcion: "",
        },
        seguroMedico: {
          id: 0,
          descripcion: "",
        },
      };
    },
    limpiarHistoriaClinica() {
      this.historiaClinica = {
        id: "",
        motivoConsulta: "",
        historiaEnfermedad: "",
        antecedentesPatologicos: "",
        antecedentesNoPatologicos: "",
        antecedentesQuirurgicos: "",
        antecedentesFamiliares: "",
        antecedentesGinecologicos: "",
        antecedentesOtros: "",
        sonografias: "",
        radiografias: "",
        laboratorios: "",
        otrosEstudios: "",
        peso: "",
        talla: "",
        temperatura: "",
        tensionArterial: "",
        frecuenciaCardiaca: "",
        frecuenciaRespiratoria: "",
        inspeccionGeneral: "",
        diagnosticos: [],
        items: [],
        medicamentosIndicados: [],
        laboratoriosIndicados: [],
        imagenesIndicadas: [],
      };
    },
    moment: function (date) {
      return moment(date);
    },
    mostrarModalHistoriaClinica() {
      this.toggleHistoriaClinicaKey();
      this.dialogHistoriaClinica = true;
    },
    toggleHistoriaClinicaKey() {
      this.historiaClinicaKey = this.historiaClinicaKey + 5;
    },
    limpiarIndicaciones() {
      this.$store.state.medicamentos = [];
      this.$store.state.laboratorios = [];
      this.$store.state.imagenes = [];
    },
    limpiarHistoriaClinicaId() {
      this.$store.state.historiaId = "";
    },
    limpiarFormulariosPersonalizadosItems() {
      this.$store.state.formulariosPersonalizadosItems = [];
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY, h:mm a");
    },
  },
};
</script>

<style>
@import url("../../../src/css/myCss.css");
</style>
