import Vue from "vue";
import Store from "./state/index.js";
import router from "./router/index.js";
import App from "./App.vue";
import Vuetify from "./vuetify/index.js";
import appState from "./state/index.js";
import VueCompositionAPI from "@vue/composition-api";

import ApiConfig from "../src/utility/apiConfig.js";
import VueToast from "vue-toast-notification";
import VueSweetalert2 from "vue-sweetalert2";

import "vue-toast-notification/dist/theme-sugar.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "./css/login-registrar.css";
import "./css/myCss.css";

import { VueMaskDirective } from "v-mask";

import swal from "sweetalert";
import swal2 from 'sweetalert2'
import DatetimePicker from "vuetify-datetime-picker";
import { parseJwt, tokenAlive } from "./utility/jwtHelper.js";
import { isOnline } from "./utility/internetHelpers.js";


if (!isOnline()) {
  swal({
    title: "Aviso!",
    text: "No hay conexión a internet.",
    icon: "warning",
    button: "Ok",
  });
}

if (appState.state.jwtToken) {
  if (!tokenAlive(parseJwt(appState.state.jwtToken).exp)) {
    localStorage.clear();
    location.reload();
  }
}

Vue.config.productionTip = true;
Vue.use(VueCompositionAPI);
Vue.use(DatetimePicker);
Vue.use(VueSweetalert2);
Vue.directive("mask", VueMaskDirective);
Vue.use(VueToast, {
  duration: 3000,
  position: "top-right",
});

const apiConfig = new ApiConfig();


//GLOBALES
Vue.prototype.$apiConfig = apiConfig;



//MESSAGES
Vue.prototype.$camposRequeridosMessage = "Este campo es requerido";
Vue.prototype.$correoValidoMessage = "Debe ingresar un correo electrónico válido";
Vue.prototype.$cedulaDebeEstarCompletaMessage = "La cédula debe tener 11 dígitos";
Vue.prototype.$soloValoreNumericosMessage = "Este campo solo permite valores numéricos";
Vue.prototype.$soloLetrasMessage = "Este campo solo permite letras";



//ALERTS
/////////////////////////////////////////////////////////////
//WARNING
Vue.prototype.$eliminarCitaQuestionAlert = function() {
  return swal({
    text: "¿Desea eliminar esta cita?",
    icon: "warning",
    buttons: {
      confirm: {
        text: "Sí",
        value: true,
        visible: true,
        className: "sweet-info",
      },
      cancel: {
        text: "No",
        value: null,
        visible: true,
        className: "sweet-cancel",
        closeModal: true,
      },
    },
  });
};

Vue.prototype.$confirmarCitaQuestionAlert = function() {
  return swal({
    text: "¿Desea confirmar esta cita?",
    icon: "warning",
    buttons: {
      confirm: {
        text: "Sí",
        value: true,
        visible: true,
        className: "sweet-info",
      },
      cancel: {
        text: "No",
        value: false,
        visible: true,
        className: "sweet-cancel",
        closeModal: true,
      },
    },
  });
};

Vue.prototype.$cancelarCitaQuestionAlert = function() {
  return swal({
    text: "¿Desea cancelar esta cita?",
    icon: "warning",
    buttons: {
      confirm: {
        text: "Sí",
        value: true,
        visible: true,
        className: "sweet-info",
      },
      cancel: {
        text: "No",
        value: false,
        visible: true,
        className: "sweet-cancel",
        closeModal: true,
      },
    },
  });
};

Vue.prototype.$pregunta = function(mensaje) {
  return swal({
    text: mensaje,
    icon: "warning",
    buttons: {
      confirm: {
        text: "Sí",
        value: true,
        visible: true,
        className: "sweet-info",
      },
      cancel: {
        text: "No",
        value: false,
        visible: true,
        className: "sweet-cancel",
        closeModal: true,
      },
    },
  });
};

Vue.prototype.$warningAlert = function(message) {
  swal({
    text: message,
    icon: "warning",
    buttons: {
      confirm: { text: "Ok", className: "sweet-info" },
    },
  });
};

Vue.prototype.$completarCamposRequeridosAlert = function() {
  this.$warningAlert("¡Favor de completar los campos requeridos!");
};

Vue.prototype.$seleccionarFechaAlert = function() {
  this.$warningAlert("Seleccione una fecha antes de guardar");
};

Vue.prototype.$seleccionarHoraAlert = function() {
  this.$warningAlert("Seleccione una hora antes de guardar");
};




//TOASTS
/////////////////////////////////////////////
//SUCCESS
Vue.prototype.$success = function(message) {
  Vue.$toast.success(message);
};

Vue.prototype.$datosGuardados = function() {
  this.$success("Datos guardados correctamente.");
};

//ERROR
Vue.prototype.$error = function(message) {
  Vue.$toast.error(message);
};

Vue.prototype.$errorGuardarDatos = function() {
  this.$error("Error al intentar guardar los datos.");
};

Vue.prototype.$errorCargarDatos = function() {
  this.$error("Error al intentar cargar los datos.");
};

Vue.filter("boolaletra", function(value) {
  return value ? "Si" : "No";
});

new Vue({
  render: (h) => h(App),
  vuetify: Vuetify,
  router: router,
  store: Store,
  base: process.env.BASE_URL,
}).$mount("#app");
