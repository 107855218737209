<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" @click:outside="cerrarModal()">
      <v-form @submit.prevent="guardar" ref="formespecialidad" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Registro de especialidades</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              outlined
              dense
              :rules="descripcionRules"
              label="Descripción"
              v-model="especialidad.descripcion"
            ></v-text-field>
            <v-checkbox label="Activo" v-model="especialidad.activo"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined rounded type="submit" color="primary">
              <v-icon left>mdi-check</v-icon>Guardar
            </v-btn>
            <v-btn outlined rounded color="error" @click="cerrarModal()">
              <v-icon left>mdi-close</v-icon>Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import jwtAxios from "../../shared/jwtAxios.js";

export default {
  name: "registro-especialidades",
  data() {
    return {
      especialidad: {
        id: 0,
        descripcion: "",
        activo: true
      },
      descripcionRules: [valido => !!valido || "La descripción es requerida."]
    };
  },
  props: {
    dialog: { type: Boolean, default: false },
    espcialidadSeleccionada: {}
  },
  methods: {
    actualizar() {
      jwtAxios
        .put(this.$apiConfig.especialidadesUrl, this.especialidad)
        .then(() => {
          this.cerrarModal();
          this.$datosGuardados();
        })
        .catch(error => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.formespecialidad.validate()) {
        if (this.especialidad.id) {
          this.actualizar();
        } else {
          jwtAxios
            .post(this.$apiConfig.especialidadesUrl, this.especialidad)
            .then(() => {
              this.cerrarModal();
              this.$datosGuardados();
            })
            .catch(error => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
        this.$emit("update:dialog", false);
      }
    },
    limpiar() {
      this.especialidad = {
        id: 0,
        descripcion: "",
        activo: true
      };
    },
    cerrarModal() {
      this.$emit("cerrarModal");
      this.$refs.formespecialidad.resetValidation();
      this.limpiar();
    }
  },
  watch: {
    espcialidadSeleccionada: function(value) {
      this.especialidad = value;
    }
  }
};
</script>
