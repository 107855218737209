<template>
  <div>
    <mensaje :mensaje="mensaje" :tipo-mensaje="tipoMensaje"></mensaje>

    <div ref="paypalbuttons"></div>

    <overlay :overlay="this.$store.getters.viewOverlay"></overlay>

    <input
      type="number"
      style="display: none"
      ref="txtDescripcion"
      v-model="planSeleccionado.descripcion"
    />

    <input
      ref="txtMonto"
      type="number"
      style="display: none"
      v-model="planSeleccionado.monto"
    />
    
    <v-btn @click="onPlanSeleccionado(1)">Mostrar paypal</v-btn>
  </div>
</template>

<script>
import store from "@/state/index.js";
import Mensaje from "../helpers/Mensaje.vue";
import Overlay from "@/components/helpers/Overlay";
import { tipoMensajeEnum, tipoPlan } from "@/utility/enumerations";
import axios from "axios";

export default {
  name: "paypal-checkout",
  components: {
    Mensaje,
    Overlay,
  },
  mounted: function () {
    // this.planSeleccionado =
    //   JSON.parse(JSON.stringify(store.state.planSeleccionado)) || {};
    this.usuarioRequest = JSON.parse(JSON.stringify(store.state.usuario)) || {};

    const script = document.createElement("script");
    script.src =
      `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
    document.head.appendChild(script);
  },
  data: function () {
    return {
      loaded: false,
      planSeleccionado: {},
      usuario: {
        nombre: "",
        apellido: "",
        email: "",
        contrasena: "",
        telefono: "",
      },
      cuentaRequest: {
        nombre: "",
        apellido: "",
        email: "",
        codigo: "",
        plandId: 0,
        telefono: "",
      },
      usuarioRequest: {
        cuentaId: 0,
        nombre: "",
        apellido: "",
        email: "",
        contrasena: "",
        telefono: "",
      },
      mensaje: "",
      tipoMensaje: "",
    };
  },
  methods: {
    onPlanSeleccionado(subscriptionId) {
      this.loaded = true;
      let vueContext = this;
      window.paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "blue",
            shape: "rect",
            label: "paypal",
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: subscriptionId
            })
            // return actions.order.create({
            //   purchase_units: [
            //     {
            //       description: `Plan ${localStorage.getItem(
            //         "@planDescripcion"
            //       )}`,
            //       amount: {
            //         currency_code: "USD",
            //         value: localStorage.getItem("@planMonto"),
            //       },
            //     },
            //   ],
            // });
          },
          onApprove: async function (data, actions) {
            
          },
          onCancel: function (data) {
            //window.location.reload();
          },
          onError: function (error) {
            vueContext.mostrarMensaje(error, tipoMensajeEnum.ERROR);
          },
        })
        .render(this.$refs.paypalbuttons);
    },
    onPagoExitoso() {
      this.$emit("onPagoExitoso", this.planSeleccionado);
    },
    mostrarMensaje(mensaje, tipo) {
      this.mensaje = mensaje;
      this.tipoMensaje = tipo;
    },
    getPlanes() {
      axios
          .get(this.$apiConfig.planesUrl)
          .then((response) => {
            this.planes = response.data;
          })
          .catch((error) => {
            this.$errorCargarDatos();
            window.console.log(error);
          });
    },
    backToHome(){
      this.$router.push("/");
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  },
  watch: {
    // planSeleccionado: {
    //   handler(newPlan) {
    //     if (
    //       newPlan.descripcion.toLowerCase() === tipoPlan.GRATIS.toLowerCase()
    //     ) {
    //       this.onPagoExitoso();
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>
