<template>
  <v-card>
    <v-card-title>Paciente</v-card-title>
    <v-card-subtitle>
      <v-autocomplete
        :loading="loading"
        class="mt-4"
        hide-no-data
        label="Buscar paciente..."
        hide-details
        item-text="nombreCompleto"
        item-value="id"
        :items="pacientes"
        @keyup.enter="getPacientes()"
        dense
        outlined
        append-icon="null"
        v-model="pacienteSeleccionado"
        :search-input.sync="searchPaciente"
        return-object
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title
              >Busque los articulos que desee facturar</v-list-item-title
            >
          </v-list-item>
        </template>

        <template v-slot:selection="{ attr, on, item, selected }">
          <v-chip
            v-bind="attr"
            :input-value="selected"
            style="background-color:transparent;"
            v-on="on"
          >
            <span v-text="item.nombre"></span>
            <span v-text="item.cedula" class="ml-4"></span>
          </v-chip>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.nombre"></v-list-item-title>
            <v-list-item-subtitle v-text="item.cedula"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <div class="container">
        <v-row dense>
          <v-col cols="4">
            <label class="font-weight-black">Código:</label>
          </v-col>
          <v-col cols="8">
            <label>
              {{
                pacienteSeleccionado !== null && pacienteSeleccionado !== null
                  ? pacienteSeleccionado.id
                  : ""
              }}
            </label>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <label class="font-weight-black">Nombre:</label>
          </v-col>
          <v-col cols="8">
            <label>
              {{
                pacienteSeleccionado !== null && pacienteSeleccionado !== null
                  ? pacienteSeleccionado.nombreCompleto
                  : ""
              }}
            </label>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <label class="font-weight-black">Cedula:</label>
          </v-col>
          <v-col cols="8">
            <label>
              {{
                pacienteSeleccionado !== null && pacienteSeleccionado !== null
                  ? pacienteSeleccionado.cedula
                  : ""
              }}
            </label>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "../../shared/jwtAxios.js";
import { isEmpty } from "../../utility/jwtHelper";

export default {
  name: "paciente-header",
  props: {
    limpiaPacienteSeleccionado: false,
  },
  created() {
    this.getPacientes();
  },
  data() {
    return {
      loading: false,
      pacientes: [],
      pacientesFromDB: [],
      pacienteSeleccionado: {},
      searchPaciente: "",
    };
  },
  methods: {
    getPacientes() {
      axios
        .get(
          this.$apiConfig.pacientesUrl +
            "/getPacientesByCuenta", {params: {cuentaId: this.$store.state.cuentaId, nombrePaciente: this.searchPaciente}}
        )
        .then((response) => {
          this.pacientesFromDB = response.data;
        })
        .catch((error) => {
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
    filtrarPacientes(val) {
      if (val) {
        this.loading = true;
        setTimeout(() => {
          if (this.pacientesFromDB.length > 0) {
             this.pacientes = this.pacientesFromDB.filter((p) => {
             return p.nombreCompleto.toLowerCase().includes(val.toLowerCase());
            });
          }
          this.loading = false;
        }, 500);
      }
    },
  },
  watch: {
    searchPaciente(val) {
      this.filtrarPacientes(val);
    },
    pacienteSeleccionado(val) {
      if (val !== null && val !== undefined) {
        if (isEmpty(val)) {
          return;
        }
        this.$emit("pacienteSeleccionado", val);
      }
    },
    limpiaPacienteSeleccionado(val) {
      if (val) {
        this.pacienteSeleccionado = {};
      }
    },
  },
};
</script>

<style></style>
