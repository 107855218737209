<template>
  <div>
    <registro-usuario
      :dialog.sync="dialog"
      :usuarioSeleccionado="usuario"
      :esEditar.sync="esEditar"
      @onGuardar="onGuardar"
      @cancelar="cancelar"
    ></registro-usuario>

    <listado-usuarios
      :usuarios="usuarios"
      :encabezados="encabezados"
      :itemsPorPagina="10"
      :esRegistrar="true"
      @crearUsuario="crearUsuario"
      @seleccionarUsuario="editarUsuario"
    ></listado-usuarios>
  </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import RegistroUsuario from "../components/usuarios/RegistroUsuario.vue";
import ListadoUsuarios from "../components/usuarios/ListadoUsuarios.vue";
import jwtAxios from "../shared/jwtAxios.js";
import store from "../state/index.js";

export default {
  name: "usuario-view",
  components: {
    RegistroUsuario,
    ListadoUsuarios,
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  created() {
    this.getUsuarios();
  },
  data() {
    return {
      dialog: false,
      usuario: {},
      usuarios: [],
      esEditar: false,
      encabezados: [
        { text: "Rol", value: "rol.descripcion" },
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "Email", value: "email" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    getUsuarios() {
      jwtAxios
        .get(`${this.$apiConfig.usuariosUrl}/${store.state.cuentaId}`)
        .then((response) => {
          this.usuarios = response.data;
        })
        .catch((error) => {
          window.console.log(error.response.data);
        });
    },
    mostrarDialog() {
      this.dialog = true;
    },
    cancelar() {
      this.dialog = false;
      this.limpiar();
    },
    crearUsuario() {
      this.mostrarDialog();
      this.esEditar = false;
      this.$emit("update:esEditar", false);
    },
    editarUsuario(usuarioParam) {
      this.usuario = usuarioParam;
      this.mostrarDialog();
      this.esEditar = true;
      this.$emit("update:esEditar", true);
    },
    limpiar() {
      this.usuario = {};
    },
    onGuardar() {
      this.dialog = false;
      this.getUsuarios();
    },
  },
};
</script>
