<template>
  <div id="registro-centros-medicos">
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="600px"
        @click:outside="cerrarModal()"
      >
        <v-form
          id="formCentroMedico"
          @submit.prevent="guardar"
          ref="formCentroMedico"
          lazy-validation
        >
          <v-card>
            <v-card-title>
              <span class="headline">Registro de centros médicos</span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                outlined
                dense
                label="Nombre"
                :rules="descripcionRules"
                v-model="centroMedico.descripcion"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="Dirección"
                v-model="centroMedico.direccion"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="centroMedico.telefono"
                label="Teléfono"
                v-mask="mascaraTelefono"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded type="submit" color="primary">
                <v-icon left>mdi-check</v-icon>Guardar
              </v-btn>
              <v-btn outlined rounded color="error" @click="cerrarModal()">
                <v-icon left>mdi-close</v-icon>Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import jwtAxios from "../../shared/jwtAxios.js";
import { telefonoMask } from "../../utility/validaciones";

export default {
  name: "registro-centros-medicos",
  props: {
    dialog: { type: Boolean, default: false },
    centroMedicoSeleccionado: {},
  },
  data() {
    return {
      centroMedico: {
        id: 0,
        descripcion: "",
        direccion: "",
        telefono: "",
      },
      //VALIDACIONES
      descripcionRules: [
        (valido) => !!valido || "La descripción es requerida.",
      ],
      mascaraTelefono: telefonoMask,
    };
  },
  watch: {
    centroMedicoSeleccionado: function(value) {
      this.centroMedico = value;
    },
  },
  methods: {
    actualizar() {
      jwtAxios
        .put(this.$apiConfig.centrosMedicosUrl, this.centroMedico)
        .then(() => {
          this.cerrarModal();
          this.$datosGuardados();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.formCentroMedico.validate()) {
        if (this.centroMedico.id > 0) {
          this.actualizar();
        } else {
          jwtAxios
            .post(this.$apiConfig.centrosMedicosUrl, this.centroMedico)
            .then(() => {
              this.cerrarModal();
              this.$datosGuardados();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
      }
    },
    limpiar() {
      this.centroMedico = {
        id: 0,
        descripcion: "",
        direccion: "",
        telefono: "",
      };
    },
    cerrarModal() {
      this.limpiar();
      this.$emit("cerrarModal");
    },
  },
};
</script>
