import axios from "axios";
import ApiConfig from "@/utility/apiConfig";
import store from "@/state/index.js";

const api = new ApiConfig();

function parseJwt(token) {
  if (token !== null) {
    let base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
        atob(base64).split("").map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(""));
    return JSON.parse(jsonPayload);
  }
}

function tokenAlive(exp) {
  if (Date.now() >= exp * 1000) {
    return false;
  }
  return true;
}

function tryRefreshToken(token) {
  let refreshToken = store.state.refreshToken;
  if (
    (token === null || token === undefined) &&
    (refreshToken === null || refreshToken === undefined)
  ) {
    return false;
  }
  let tokenRequest = {
    accessToken: token,
    refreshToken: refreshToken,
  };

  let isRefreshSuccess = true;
  
  try {
    axios
      .post(api.tokensUrl, tokenRequest)
      .then((response) => {
        store.dispatch("setJwtToken", response.data.newToken);
        store.dispatch("setRefreshToken", response.data.newRefreshToken);
        window.console.log("Token refrescado correctamente.");
      })
      .catch((error) => {
        isRefreshSuccess = false;
        window.console.log(error);
      });
  } catch (error) {
    isRefreshSuccess = false;
    window.console.log(error);
  }
  return isRefreshSuccess;
}

let isEmpty = (obj) => Object.entries(obj).length === 0;

export {
  parseJwt,
  tokenAlive,
  tryRefreshToken,
  isEmpty
}
