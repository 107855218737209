<template>
  <div id="registro-plantilla">
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="600px"
        lazy-validation
        @click:outside="cerrarModal()"
      >
        <v-form @submit.prevent="guardar()" ref="form" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="headline">Plantilla de {{ contexto }}</span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                id="txtDescripcion"
                ref="txtDescripcion"
                outlined
                dense
                :rules="descripcionRules"
                label="Descripción"
                v-model="plantillaIndicacion.descripcion"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded type="submit" color="primary">
                <v-icon left>mdi-check</v-icon>Guardar
              </v-btn>
              <v-btn outlined rounded color="error" @click="cerrarModal()">
                <v-icon left>mdi-close</v-icon>Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { contextoPlantillaIndicacion } from "../../../../utility/enumerations.js";
import jwtAxios from "../../../../shared/jwtAxios.js";

export default {
  name: "registro-plantilla",
  props: {
    contexto: "",
    dialog: false,
  },
  data() {
    return {
      plantillaIndicacion: {
        id: 0,
        cuentaId: 0,
        descripcion: "",
        detalle: [],
      },
      descripcionRules: [(v) => !!v || "La descripción es requerida."],
    };
  },
  methods: {
    guardar() {
      this.plantillaIndicacion.cuentaId = Number.parseInt(
        this.$store.state.cuentaId.toString()
      );
      window.console.log(this.contexto);
      switch (this.contexto) {
        case contextoPlantillaIndicacion.MEDICAMENTO:
          this.guardarPlantillaMedicamento();
          break;
        case contextoPlantillaIndicacion.IMAGEN:
          this.guardarPlantillaImagen();
          break;
        case contextoPlantillaIndicacion.LABORATORIO:
          this.guardarPlantillaLaboratorio();
          break;
      }
    },
    formValido() {
      return this.$refs.form.validate();
    },
    guardarPlantillaMedicamento() {
      if (this.formValido()) {
        jwtAxios
          .post(
            this.$apiConfig.plantillaIndicacionMedicamentos,
            this.plantillaIndicacion
          )
          .then(() => {
            this.$datosGuardados();
            this.$refs.form.resetValidation();
            this.cerrarModal();
            this.limpiar();
            this.onGuardado();
          })
          .catch((error) => window.console.log(error));
      }
    },
    guardarPlantillaImagen() {
      if (this.formValido()) {
        jwtAxios
          .post(
            this.$apiConfig.plantillaIndicacionImagenes,
            this.plantillaIndicacion
          )
          .then(() => {
            this.$datosGuardados();
            this.$refs.form.resetValidation();
            this.cerrarModal();
            this.limpiar();
            this.onGuardado();
          })
          .catch((error) => window.console.log(error));
      }
    },
    guardarPlantillaLaboratorio() {
      if (this.formValido()) {
        jwtAxios
          .post(
            this.$apiConfig.plantillaIndicacionLaboratorios,
            this.plantillaIndicacion
          )
          .then(() => {
            this.$datosGuardados();
            this.$refs.form.resetValidation();
            this.cerrarModal();
            this.limpiar();
            this.onGuardado();
          })
          .catch((error) => window.console.log(error));
      }
    },
    onGuardado() {
      this.$emit("onPlantillaGuardada");
    },
    cerrarModal() {
      this.$emit("cerrarModal");
    },
    focusDescripcion() {
      this.$refs.txtDescripcion.$refs.input.focus();
    },
    limpiar() {
      this.plantillaIndicacion = {
        id: 0,
        cuentaId: 0,
        descripcion: "",
        detalle: [],
      };
    },
    getMedicamentosFromStore() {
      return this.$store.state.medicamentos;
    },
    getImagenesFromStore() {
      return this.$store.state.imagenes;
    },
    getLaboratoriosFromStore() {
      return this.$store.state.laboratorios;
    },
  },
  watch: {
    contexto: function (value) {
      switch (value) {
        case contextoPlantillaIndicacion.MEDICAMENTO:
          this.plantillaIndicacion.detalle = this.getMedicamentosFromStore();
          break;
        case contextoPlantillaIndicacion.IMAGEN:
          this.plantillaIndicacion.detalle = this.getImagenesFromStore();
          break;
        case contextoPlantillaIndicacion.LABORATORIO:
          this.plantillaIndicacion.detalle = this.getLaboratoriosFromStore();
          break;
      }
    },
    dialog: function (value) {
      if (value) {
        setTimeout(() => {
          this.focusDescripcion();
        });
      }
    },
  },
};
</script>
