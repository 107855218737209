<template>
  <v-container id="container">
    <v-row dense>
      <v-col cols="12" md="6" sm="6">
        <div class="logo-container">
          <img src="@/assets/logo1.png" class="logo" alt />
          <p class="app-description"></p>
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-card id="login-card" elevation="10" class="mx-auto">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ tituloActual }}</span>
          </v-card-title>

          <v-window v-model="step" touchless>
            <!--LOGIN-->
            <v-window-item :value="1">
              <v-form
                @submit.prevent="loginMethod"
                id="formlogin"
                lazy-validation
                ref="formlogin"
              >
                <mensaje
                  v-bind:mensaje="mensajeError"
                  :tipo-mensaje="tipoMensaje"
                ></mensaje>

                <v-text-field
                  outlined
                  dense
                  prepend-icon="person"
                  v-model="login.email"
                  ref="txtEmail"
                  id="txtEmail"
                  label="Email"
                  :rules="emailRules"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  prepend-icon="lock"
                  v-model="login.contrasena"
                  label="Contraseña"
                  :type="inputType"
                  :rules="contrasenaRules"
                ></v-text-field>
                <v-checkbox
                  v-model="mostrarContrasena"
                  label="Mostrar contraseña"
                ></v-checkbox>

                <a id="txtOlvidoContrasena" @click="olvidoContrasena()"
                  >¿Olvidó su contraseña?</a
                >
                <v-container>
                  <v-row justify="space-between">
                    <v-col>
                      <v-btn
                        class="d-none"
                        @click="registrarse()"
                        text
                        color="primary"
                        >Registrarse</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        outlined
                        rounded
                        @click="loginMethod()"
                        color="primary"
                        >Entrar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-window-item>

            <!--CODIGO CAMBIO CONTRASENA-->
            <v-window-item :value="2">
              <v-container>
                <mensaje
                  v-if="mensajeError.length > 0"
                  v-bind:mensaje="mensajeError"
                ></mensaje>

                <v-alert v-else dense type="info">
                  Se ha enviado un código de verificación a su correo e
                  insertelo en la caja de texto para proceder con el cambio de
                  contraseña.
                </v-alert>
                <v-text-field
                  outlined
                  dense
                  :rules="codigoConfirmadorCambioContrasenaRules"
                  v-model="codigoConfirmadorCambioContrasena"
                  label="Inserte el código"
                ></v-text-field>

                <v-row :justify="'space-between'">
                  <v-col cols="12" sm="4"></v-col>
                  <v-col cols="12" sm="4">
                    <v-btn
                      v-if="codigoConfirmadorCambioContrasena.length === 6"
                      @click="verificarCodigo()"
                      outlined
                      rounded
                      dense
                      color="primary"
                      >Siguiente</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>

            <!--CAMBIO CONTRASENA-->
            <v-window-item :value="3">
              <cambiar-contrasena
                @onCambiarContrasena="onCambiarContrasena"
              ></cambiar-contrasena>
            </v-window-item>
          </v-window>

          <v-divider v-if="step === 2"></v-divider>
          <v-card-actions v-if="step === 2">
            <v-btn v-if="step === 2" text @click="paginaAnterior"
              >Anterior</v-btn
            >
            <v-spacer></v-spacer>
            <p style></p>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <overlay :overlay="this.$store.getters.viewOverlay"></overlay>
  </v-container>
</template>

<script>
import LoginRegisterLayout from "@/layouts/LoginRegisterLayout.vue";
import Mensaje from "../helpers/Mensaje.vue";
import Overlay from "../helpers/Overlay.vue";
import CambiarContrasena from "./CambiarContrasena.vue";
import { emailRegex } from "@/utility/validaciones";
import axios from "axios";
import store from "@/state/index";
import { tipoMensajeEnum } from "@/utility/enumerations";

export default {
  name: "login",
  components: {
    LoginRegisterLayout,
    Mensaje,
    CambiarContrasena,
    Overlay,
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.loginMethod();
      }
    });
  },
  mounted() {
    this.$emit("update:layout", LoginRegisterLayout);
    this.$refs.txtEmail.focus();
  },
  data() {
    return {
      login: {
        email: "",
        contrasena: "",
      },
      tipoMensaje: "",
      mensajeError: "",
      step: 1,
      mostrarContrasena: false,
      inputType: "password",
      codigoConfirmadorCambioContrasena: "",

      //VALIDACIONES
      emailRules: [
        (v) => emailRegex.test(v) || "Ingrese un E-mail válido",
        (v) => !!v || "El E-Mail es requerido.",
      ],
      contrasenaRules: [(v) => !!v || "La contraseña es requerida."],
      codigoConfirmadorCambioContrasenaRules: [
        (v) => v.length === 6 || "El código debe tener 6 dígitos.",
      ],
    };
  },
  computed: {
    tituloActual: function () {
      switch (this.step) {
        case 1:
          return "Login";
        case 2:
          return "Confirmación de identidad";
        case 3:
          return "Cambiar contraseña";
      }
    },
  },
  methods: {
    loginMethod() {
      if (this.$refs.formlogin.validate()) {
        store
          .dispatch("Login", this.login)
          .then(() => {
            this.$router.push("/home");
            this.$store.dispatch("getConfiguracionReporte");
          })
          .catch((error) => {
            this.mostrarMensaje(
              "Usuario/Contrasena incorrecta.",
              tipoMensajeEnum.ERROR
            );
            window.console.error(error);
          });
      }
    },
    registrarse() {
      this.$router.push("registrar").catch((error) => error);
    },
    olvidoContrasena() {
      if (emailRegex.test(this.login.email)) {
        axios
          .post(`${this.$apiConfig.usuariosUrl}/VerificarEmailExiste`, null, {
            params: { email: this.login.email },
          })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("setEmail", this.login.email);
              this.mensajeError = "";
              this.enviarCodigo();
              this.step++;
            }
          })
          .catch((error) => {
            this.mostrarMensaje(error.response.data, tipoMensajeEnum.ERROR);
          });
      }
    },
    enviarCodigo() {
      axios
        .post(
          `${this.$apiConfig.usuariosUrl}/EnviarCodigoCambioContrasena`,
          null,
          { params: { email: this.login.email } }
        )
        .then((response) => {
          if (response.status === 200) {
            this.mensajeError = "";
          }
        })
        .catch((error) => {
          this.mensajeError = error.response.data;
        });
    },
    verificarCodigo() {
      let cambioContrasenaRequest = {
        email: localStorage.getItem("@email"),
        codigo: this.codigoConfirmadorCambioContrasena,
      };
      axios
        .post(
          `${this.$apiConfig.usuariosUrl}/VerificarCodigoContrasena`,
          cambioContrasenaRequest
        )
        .then((response) => {
          if (response.status === 200) {
            this.step++;
            this.mensajeError = "";
          }
        })
        .catch((error) => {
          this.mostrarMensaje(error.response.data, tipoMensajeEnum.ERROR);
        });
    },
    paginaAnterior() {
      this.step--;
      if (localStorage.getItem("@email")) {
        localStorage.removeItem("@email");
      }
    },
    onCambiarContrasena(value) {
      this.step = value;
    },
    mostrarMensaje(mensaje, tipo) {
      this.tipoMensaje = tipo;
      this.mensajeError = mensaje;
    },
  },
  watch: {
    mostrarContrasena: function (value) {
      if (value) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
  },
};
</script>

<style scoped>
#login-form {
  width: 500px;
  max-width: 500px;
}

#formlogin {
  padding: 10px;
}
.margin-top {
  margin-top: 10px;
}

h3 {
  font-size: 1.5em;
  font-weight: 500;
}

#txtOlvidoContrasena {
  margin-left: 28px;
  font-size: 0.9em;
}
</style>
