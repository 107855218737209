<template>
  <v-form @submit.prevent="guardar" ref="formmedico" lazy-validation>
    <v-card max-width="700">
      <mensaje :mensaje="mensaje" :tipo-mensaje="tipoMensaje"></mensaje>
      <v-card-text>
        <v-select
          outlined
          dense
          :items="especialidades"
          item-text="descripcion"
          item-value="id"
          :rules="especialidadRules"
          v-model="especialidadSeleccionadaId"
          label="Especialidad"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="nuevaEspecialidad"
        ></v-select>
        <v-select
          outlined
          dense
          :items="centrosMedicos"
          item-text="descripcion"
          item-value="id"
          v-model="centroMedicoSeleccionadoId"
          label="Centro Médico"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="nuevoCentroMedico"
        ></v-select>
        <v-text-field
          outlined
          dense
          label="Firma"
          v-model="medico.firma"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Exequatur"
          v-model="medico.exequatur"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Dirección"
          :rules="direccionRules"
          v-model="medico.direccion"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Teléfono de Contacto"
          v-mask="telefonoContactoMask"
          v-model="medico.telefonoContacto"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Reporte de Imágenes"
          v-model="medico.reporteImagenes"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Reporte de Laboratorios"
          v-model="medico.reporteLaboratorios"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Reporte de Medicamentos"
          v-model="medico.reporteMedicamentos"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Reporte de Historia Clínica"
          v-model="medico.reporteHistoriaClinica"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded type="submit" color="primary">
          <v-icon left>mdi-check</v-icon>Guardar
        </v-btn>
        <v-btn outlined rounded color="error" @click="cancelar()">
          <v-icon left>mdi-close</v-icon>Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    <registro-especialidades
      ref="especialidadDialog"
      :dialog.sync="especialidadDialog"
      @cerrarModal="cerrarEspecialidadDialog"
    ></registro-especialidades>
    <registro-centros-medicos
      ref="centroMedicoDialog"
      :dialog.sync="centroMedicoDialog"
      @cerrarModal="cerrarCentroMedicoDialog"
    ></registro-centros-medicos>
  </v-form>
</template>

<script>
import jwtAxios from "../../shared/jwtAxios.js";
import Mensaje from "../helpers/Mensaje.vue";
import RegistroCentrosMedicos from "./RegistroCentrosMedicos.vue";
import RegistroEspecialidades from "./RegistroEspecialidades.vue";
import store from "../../state/index.js";
import { Rol } from "../../utility/roles.js";
import { telefonoMask } from "../../utility/validaciones.js";

export default {
  name: "registro-medico",
  components: {
    Mensaje,
    RegistroCentrosMedicos,
    RegistroEspecialidades,
  },
  created() {
    this.getAllData();
  },
  data() {
    return {
      medico: {
        usuarioId: 0,
        especialidadId: 0,
        centroMedicoId: 0,
        firma: "",
        exequatur: "",
        direccion: "",
        telefonoContacto: "",
        reporteImagenes: "",
        reporteLaboratorios: "",
        reporteMedicamentos: "",
        reporteHistoriaClinica: "",
      },
      especialidades: [],
      especialidadSeleccionadaId: 0,
      centrosMedicos: [],
      centroMedicoSeleccionadoId: 0,
      mensaje: "",
      tipoMensaje: "",
      centroMedicoDialog: false,
      especialidadDialog: false,
      telefonoContactoMask: telefonoMask,

      //VALIDACIONES
      especialidadRules: [(v) => v > 0 || "La especialidad es requerida."],
      firmaRules: [(v) => !!v || "La firma es requerida."],
      direccionRules: [(v) => !!v || "La dirección es requerida."],
    };
  },
  methods: {
    getAllData() {
      this.getMedico();
      this.getEspecialidades();
      this.getCentrosMedicos();
    },
    getEspecialidades() {
      jwtAxios
        .get(this.$apiConfig.especialidadesUrl)
        .then((response) => {
          this.especialidades = response.data;
        })
        .catch((error) => window.console.log(error));
    },
    getCentrosMedicos() {
      jwtAxios
        .get(this.$apiConfig.centrosMedicosUrl)
        .then((response) => {
          this.centrosMedicos = response.data;
        })
        .catch((error) => {
          this.tipoMensaje = "error";
          this.mensajeError = error.response;
          window.console.log(error);
        });
    },
    getMedico() {
      store
        .dispatch("getPerfilMedico")
        .then((response) => {
          this.medico = response.data;
          this.especialidadSeleccionadaId = this.medico.especialidadId;
          this.centroMedicoSeleccionadoId = this.medico.centroMedicoId;
          this.reporteImagenes = this.medico.reporteImagenes;
          this.reporteLaboratorios = this.medico.reporteLaboratorios;
          this.reporteMedicamentos = this.medico.reporteMedicamentos;
          this.reporteHistoriaClinica = this.medico.reporteHistoriaClinica;
        })
        .catch((error) => window.console.log(error));
    },
    actualizar() {
      this.medico.especialidadId = this.especialidadSeleccionadaId;
      this.medico.centroMedicoId = this.centroMedicoSeleccionadoId;
      jwtAxios
        .put(this.$apiConfig.medicosUrl, this.medico)
        .then(() => {
          this.$success("Perfil creado satisfactoriamente.");
          this.limpiar();
          this.getMedico();
        })
        .catch((error) => {
          this.mostrarMensaje(error.response, "error");
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.formmedico.validate()) {
        if (this.medico.id > 0) {
          this.actualizar();
        } else {
          this.medico.usuarioId = parseInt(this.$store.state.usuarioId);
          this.medico.especialidadId = this.especialidadSeleccionadaId;
          this.medico.centroMedicoId = this.centroMedicoSeleccionadoId;
          jwtAxios
            .post(this.$apiConfig.medicosUrl, this.medico)
            .then(() => {
              this.$success("Perfil creado satisfactoriamente.");
              this.limpiar();
              this.getMedico();
            })
            .catch((error) => {
              this.mostrarMensaje(error.response, "error");
              window.console.log(error);
            });
        }
        this.getMedico();
      }
    },
    mostrarMensaje(mensaje, tipo) {
      this.tipoMensaje = tipo;
      this.mensaje = mensaje;
    },
    cancelar() {
      this.limpiar();
    },
    limpiar() {
      this.medico = {
        especialidadId: 0,
        centroMedicoId: 0,
        firma: "",
        Exequatur: "",
        Direccion: "",
      };
      this.especialidadSeleccionadaId = 0;
      this.centroMedicoSeleccionadoId = 0;
      this.$refs.formmedico.resetValidation();
    },
    nuevaEspecialidad() {
      this.especialidadDialog = true;
    },
    nuevoCentroMedico() {
      this.centroMedicoDialog = true;
    },
    cerrarEspecialidadDialog() {
      this.especialidadDialog = false;
      this.$nextTick(() => this.getEspecialidades());
    },
    cerrarCentroMedicoDialog() {
      this.centroMedicoDialog = false;
      this.$nextTick(() => this.getCentrosMedicos());
    },
  },

  computed: {
    esAdministrador() {
      return this.$store.getters.rolUsuario === Rol.Admin;
    },
  },
};
</script>
