import axios from "axios";
import store from "../state/index.js";
import ApiConfig from "../utility/apiConfig.js";
import { parseJwt, tokenAlive } from "../utility/jwtHelper.js";

const apiConfig = new ApiConfig();
const jwtAxios = axios.create();

const token = () => window.localStorage.getItem("@jwtToken");
const refreshToken = () => window.localStorage.getItem("@refreshToken");
const tokenRefreshUrl = `${apiConfig.tokensUrl}/refrescar`;

jwtAxios.interceptors.request.use(
  function(config) {
    if (tokenAlive(parseJwt(token()).exp)) {
      config.headers.Authorization = `Bearer ${token()}`;
    }
    return config;
  },
  function(error) {
    window.console.log(error);
    return Promise.reject(error);
  }
);

jwtAxios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    let {
      response: { status },
    } = error;
    if (error.config && error.response && status === 401) {
      let tokenRequest = {
        accessToken: token(),
        refreshToken: refreshToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(tokenRefreshUrl, tokenRequest)
          .then((response) => {
            store.commit("setJwtToken", response.data.newToken);
            store.commit("setRefreshToken", response.data.newRefreshToken);
            axios.defaults.headers.common.Authorization = `Bearer ${token()}`;
            error.config.headers.Authorization = `Bearer ${token()}`;
            resolve(axios(error.config));
          })
          .catch((error) => {
            window.console.log(error);
            reject(error);
          });
      });
    }
    return Promise.reject(error);
  }
);

export default jwtAxios;
