<template>
  <v-app id="inspire">
    <component class="mt-3" :is="layout">
      <router-view :layout.sync="layout"></router-view>
    </component>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "app",
  components: {
    Navbar,
  },
  data() {
    return {
      layout: `div`,
    };
  },
};
</script>
