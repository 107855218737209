<template>
  <div id="listado-articulos">
    <h1 v-if="esRegistrar" class="mt-6">Artículos</h1>
    <v-data-table
      fixed-header
      :headers="encabezados"
      :items="articulosFiltrados"
      :items-per-page="5"
      :loading="isLoading"
      loading-text="Cargando datos... Por favor espere"
      class="elevation-1"
    >
      <template v-slot:top>
        <div class="mx-4">
          <v-row>
            <v-text-field
              v-model="busqueda"
              label="Buscar"
              ref="txtBuscar"
              :append-icon="esRegistrar ? iconoCrear : undefined"
              @click:append="crear()"
            ></v-text-field>
          </v-row>
        </div>
      </template>

      <template v-slot:item.activo="{ item }">
        <div>
          <label>{{ item.activo | boolaletra }}</label>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="primary" v-if="esRegistrar" @click="seleccionar(item)">mdi-pencil</v-icon>
        <v-icon color="primary" v-else @click="seleccionar(item)">mdi-plus</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "listado-usuarios",
  mounted() {
    this.$refs.txtBuscar.focus();
  },
  data() {
    return {
      busqueda: "",
      iconoCrear: "mdi-plus",
      isLoading: false,
      encabezados: [
        { text: "Descripción", value: "descripcion" },
        { text: "Precio", value: "precio" },
        { text: "", value: "actions", sortable: false }
      ]
    };
  },
  props: {
    articulos: Array,
    esRegistrar: Boolean //Propiedad que decide si mostrar el boton editar o el boton de seleccionar.
  },
  computed: {
    rows() {
      return this.usuarios.length;
    },
    articulosFiltrados() {
      return this.articulos.filter(articulo => {
        return articulo.descripcion
          .toUpperCase()
          .includes(this.busqueda.toUpperCase());
      });
    }
  },
  methods: {
    crear() {
      this.$emit("crearArticulo", null);
    },
    seleccionar(articuloParam) {
      this.$emit("seleccionarArticulo", articuloParam);
    }
  },
  watch: {
    articulos: function(val) {
      this.isLoading = val.length > 0 ? false : true;
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
    }
  }
};
</script>
