var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Paciente")]),_c('v-card-subtitle',[_c('v-autocomplete',{staticClass:"mt-4",attrs:{"loading":_vm.loading,"hide-no-data":"","label":"Buscar paciente...","hide-details":"","item-text":"nombreCompleto","item-value":"id","items":_vm.pacientes,"dense":"","outlined":"","append-icon":"null","search-input":_vm.searchPaciente,"return-object":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getPacientes()},"update:searchInput":function($event){_vm.searchPaciente=$event},"update:search-input":function($event){_vm.searchPaciente=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v("Busque los articulos que desee facturar")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"background-color":"transparent"},attrs:{"input-value":selected}},'v-chip',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(item.nombre)}}),_c('span',{staticClass:"ml-4",domProps:{"textContent":_vm._s(item.cedula)}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.cedula)}})],1)]}}]),model:{value:(_vm.pacienteSeleccionado),callback:function ($$v) {_vm.pacienteSeleccionado=$$v},expression:"pacienteSeleccionado"}})],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"container"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{staticClass:"font-weight-black"},[_vm._v("Código:")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('label',[_vm._v(" "+_vm._s(_vm.pacienteSeleccionado !== null && _vm.pacienteSeleccionado !== null ? _vm.pacienteSeleccionado.id : "")+" ")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{staticClass:"font-weight-black"},[_vm._v("Nombre:")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('label',[_vm._v(" "+_vm._s(_vm.pacienteSeleccionado !== null && _vm.pacienteSeleccionado !== null ? _vm.pacienteSeleccionado.nombreCompleto : "")+" ")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{staticClass:"font-weight-black"},[_vm._v("Cedula:")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('label',[_vm._v(" "+_vm._s(_vm.pacienteSeleccionado !== null && _vm.pacienteSeleccionado !== null ? _vm.pacienteSeleccionado.cedula : "")+" ")])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }