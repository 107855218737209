<template>
  <div id="especialidades">
    <h1>Especialidades</h1>

    <registro-especialidades
      v-bind:dialog.sync="dialog"
      :espcialidadSeleccionada="especialidad"
      @cerrarModal="onCerrarModal"
    ></registro-especialidades>

    <listado-generico
      @crearItem="crear"
      @seleccionarItem="editar"
      :esRegistrar="true"
      :itemsPorPagina="itemsPorPagina"
      :encabezados="encabezados"
      :items="especialidades"
    ></listado-generico>
  </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import RegistroEspecialidades from "../components/medicos/RegistroEspecialidades.vue";
import ListadoGenerico from "../components/indicaciones/ListadoGenerico.vue";
import jwtAxios from "../shared/jwtAxios.js";

export default {
  components: {
    RegistroEspecialidades,
    ListadoGenerico
  },
  name: "especialidades",
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  created() {
    this.get();
  },
  data() {
    return {
      encabezados: [
        { text: "Descripcion", value: "descripcion" },
        { text: "", value: "actions", sortable: false }
      ],
      especialidad: { id: 0, descripcion: "", activo: true },
      especialidades: [],
      itemsPorPagina: 5,
      dialog: false
    };
  },
  methods: {
    get() {
      jwtAxios
        .get(this.$apiConfig.especialidadesUrl)
        .then(response => {
          this.especialidades = response.data;
        })
        .catch(error => {
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
    editar(especialidadParam) {
      this.especialidad = especialidadParam;
      this.mostrarModal();
    },
    crear() {
      this.mostrarModal();
    },
    onCerrarModal() {
      this.dialog = false;
      this.limpiar();
      this.get();
    },
    mostrarModal() {
      this.dialog = true;
    },
    limpiar() {
      this.especialidad = { id: 0, descripcion: "", activo: true };
      this.especialidadSeleccionada = null;
    }
  }
};
</script>
