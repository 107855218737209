<template>
  <v-row justify="center">
    <!--MODAL REGISTRO DE PACIENTES-->
    <v-dialog
      v-model="dialogPaciente"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-form @submit.prevent="guardar" ref="form" lazy-validation>
        <v-card>
          <v-system-bar height="85" color="primary" dark>
            <v-btn icon @click="cerrarModal()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title color="primary">
              <span color="primary" style="color: white"
                >Registro de Pacientes</span
              >
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items></v-toolbar-items>
          </v-system-bar>

          <v-card>
            <v-card-title>
              <v-icon>mdi-account</v-icon>Datos Generales
            </v-card-title>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    v-model="paciente.cedula"
                    :rules="[rules.required, rules.cedulaMinLength]"
                    label="Cédula"
                    v-mask="mascaraCedula"
                  ></v-text-field>
                </v-flex>

                <v-flex sm12 md6>
                  <v-radio-group dense v-model="paciente.sexo" mandatory row>
                    <template v-slot:label>
                      <div>Sexo</div>
                    </template>
                    <v-radio label="Masculino" value="MASCULINO"></v-radio>
                    <v-radio label="Femenino" value="FEMENINO"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    class="upperCase"
                    :rules="[rules.required, rules.onlyLetters]"
                    label="Nombre"
                    v-model="paciente.nombre"
                  ></v-text-field>
                </v-flex>

                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.required, rules.onlyLetters]"
                    class="upperCase"
                    label="Apellidos"
                    v-model="paciente.primerApellido"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :rules="[rules.required]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="paciente.fechaNacimiento"
                        :rules="[rules.required]"
                        label="Fecha Nacimiento"
                        prepend-inner-icon="mdi-calendar"
                        persistent-hint
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="paciente.fechaNacimiento"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1900-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    disabled
                    v-model="age"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-combobox
                    outlined
                    dense
                    :rules="[rules.comboboxRequired]"
                    v-model="paciente.estadoCivil"
                    :items="estadosCiviles"
                    item-text="descripcion"
                    label="Estado Civil"
                    return-object
                  ></v-combobox>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    label="Ocupación"
                    v-model="paciente.ocupacion"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
          <v-card>
            <v-card-title> <v-icon> mdi-phone</v-icon>Contacto </v-card-title>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.required, rules.telefonoLength]"
                    v-model="paciente.celular"
                    label="Celular"
                    v-mask="mascaraTelefono"
                  ></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    v-model="paciente.telefono"
                    :rules="[rules.telefonoLength]"
                    label="Teléfono"
                    v-mask="mascaraTelefono"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    label="Dirección"
                    v-model="paciente.direccion"
                  ></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.email]"
                    label="Correo Electrónico"
                    v-model="paciente.correoElectronico"
                  ></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    label="Pariente"
                    v-model="paciente.nombrePariente"
                  ></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    label="Parentesco"
                    v-model="paciente.parentesco"
                  ></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    v-model="paciente.celularPariente"
                    label="Celular del Pariente"
                    v-mask="mascaraTelefono"
                  ></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    v-model="paciente.telefonoPariente"
                    label="Teléfono del Pariente"
                    v-mask="mascaraTelefono"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
          <v-card>
            <v-card-title>
              <v-icon> mdi-hospital</v-icon>Seguro Médico
            </v-card-title>
            <v-container grid-list-md class="mb-6">
              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-combobox
                    outlined
                    dense
                    v-model="paciente.seguroMedico"
                    :rules="[rules.comboboxRequired]"
                    :items="segurosMedicos"
                    item-text="descripcion"
                    label="Seguro Médico"
                    return-object
                  ></v-combobox>
                </v-flex>

                <v-flex sm12 md6>
                  <v-text-field
                    outlined
                    dense
                    label="Número Afiliado"
                    v-model="paciente.numeroAfiliado"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-card>
        <v-footer fixed>
          <v-spacer></v-spacer>
          <v-btn right outlined rounded type="submit" color="primary">
            <v-icon left>mdi-check</v-icon>Guardar
          </v-btn>
          <v-btn
            class="ml-2"
            right
            outlined
            rounded
            color="error"
            @click="cerrarModal()"
          >
            <v-icon left>mdi-close</v-icon>Cerrar
          </v-btn>
        </v-footer>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<style>
@import url("../../../src/css/myCss.css");
</style>
<script>
import axios from "../../shared/jwtAxios.js";
import {
  emailRegex,
  soloLetrasRegex,
  soloNumerosRegex,
  telefonoMask,
  cedulaMask,
  soloLetrasyÑRegex,
} from "../../utility/validaciones.js";

export default {
  name: "registro-pacientes",
  mounted() {
    this.getSegurosMedicos();
  },
  data() {
    return {
      tabPaciente: null, //VALIDACIONES
      url: this.$apiConfig.pacientesUrl,
      segurosMedicosUrl: this.$apiConfig.segurosMedicosUrl,
      mascaraTelefono: telefonoMask,
      mascaraCedula: cedulaMask,
      menu: false,
      date: null,
      estadosCiviles: [
        { id: 1, descripcion: "Soltero (a)" },
        { id: 2, descripcion: "Casado (a)" },
        { id: 3, descripcion: "Unión Libre" },
        { id: 4, descripcion: "Divorciado (a)" },
        { id: 5, descripcion: "Viudo (a)" },
      ],
      segurosMedicos: [],
      rules: {
        required: (valido) => !!valido || this.$camposRequeridosMessage,
        comboboxRequired: (v) =>
          (v && v.id > 0) || this.$camposRequeridosMessage,
        email: (v) => !v || emailRegex.test(v) || this.$camposRequeridosMessage,
        cedulaMinLength: (v) =>
          (v && v.length > 12) || this.$cedulaDebeEstarCompletaMessage,
        onlyNumbers: (v) =>
          soloNumerosRegex.test(v) || this.$soloValoresNumericosMessage,
        onlyLetters: (v) =>
          soloLetrasyÑRegex.test(v) || this.$soloLetrasMessage,
        telefonoLength: (v) => v.length > 0 && v.length === 14,
      },
    };
  },
  props: {
    dialogPaciente: Boolean,
    esRegistrar: Boolean,
    paciente: Object,
  },
  computed: {
    age: function () {
      return this.getAge(this.paciente.fechaNacimiento);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    cerrarModal() {
      this.tabPaciente = null;
      this.$emit("cerrarModalPaciente");
      this.$refs.form.resetValidation();
    },
    getSegurosMedicos() {
      axios
        .get(`${this.segurosMedicosUrl}/${this.$store.state.cuentaId}`)
        .then(({ data }) => {
          this.segurosMedicos = data;
        })
        .catch((error) => {
          window.console.log(error);
          this.$errorCargarDatos();
        });
    },
    getAge(birthDateParam) {
      let ageTemp = 0;
      let currentDate = new Date();
      let birthDate = new Date(birthDateParam);

      let dayOfYearBirthDate = this.getDayOfYear(birthDate);
      let dayOfYearCurrentDate = this.getDayOfYear(currentDate);

      ageTemp =
        dayOfYearBirthDate <= dayOfYearCurrentDate
          ? currentDate.getFullYear() - birthDate.getFullYear()
          : currentDate.getFullYear() - birthDate.getFullYear() - 1;

      return Number.isNaN(ageTemp) ? 0 + " años" : ageTemp + " años";
    },
    getDayOfYear(date) {
      var now = new Date(date);
      var start = new Date(now.getFullYear(), 0, 0);
      var diff =
        now -
        start +
        (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
      var oneDay = 1000 * 60 * 60 * 24;

      return Math.floor(diff / oneDay) + 1;
    },
    guardar() {
      if (this.$refs.form.validate()) {
        this.paciente.nombre = this.paciente.nombre.toUpperCase();
        this.paciente.primerApellido =
          this.paciente.primerApellido.toUpperCase();
        this.paciente.cuentaId = Number.parseInt(this.$store.state.cuentaId);
        axios
          .post(this.url, this.paciente)
          .then(() => {
            this.$emit("onPacienteGuardado");
            this.cerrarModal();
            this.$datosGuardados();
          })
          .catch((error) => {
            this.$errorGuardarDatos();
            window.console.log(error);
          });
      } else {
        this.$completarCamposRequeridosAlert();
        return;
      }
    },
    limpiarPaciente() {
      this.paciente = {
        id: "",
        nombre: "",
        primerApellido: "",
        cedula: "",
        sexo: "",
        fechaNacimiento: "",
        ocupacion: "",
        celular: "",
        telefono: "",
        direccion: "",
        correoElectronico: "",
        numeroAfiliado: "",
        estadoCivil: {
          id: 0,
          descripcion: "",
        },
        seguroMedico: {
          id: 0,
          descripcion: "",
        },
      };
    },
    save(date) {
      this.$refs.menu.save(date);
      this.paciente.fechaNacimiento = date;
      this.getAge(date);
    },
  },
};
</script>
