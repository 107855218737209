<template>
  <div id="seguros-medicos">
    <h1>Seguros médicos</h1>

    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-form @submit.prevent="guardar" ref="form" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="headline">Registro de seguros médicos</span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                outlined
                dense
                label="Código prestador"
                v-model="seguroMedico.codigoPrestador"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                :rules="descripcionRules"
                label="Descripción"
                v-model="seguroMedico.descripcion"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="Dirección"
                v-model="seguroMedico.direccion"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="RNC"
                v-model="seguroMedico.rnc"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                v-model="seguroMedico.telefono"
                label="Teléfono"
                v-mask="mascaraTelefono"
              ></v-text-field>

              <v-checkbox
                label="Activo"
                v-model="seguroMedico.activo"
              ></v-checkbox>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded type="submit" color="primary">
                <v-icon left>mdi-check</v-icon>Guardar
              </v-btn>
              <v-btn outlined rounded color="error" @click="cerrarModal()">
                <v-icon left>mdi-close</v-icon>Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <div>
      <v-data-table
        :headers="encabezados"
        :items="segurosMedicosFiltrados"
        :items-per-page="itemsPorPagina"
        :loading="isLoading"
        loading-text="Cargando datos... Por favor espere"
        class="elevation-1"
      >
        <template v-slot:top>
          <div class="mx-4">
            <v-row>
              <v-text-field
                class="noShadow"
                v-model="busqueda"
                label="Buscar"
                ref="txtBuscar"
                :append-icon="iconoCrear"
                @click:append="crear()"
              ></v-text-field>
            </v-row>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" @click="editar(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import jwtAxios from "../shared/jwtAxios.js";
import MainLayout from "../layouts/MainLayout.vue";
import { telefonoMask } from "../utility/validaciones";

export default {
  name: "seguros-medicos",
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  created() {
    this.get();
  },
  data() {
    return {
      seguroMedico: {
        id: 0,
        cuentaId: 0,
        codigoPrestador: "",
        descripcion: "",
        direccion: "",
        rnc: "",
        telefono: "",
        activo: true,
      },
      iconoCrear: "mdi-plus",
      encabezados: [
        { text: "Código prestador", value: "codigoPrestador" },
        { text: "Descripción", value: "descripcion" },
        { text: "Dirección", value: "direccion" },
        { text: "RNC", value: "rnc" },
        { text: "Teléfono", value: "telefono" },
        { text: "", value: "actions", sortable: false },
      ],
      segurosMedicos: [],
      busqueda: "",
      itemsPorPagina: 5,
      dialog: false,
      mascaraTelefono: telefonoMask,
      telefonoInvalido: false,
      isLoading: false,

      //VALIDACIONES
      descripcionRules: [
        (valido) => !!valido || "La descripción es requerida.",
      ],
    };
  },
  computed: {
    rows() {
      return this.segurosMedicos.length;
    },
    segurosMedicosFiltrados: function () {
      return this.segurosMedicos.filter((seguroMedico) => {
        return (
          seguroMedico.descripcion
            .toUpperCase()
            .includes(this.busqueda.toUpperCase()) ||
          seguroMedico.rnc.includes(this.busqueda.toUpperCase())
        );
      });
    },
  },

  methods: {
    get() {
      jwtAxios
        .get(
          `${this.$apiConfig.segurosMedicosUrl}/${this.$store.state.cuentaId}`
        )
        .then(({ data }) => {
          this.segurosMedicos = data;
        })
        .catch((error) => {
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
    actualizar() {
      jwtAxios
        .put(this.$apiConfig.segurosMedicosUrl, this.seguroMedico)
        .then(() => {
          this.cerrarModal();
          this.$datosGuardados();
          this.get();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.form.validate()) {
        if (this.seguroMedico.id > 0) {
          this.actualizar();
        } else {
          this.seguroMedico.cuentaId = Number.parseInt(
            this.$store.state.cuentaId
          );
          jwtAxios
            .post(this.$apiConfig.segurosMedicosUrl, this.seguroMedico)
            .then(() => {
              this.cerrarModal();
              this.$datosGuardados();
              this.get();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
      }
    },
    crear() {
      this.limpiar();
      this.mostrarModal();
    },
    mostrarModal() {
      this.dialog = true;
    },
    cerrarModal() {
      this.limpiar();
      this.dialog = false;
      this.$refs.form.resetValidation();
    },
    editar(seguroMedicoParam) {
      this.seguroMedico = seguroMedicoParam;
      this.mostrarModal();
    },
    limpiar() {
      this.seguroMedico = {
        id: 0,
        codigoPrestador: "",
        descripcion: "",
        direccion: "",
        rnc: "",
        telefono: "",
        activo: true,
      };
    },
  },
  watch: {
    "seguroMedico.telefono": function (telefono) {
      this.telefonoInvalido = !telefono.match(this.$telefonoRegex);
    },
    segurosMedicos: function (val) {
      this.isLoading = val.length > 0 ? false : true;
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
    },
  },
};
</script>
