<template>
  <v-tooltip bottom v-if="mensaje">
    <template v-slot:activator="{ on, attrs }">
      <p @dblclick="cerrar"  v-bind="attrs" v-on="on" v-if="mensaje">
        <span :class="cssClass">
          <v-icon class="secondary--text">{{ icon }}</v-icon>
          {{ mensaje }}
        </span>
      </p>
    </template>
    <span>Haga doble click para desaparecer la alerta.</span>
  </v-tooltip>
</template>

<script>
import { tipoMensajeEnum } from '@/utility/enumerations'

export default {
  name: "mensaje",
  props: {
    mensaje: "",
    tipoMensaje: ""
  },
  methods: {
    cerrar() {
      this.mensaje = "";
      this.tipoMensaje = ""
    }
  },
  computed:{
    cssClass(){
      if (this.tipoMensaje === tipoMensajeEnum.ERROR){
        return "error--text";
      }
      if (this.tipoMensaje === tipoMensajeEnum.SUCCESS){
        return "success--text"
      }
    },
    icon(){
      if (this.tipoMensaje === tipoMensajeEnum.ERROR){
        return "mdi-alert-circle";
      }
      if (this.tipoMensaje === tipoMensajeEnum.SUCCESS){
        return "mdi-check"
      }
    }
  }
}
</script>

<style scoped>
  .error {
    color: #c52121;
  }

  .success {
    color: #00C851;
  }
</style>
