<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" @click:outside="cancelar()">
      <v-form @submit.prevent="guardar" ref="formUsuario" lazy-validation>
        <v-card>
          <v-card-text>
            <v-select
              outlined
              :items="rolesUsuariosFiltrados"
              item-text="descripcion"
              item-value="id"
              v-model="rolUsuarioSeleccionadoId"
              label="Rol de usuario"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="nuevoRolUsuario"
            ></v-select>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  :rules="nombreRules"
                  prepend-icon="person"
                  v-model="usuario.nombre"
                  label="Nombre"
                  id="txtNombre"
                  ref="txtNombre"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  :rules="apellidoRules"
                  v-model="usuario.apellido"
                  prepend-icon="person"
                  label="Apellido"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              outlined
              :rules="emailRules"
              v-model="usuario.email"
              prepend-icon="email"
              type="email"
              label="E-mail"
            ></v-text-field>

            <mensaje v-bind:mensaje="mensajeError" v-bind:tipo-mensaje="tipoMensaje"></mensaje>

            <v-row v-if="!esEditar">
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  prepend-icon="lock"
                  :type="inputType"
                  :rules="contrasenaRules"
                  v-model="usuario.contrasena"
                  label="Contraseña"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  prepend-icon="lock"
                  :type="inputType"
                  :rules="confirmarContrasenaRules"
                  label="Confirmar contraseña"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-checkbox label="Activo" v-model="usuario.activo"></v-checkbox>
            <v-checkbox v-if="!esEditar" v-model="mostrarContrasena" label="Mostrar contraseña"></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined rounded type="submit" color="primary">
              <v-icon left>mdi-check</v-icon>Guardar
            </v-btn>
            <v-btn outlined rounded color="error" @click="cancelar()">
              <v-icon left>mdi-close</v-icon>Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <registro-rol-usuario
      v-bind:dialog.sync="rolUsuarioDialog"
      @cerrarModal="onCerrarRolUsuarioDialog"
    ></registro-rol-usuario>
  </v-row>
</template>

<script>
import jwtAxios from "../../shared/jwtAxios.js";
import Mensaje from "../helpers/Mensaje.vue";
import { emailRegex } from "@/utility/validaciones.js";
import RegistroRolUsuario from "../cuentas/RegistroRolUsuario.vue";
import { Rol } from "@/utility/roles.js";
import {tipoMensajeEnum} from "@/utility/enumerations";

export default {
  name: "registro-especialidades",
  components: {
    Mensaje,
    RegistroRolUsuario
  },
  mounted() {
    this.getRoles();
  },
  props: {
    dialog: { type: Boolean, default: false },
    usuarioSeleccionado: {},
    esEditar: { type: Boolean, default: false }
  },
  data() {
    return {
      usuario: {
        id: 0,
        cuentaId: 0,
        usuarioPadre: 0,
        rolUsuarioId: 0,
        nombre: "",
        apellido: "",
        email: "",
        contrasena: "",
        activo: true
      },
      confirmarContrasena: "",
      usuarios: [],
      rolesUsuarios: [],
      rolUsuarioSeleccionadoId: {},
      inputType: "password",
      mensajeError: "",
      tipoMensaje: "",
      mostrarContrasena: false,
      rolUsuarioDialog: false,

      //VALIDACIONES
      nombreRules: [v => !!v || "El nombre es requerido."],
      apellidoRules: [v => !!v || "El apellido es requerido."],
      emailRules: [
        v => !!v || "El E-mail es requerido.",
        v => emailRegex.test(v) || "Digite un E-mail válido."
      ],
      contrasenaRules: [v => !!v || "La contraseña es requerida."],
      confirmarContrasenaRules: [
        v => !!v || "Digite una contraseña de verificación.",
        v => v === this.usuario.contrasena || "Las contraseñas no coinciden."
      ]
    };
  },
  methods: {
    mostrarMensaje(mensaje, tipo){
      this.tipoMensaje = tipo;
      this.rolesUsuarios = mensaje;
    },
    getRoles() {
      jwtAxios
        .get(this.$apiConfig.rolesUsuariosUrl)
        .then(response => {
          this.mostrarMensaje(response.data, tipoMensajeEnum.SUCCESS)
        })
        .catch(error => {
          this.mostrarMensaje(error.response.data, tipoMensajeEnum.ERROR)
          window.console.log(error.response);
        });
    },
    actualizar() {
      jwtAxios
        .put(`${this.$apiConfig.usuariosUrl}/Actualizar`, this.usuario)
        .then(() => {
          this.onGuardar();
          this.$datosGuardados();

          if (this.usuarioSeleccionado.id === this.$store.state.usuarioId) {
            let rol = this.rolesUsuarios.find(
              r => r.id === this.rolUsuarioSeleccionadoId
            );
            this.$store.commit("setRolUsuario", rol.descripcion);
          }
        })
        .catch(error => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.formUsuario.validate()) {
        this.usuario.cuentaId = Number.parseInt(
          localStorage.getItem("@cuentaId")
        );
        this.usuario.usuarioPadre = Number.parseInt(
          this.$store.getters.getMedicoId
        );
        this.usuario.rolUsuarioId = this.rolUsuarioSeleccionadoId;
        if (this.usuario.id > 0) {
          this.actualizar();
        } else {
          jwtAxios
            .post(
              `${this.$apiConfig.usuariosUrl}/RegistroSecundario`,
              this.usuario
            )
            .then(() => {
              this.onGuardar();
              this.$datosGuardados();
            })
            .catch(error => {
              this.$errorGuardarDatos();
              window.console.log(error.response);
            });
        }
      }
    },
    onGuardar() {
      this.$emit("onGuardar");
    },
    limpiar() {
      this.usuario = {
        id: 0,
        rolUsuarioId: 0,
        nombre: "",
        apellido: "",
        email: "",
        contrasena: "",
        activo: true
      };
      this.rolUsuarioSeleccionadoId = 1;
    },
    cancelar() {
      this.$emit("cancelar");
      this.$refs.formUsuario.resetValidation();
      this.limpiar();
    },
    nuevoRolUsuario() {
      this.rolUsuarioDialog = true;
    },
    onCerrarRolUsuarioDialog() {
      this.rolUsuarioDialog = false;
    }
  },
  computed: {
    rolesUsuariosFiltrados() {
      return this.$store.getters.rolUsuario !== Rol.Admin
        ? this.rolesUsuarios.filter(r => r.descripcion !== Rol.Admin)
        : this.rolesUsuarios;
    }
  },
  watch: {
    usuarioSeleccionado: function(value) {
      this.usuario = value;
      this.rolUsuarioSeleccionadoId = value.rolUsuarioId;
    },
    mostrarContrasena: function(value) {
      if (value) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    }
  }
};
</script>
