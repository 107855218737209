import swal from "sweetalert";

function eliminarCitaQuestionAlert2() {
  return swal({
    text: "¿Desea eliminar esta cita?",
    icon: "warning",
    buttons: {
      confirm: {
        text: "Sí",
        value: true,
        visible: true,
        className: "sweet-info",
      },
      cancel: {
        text: "No",
        value: null,
        visible: true,
        className: "sweet-cancel",
        closeModal: true,
      },
    },
  });
}


export { 
  eliminarCitaQuestionAlert2
};