import moment from "moment";

let getCurrentDate = () => new Date();

function dateNow() {
  let today = getCurrentDate();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

function dateNowDDMMYY() {
  let today = getCurrentDate();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
}

function toNormalDate(date) {
  let myDate = new Date(date);
  let newDate = null;
  try {
    newDate = moment(myDate).format("DD/MM/YYYY hh:mm a");
  } catch (error) {
    window.console.error(error);
  }
  return newDate;
}

function momentDate(date, format){
  let myDate = new Date(date);
  let newDate = null;
  try {
    newDate = moment(myDate).format(format);
  } catch (error) {
    window.console.error(error);
  }
  return newDate;
}

function toDateFormatDDMMYY(date) {
  return date.replaceAll("-", "/");
}

function toDDMMYYDate(date){
  return momentDate(date, "DD/MM/YYYY");
}

function toNormalDateNoHour(date) {
  return momentDate(date, "YYYY/MM/DD");
}


function removeTimeFromDate(date) {
  return date.split("T")[0];
}

function getTimeFromDate(date) {
  return date.split("T")[1];
}

function toTwelveHourFormat(hour){
  let hourWithoutMinute = hour.substring(
    0,
    hour.indexOf(":")
  );

  let amORpm = "";

  switch(Number(hourWithoutMinute)){
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      amORpm = "AM";
      break;
    case 12:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      amORpm = "PM";
      break;
  }
  return `${hour} ${amORpm}`;
}

export {
  getCurrentDate,
  dateNow,
  toNormalDate,
  toNormalDateNoHour,
  removeTimeFromDate,
  getTimeFromDate,
  toDateFormatDDMMYY,
  dateNowDDMMYY,
  toDDMMYYDate,
  toTwelveHourFormat
};
