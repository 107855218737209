<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" @click:outside="cancelar()" width="600px">
      <v-form @submit.prevent="guardar" ref="formArticulo" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Registro de servicios</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-text-field
                  outlined
                  dense
                  label="Descripción"
                  :rules="descripcionRules"
                  v-model="articulo.descripcion"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  outlined
                  dense
                  placeholder="0.00"
                  prefix="$"
                  label="Precio"
                  :rules="precioRules"
                  type="number"
                  v-model="articulo.precio"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-checkbox
                  label="Activo"
                  v-model="articulo.activo"
                ></v-checkbox>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined rounded type="submit" color="primary">
              <v-icon left>mdi-check</v-icon>Guardar
            </v-btn>
            <v-btn outlined rounded color="error" @click="cancelar()">
              <v-icon left>mdi-close</v-icon>Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "../../shared/jwtAxios";

export default {
  name: "registro-articulo",
  data() {
    return {
      articulo: {
        id: 0,
        uniqueId: "",
        cuentaId: 0,
        descripcion: "",
        precio: 0,
        quienRegistrar: 0,
        activo: true,
      },
      //VALIDACIONES
      descripcionRules: [(v) => !!v || "La descripción es requerida."],
      precioRules: [
        (v) => v >= 0 || "El precio debe ser mayor o igual a cero.",
      ],
    };
  },
  props: {
    dialog: { type: Boolean, default: true },
    articuloSeleccionado: {},
  },
  methods: {
    actualizar() {
      axios
        .put(this.$apiConfig.articulosUrl, this.articulo)
        .then(() => {
          this.onGuardar();
          this.$datosGuardados();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.formArticulo.validate()) {
        this.articulo.precio = parseFloat(this.articulo.precio);
        this.articulo.cuentaId = parseInt(localStorage.getItem("@cuentaId"));
        this.articulo.quienRegistra = parseInt(localStorage.getItem("@usuarioId"));
        if (this.articulo.id > 0) {
          this.actualizar();
        } else {
          axios
            .post(this.$apiConfig.articulosUrl, this.articulo)
            .then(() => {
              this.onGuardar();
              this.$datosGuardados();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error.response);
            });
        }
      }
    },
    onGuardar() {
      this.$refs.formArticulo.resetValidation();
      this.$emit("onGuardar");
    },
    cancelar() {
      this.$emit("cancelar");
      this.$refs.formArticulo.resetValidation();
    },
  },
  watch: {
    articuloSeleccionado: function(value) {
      this.articulo = value;
    },
  },
};
</script>

<style></style>
