import { extensionesArchivos } from "../utility/enumerations";

function getFileExtension(fileName) {
  if (fileName === null) {
    return "";
  }
  let extension = fileName.substring(
    fileName.lastIndexOf("."),
    fileName.length
  );
  return extension;
}

function archivoEsDocumento(extension) {
  let esDocumento = false;
  switch (extension) {
    case extensionesArchivos.WORD:
    case extensionesArchivos.DOC:
    case extensionesArchivos.RTF:
    case extensionesArchivos.EXCEL:
    case extensionesArchivos.PPOINT:
    case extensionesArchivos.PDF:
    case extensionesArchivos.TXT:
      esDocumento = true;
      break;
    case extensionesArchivos.PNG:
    case extensionesArchivos.JPG:
    case extensionesArchivos.JPEG:
    case extensionesArchivos.GIF:
    case extensionesArchivos.RAW:
    case extensionesArchivos.TIFF:
    case extensionesArchivos.JFIF:
      esDocumento = false;
      break;
  }
  return esDocumento;
}

export { getFileExtension, archivoEsDocumento };
