<template>
  <div id="planes">
    <h1>Planes</h1>

    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-form @submit.prevent="guardar" ref="formplan" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="headline">Registro de planes</span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                :rules="descripcionRules"
                label="Descripción"
                v-model="plan.descripcion"
              ></v-text-field>

              <v-text-field
                id="txtMonto"
                label="Monto"
                type="number"
                @blur="onMontoBlur"
                v-model="plan.monto"
              ></v-text-field>

              <v-text-field
                label="Vigencia"
                :rules="vigenciaRules"
                @blur="onVigenciaBlur"
                type="number"
                v-model="plan.vigencia"
                suffix="Días"
              ></v-text-field>

              <v-textarea v-model="plan.caracteristicas" color="primary">
                <template v-slot:label>
                  <div>Caracteristicas</div>
                </template>
              </v-textarea>

              <v-checkbox label="Activo" v-model="plan.activo"></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text type="submit" color="primary">
                <v-icon left>mdi-check</v-icon>Guardar
              </v-btn>
              <v-btn text color="error" @click="cerrarModal()">
                <v-icon left>mdi-close</v-icon>Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <listado-generico
      @crearItem="crear"
      @seleccionarItem="editar"
      :esRegistrar="true"
      :itemsPorPagina="itemsPorPagina"
      :encabezados="encabezados"
      :items="planes"
    ></listado-generico>
  </div>
</template>

<script>
import ListadoGenerico from "@/components/indicaciones/ListadoGenerico.vue";

import MainLayout from "../../../layouts/MainLayout";
import axios from "axios";

export default {
  name: "planes",
  components: {
    ListadoGenerico,
  },
  created() {
    this.$emit("update:layout", MainLayout);
  },
  mounted() {
    this.get();
  },
  data() {
    return {
      url: this.$apiConfig.planesUrl,
      plan: {
        id: 0,
        descripcion: "",
        monto: 0,
        vigencia: 0,
        caracteristicas: "",
        activo: true,
      },
      encabezados: [
        { text: "Id", value: "id" },
        { text: "Descripcion", value: "descripcion" },
        { text: "Monto", value: "monto" },
        { text: "Vigencia", value: "vigencia" },
        { text: "Activo", value: "activo" },
        { text: "", value: "actions", sortable: false },
      ],
      planes: [],

      itemsPorPagina: 5,
      dialog: false,

      //VALIDACIONES
      descripcionRules: [
        (valido) => !!valido || "La descripción es requerida.",
      ],
      vigenciaRules: [
        () => this.plan.vigencia > 0 || "El plan debe durar al menos un día.",
      ],
    };
  },
  methods: {
    get() {
      axios
        .get(this.url)
        .then(({ data }) => {
          this.planes = data;
        })
        .catch((error) => {
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
    actualizar() {
      axios
        .put(this.url, this.plan)
        .then(() => {
          this.cerrarModal();
          this.$datosGuardados();
          this.get();
        })
        .catch((error) => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.formplan.validate()) {
        if (this.plan.id > 0) {
          this.actualizar();
        } else {
          axios
            .post(this.url, this.plan)
            .then(() => {
              this.cerrarModal();
              this.$datosGuardados();
              this.get();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
      }
    },
    limpiar() {
      this.plan = {
        id: 0,
        descripcion: "",
        monto: 0,
        vigencia: 0,
        caracteristicas: "",
        activo: true,
      };
    },
    editar(planParam) {
      this.plan = planParam;
      this.mostrarModal();
    },
    crear() {
      this.limpiar();
      this.mostrarModal();
    },
    mostrarModal() {
      this.dialog = true;
    },
    cerrarModal() {
      this.dialog = false;
      this.limpiar();
      this.$refs.formplan.resetValidation();
    },

    //EVENTOS
    onMontoBlur(event) {
      let valor = Number.parseFloat(event.target.value);
      if (Number.isNaN(valor)) {
        this.plan.monto = 0;
      } else {
        this.plan.monto = Number.parseFloat(event.target.value).toFixed(2);
      }
    },
    onVigenciaBlur(event) {
      let valor = Number.parseFloat(event.target.value);
      if (Number.isNaN(valor)) {
        this.plan.vigencia = 0;
      }
    },
  },
};
</script>
