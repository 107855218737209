<template>
  <div class="NotFound">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat>
            <div class="display-3 mt-5">Página no encontrada.</div>
            <br />
            <div>La página a la que está tratando de acceder no existe.</div>
            <br />
            <v-btn outline rounded color="primary" @click="backHome()"
              >Ir a la página principal</v-btn
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "not-found",
  methods: {
    backHome() {
      this.$router.push("/home");
    },
  },
};
</script>

<style>
.paragraph-text {
  font-size: 18px;
}
</style>
