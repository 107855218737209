<template>
  <v-btn
    icon
    @click="cambiarTema"
    role="presentation"
    title="theme"
    aria-label="theme"
  >
    <v-icon class="white--text">{{ icono }}</v-icon>
  </v-btn>
</template>

<script>
import jwtAxios from "../../shared/jwtAxios.js";

export default {
  created() {
    this.getConfiguracion();
  },
  data() {
    return {
      icono: null,
    };
  },
  methods: {
    getConfiguracion() {
      jwtAxios
        .get(
          `${this.$apiConfig.configuracionUrl}/getTema/${this.$store.getters.getCuentaId}`
        )
        .then(({ data }) => {
          this.$store.commit("setTema", data);
          this.$vuetify.theme.dark = data.temaOscuro;
          this.icono =
            this.$vuetify.theme.dark == true
              ? "mdi-white-balance-sunny"
              : "mdi-moon-waxing-crescent";
        })
        .catch((error) => window.console.log(error));
    },
    cambiarTema() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      let configuracion = {
        id: this.$store.state.configuracion.id,
        cuentaId: this.$store.getters.getCuentaId,
        temaOscuro: this.$vuetify.theme.dark,
      };
      jwtAxios
        .post(this.$apiConfig.configuracionUrl, configuracion)
        .then(() => {
          this.getConfiguracion();
        })
        .catch((error) =>
          window.console.log("Error al intentar aplicar el tema" + error)
        );
    },
  },
};
</script>
