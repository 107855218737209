<template>
  <v-container id="Navbar">
    <!--BARRA SUPERIOR-->
    <v-app-bar color="primary" class="elevation-1" app>
      <v-app-bar-nav-icon
        role="presentation"
        title="avatar"
        aria-label="avatar"
        color="white"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="white--text">SIGEMED</v-toolbar-title>

      <v-spacer></v-spacer>

      <configuracion-tema></configuracion-tema>

      <v-menu left bottom v-if="this.$store.getters.isTokenAlive">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            role="presentation"
            title="options"
            aria-label="options"
          >
            <v-icon class="white--text">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="navegar('configuracion')">
            <v-list-item-title>
              <v-icon>settings</v-icon>Configuración
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logOut()">
            <v-list-item-title>
              <v-icon>mdi-logout</v-icon>Salir
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!--MENU LATERAL-->
    <v-navigation-drawer v-model="drawer" class="pb-0" overflow app>
      <v-list-item id="header-listitem" class="primary elevation-1">
        <v-list-item-avatar>
          <v-avatar color="primary" class="mt-3 mr-3">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="white--text h1 mt-3">{{
            nombreAbreviado
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.titulo"
          link
          no-action
          @click="navegar(item.url)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icono }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.titulo }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import ConfiguracionTema from "./configuraciones/ConfiguracionTema.vue";
import { Rol } from "@/utility/roles.js";
import { parseJwt } from "@/utility/jwtHelper.js";
import swal from "sweetalert";
import store from "@/state/index.js";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";

export default defineComponent({
  components: { ConfiguracionTema },
  name: "navbar",

  setup() {
    onMounted(() => {
      getPerfilMedico();
      getNombreAbreviado();
      setPerfilMedico();
    });

    let tienePerfilMedico = computed(() => setPerfilMedico());
    let nombreAbreviado = computed(() => getNombreAbreviado());
    const esAdministrador = computed(
      () => store.state.rolUsuario === Rol.Admin
    );
    let drawer = true;
    let menuItems = [
      { index: 1, titulo: "Inicio", icono: "home", url: "home" },
      {
        index: 2,
        titulo: "Pacientes",
        icono: "mdi-account-supervisor-circle",
        url: "pacientes",
      },
      {
        index: 3,
        titulo: "Pacientes en Turno",
        icono: "mdi-ticket",
        url: "pacientes-en-turno",
      },
      {
        index: 4,
        titulo: "Pacientes Citados",
        icono: "mdi-calendar",
        url: "pacientes-citados",
      },
      {
        index: 5,
        titulo: "Artículos",
        icono: "mdi-cart",
        url: "articulos",
      },
      {
        index: 6,
        titulo: "Facturación",
        icono: "mdi-point-of-sale",
        url: "facturacion",
      },
      {
        index: 7,
        titulo: "Listado de facturas",
        icono: "mdi-receipt",
        url: "listado-facturas",
      },
      {
        index: 8,
        titulo: "Imagenes",
        icono: "mdi-radiology-box",
        url: "imagenes",
      },
      {
        index: 9,
        titulo: "Laboratorios",
        icono: "mdi-test-tube",
        url: "laboratorios",
      },
      {
        index: 10,
        titulo: "Medicamentos",
        icono: "mdi-pill",
        url: "medicamentos",
      },
      {
        index: 11,
        titulo: "Seguros médicos",
        icono: "mdi-security",
        url: "seguros-medicos",
      },
      // {
      //   index: 11,
      //   titulo: "Especialidades",
      //   icono: "mdi-doctor",
      //   url: "especialidades",
      // },
      // {
      //   index: 12,
      //   titulo: "Centros médicos",
      //   icono: "mdi-hospital-building",
      //   url: "centros-medicos"
      // },
      {
        index: 100,
        titulo: "Configuración",
        icono: "settings",
        url: "configuracion",
      },
    ];

    function getPerfilMedico() {
      store
        .dispatch("getPerfilMedico")
        .then(() => {
          store.commit("getPerfilMedico", true);
        })
        .catch((error) => {
          store.commit("getPerfilMedico", false);
          window.console.log(error);
        });
    }

    function navegar(url) {
      if (store.getters.rolUsuario === Rol.Secretaria) {
        if (url !== "configuracion") {
          this.$router.push(url).catch((error) => error);
        } else {
          this.$warningAlert(
            "Usted no tiene permisos para acceder a este recurso."
          );
          return;
        }
      }
      this.$router.push(url).catch((error) => error);
    }

    function logOut() {
      swal({
        text: "¿Desea salir del sistema?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((quiereSalir) => {
        if (quiereSalir) {
          localStorage.clear();
          this.$router.push("/login").catch((error) => error);
        }
      });
    }

    let getNombreAbreviado = () => store.getters.nombreAbreviado;
    let setPerfilMedico = () => parseJwt(store.state.jwtToken).firma !== null;

    return {
      //PROPERTIES
      nombreAbreviado,
      menuItems,
      drawer,
      tienePerfilMedico,
      esAdministrador,

      //METHODS
      navegar,
      logOut,
      getPerfilMedico,
      getNombreAbreviado,
      setPerfilMedico,
    };
  },
});
</script>

<style scoped>
#header-listitem {
  padding-bottom: 7.5px;
}

@media screen and (max-width: 768px) {
  #header-listitem {
    padding-bottom: 1px;
  }
}
</style>
