<template>
  <div id="laboratorios">
    <h1>Laboratorios</h1>

    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-form @submit.prevent="guardar" ref="form" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="headline">Registro de laboratorios</span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                dense
                outlined
                :rules="descripcionRules"
                label="Descripción"
                v-model="laboratorio.descripcion"
              ></v-text-field>
              <v-checkbox label="Activo" v-model="laboratorio.activo"></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded type="submit" color="primary">
                <v-icon left>mdi-check</v-icon>Guardar
              </v-btn>
              <v-btn outlined rounded color="error" @click="cerrarModal()">
                <v-icon left>mdi-close</v-icon>Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <listado-generico
      @crearItem="crear"
      @seleccionarItem="editar"
      :esRegistrar="true"
      :itemsPorPagina="itemsPorPagina"
      :encabezados="encabezados"
      :items="laboratorios"
    ></listado-generico>
  </div>
</template>

<script>
import MainLayout from "../../layouts/MainLayout";
import ListadoGenerico from "./ListadoGenerico.vue";
import jwtAxios from "../../shared/jwtAxios";

export default {
  components: {
    ListadoGenerico
  },
  name: "laboratorios",
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  created() {
    this.get();
  },
  data() {
    return {
      laboratorio: {
        id: 0,
        cuentaId: 0,
        descripcion: "",
        activo: true
      },
      encabezados: [
        { text: "Descripcion", value: "descripcion" },
        { text: "", value: "actions", sortable: false }
      ],
      laboratorios: [],
      itemsPorPagina: 5,
      dialog: false,

      //VALIDACIONES
      descripcionRules: [valido => !!valido || "La descripción es requerida."]
    };
  },
  methods: {
    get() {
      jwtAxios
        .get(`${this.$apiConfig.laboratoriosUrl}/${this.$store.state.cuentaId}`)
        .then(({ data }) => {
          this.laboratorios = data;
        })
        .catch(error => {
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
    actualizar() {
      jwtAxios
        .put(this.$apiConfig.laboratoriosUrl, this.laboratorio)
        .then(() => {
          this.cerrarModal();
          this.$datosGuardados();
          this.get();
        })
        .catch(error => {
          this.$errorGuardarDatos();
          window.console.log(error);
        });
    },
    guardar() {
      if (this.$refs.form.validate()) {
        if (this.laboratorio.id > 0) {
          this.actualizar();
        } else {
          this.laboratorio.cuentaId = Number.parseInt(
            this.$store.state.cuentaId
          );
          jwtAxios
            .post(this.$apiConfig.laboratoriosUrl, this.laboratorio)
            .then(() => {
              this.cerrarModal();
              this.$datosGuardados();
              this.get();
            })
            .catch(error => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
      }
    },
    limpiar() {
      this.laboratorio = {
        id: 0,
        descripcion: "",
        activo: true
      };
    },
    editar(laboratorioParam) {
      this.laboratorio = laboratorioParam;
      this.mostrarModal();
    },
    crear() {
      this.limpiar();
      this.mostrarModal();
    },
    mostrarModal() {
      this.dialog = true;
    },
    cerrarModal() {
      this.dialog = false;
      this.limpiar();
      this.$refs.form.resetValidation();
    }
  }
};
</script>
