<template>
  <v-container class="home">
    <v-row
      ><h1>{{ titulo }}</h1></v-row
    >
    <v-row>
      <v-col cols="12" md="6" sm="6">
        <v-card elevation="5">
          <div class="cinta-mamey">
            <v-icon color="white" class="icono-cinta">mdi-account</v-icon>
          </div>
          <v-card-title class="card-title">Pacientes atendidos</v-card-title>
          <v-card-text
            ><h3>{{ pacientesAtendidos }} paciente(s)</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-card elevation="5">
          <div class="cinta-verde">
            <v-icon color="white" class="icono-cinta">mdi-cash</v-icon>
          </div>
          <v-card-title class="card-title">Total facturado</v-card-title>
          <v-card-text
            ><h3>RD${{ totalFacturado }}</h3></v-card-text
          >
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="4" sm="6">
          <v-card elevation="5">
            <div class="cinta-roja">
              <v-icon color="white" class="icono-cinta"
                >mdi-calendar-range</v-icon
              >
            </div>
            <v-card-title class="card-title">Citas programadas</v-card-title>
            <v-card-text class="card-text">0</v-card-text>
          </v-card>
        </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./Navbar.vue";
import MainLayout from "../layouts/MainLayout.vue";
import { toMiles } from "../utility/numberHelper.js";
import axios from "../shared/jwtAxios.js";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import ApiConfig from "../utility/apiConfig";
import store from "../state/index.js";

export default defineComponent({
  components: {
    NavBar,
  },
  name: "Home",

  setup(props, { emit }) {
    onMounted(() => {
      emit("update:layout", MainLayout);

      getTotalFacturado();
      getPacientesAtendidos();
    });

    const apiConfig = new ApiConfig();
    let titulo = "Inicio";
    let totalFacturado = ref(0);
    let pacientesAtendidos = ref(0);

    function getTotalFacturado() {
      axios
        .get(
          `${apiConfig.facturasUrl}/GetTotalFacturado/${store.state.cuentaId}`
        )
        .then((response) => {
          if (response.data !== null) {
            totalFacturado.value = toMiles(response.data);
          }
        })
        .catch((error) => window.console.log(error));
    }

    function getPacientesAtendidos() {
      axios
        .get(
          `${apiConfig.historiasClinicasUrl}/getPacientesAtentidos/${store.state.cuentaId}`
        )
        .then((response) => {
          if (response.data !== null) {
            pacientesAtendidos.value = response.data;
          }
        })
        .catch((error) => window.console.log(error));
    }

    return {
      titulo,
      totalFacturado,
      pacientesAtendidos,

      getTotalFacturado,
      getPacientesAtendidos,
    };
  },
});
</script>

<style scoped>
.card-title {
  font-size: 1.4em;
}

.card-text {
  font-size: 0.9em;
}

.cinta-mamey {
  background-color: #ffc107;
  color: white;
  height: 40px;
  width: 40px;
  position: relative;
  outline-style: none;
  left: 15px;
  top: 0;
  border-radius: 0px 0px 2px 2px;
  text-align: center;
}

.cinta-verde {
  background-color: #016b6d;
  color: white;
  height: 40px;
  width: 40px;
  position: relative;
  left: 15px;
  top: 0;
  border-radius: 0px 0px 2px 2px;
  outline-style: none;
  text-align: center;
}

.cinta-roja {
  background-color: #c52121;
  color: white;
  height: 40px;
  width: 40px;
  position: relative;
  left: 15px;
  top: 0;
  border-radius: 0px 0px 2px 2px;
  outline-style: none;
  text-align: center;
}

.icono-cinta {
  margin-top: 5px;
}

@media screen and (max-width: 900px) {
  .card-title {
    font-size: 1em;
  }

  .card-text {
    font-size: 0.8em;
  }
}
</style>
