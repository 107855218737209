<template>
  <div>
    <h1>Facturación</h1>

    <v-form
      ref="formFactura"
      @submit.prevent="guardarFactura()"
      lazy-validation
    >
      <div class="container">
        <v-row>
          <v-col cols="12" md="8" sm="12" xs="12">
            <v-card>
              <v-card-title
                class="headline font-weight-regular primary white--text"
                >Articulos</v-card-title
              >
              <v-card-text>
                <v-autocomplete
                  id="cmbArticulos"
                  class="ma-4"
                  :loading="loading"
                  hide-no-data
                  label="Buscar artículos..."
                  hide-details
                  item-text="descripcion"
                  item-value="id"
                  :items="articulos"
                  dense
                  outlined
                  append-icon="null"
                  v-model="articuloSeleccionado"
                  :search-input.sync="searchArticulo"
                  return-object
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        >Busque los articulos que desee
                        facturar</v-list-item-title
                      >
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="blue-grey"
                      class="white--text"
                      v-on="on"
                    >
                      <span v-text="item.descripcion"></span>
                      <span v-text="'$' + item.precio" class="ml-4"></span>
                    </v-chip>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.descripcion"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="'$' + item.precio"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-card-text>
            </v-card>

            <v-simple-table
              height="300px"
              class="elevation-3 mt-5"
              style="overflow: auto;"
            >
              <template fixed-header v-slot:default>
                <thead>
                  <tr>
                    <th>Descripcion</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th>ITBIS</th>
                    <th>% Cobertura</th>
                    <th>Cobertura</th>
                    <th>Diferencia</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in articulosSeleccionados" :key="item.id">
                    <td>
                      <label>{{ item.descripcion }}</label>
                    </td>
                    <td>
                      <v-text-field
                        type="number"
                        min="1"
                        id="txtCantidad"
                        placeholder="0.00"
                        v-on:input="calcularTotales()"
                        v-model="item.cantidad"
                      ></v-text-field>
                    </td>
                    <td>
                      <label v-if="item.precio > 0">${{ item.precio }}</label>
                      <label v-else>$0.00</label>
                    </td>
                    <td>
                      <label v-if="item.itbis > 0">{{ item.itbis }}</label>
                      <label v-else>$0.00</label>
                    </td>
                    <td>
                      <v-text-field
                        id="txtPorcientoCobertura"
                        placeholder="0.00%"
                        type="number"
                        min="1"
                        suffix="%"
                        v-on:input="calcularTotales()"
                        v-model="item.porcientoCobertura"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        id="txtCobertura"
                        placeholder="0.00"
                        type="number"
                        min="1"
                        prefix="$"
                        v-on:input="calcularTotales()"
                        v-model="item.cobertura"
                      ></v-text-field>
                    </td>
                    <td>
                      <label v-if="item.diferencia > 0"
                        >${{ item.diferencia }}</label
                      >
                      <label v-else>$0.00</label>
                    </td>
                    <td>
                      <label v-if="item.total > 0">${{ item.total }}</label>
                      <label v-else>$0.00</label>
                    </td>
                    <td>
                      <v-icon color="secondary" @click="quitarArticulo(item)"
                        >mdi-minus</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-text-field
              id="txtObservacion"
              dense
              outlined
              v-model="factura.observacion"
              class="mt-5 mr-15"
              label="Observacion (opcional)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="12" xs="12">
            <!--DATOS PACIENTE-->
            <paciente-header
              @pacienteSeleccionado="onPacienteSelecionado"
              :limpiaPacienteSeleccionado="limpiarPacienteSeleccionado"
            ></paciente-header>

            <!--CONFIGURACION FACTURA-->
            <v-card class="mt-5">
              <v-card-title>Configuración factura</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="container">
                  <v-row dense>
                    <v-col cols="4">
                      <label class="font-weight-black">Factura No.:</label>
                    </v-col>
                    <v-col cols="8">
                      <h3>{{ numeroFactura }}</h3>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4">
                      <p class="mt-2 font-weight-black">Tipo de factura:</p>
                    </v-col>
                    <v-col cols="8">
                      <v-select
                        dense
                        outlined
                        :items="tiposFacturas"
                        v-model="factura.tipo"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4">
                      <label class="font-weight-black">Médico:</label>
                    </v-col>
                    <v-col cols="8">
                      <label>{{ firmaDoctor }}</label>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4">
                      <label class="font-weight-black">Fecha:</label>
                    </v-col>
                    <v-col cols="8">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="factura.fecha"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            v-model="factura.fecha"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="factura.fecha"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(factura.fecha)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4">
                      <label class="font-weight-black">No. autorización:</label>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        dense
                        outlined
                        v-model="factura.numeroAutorizacion"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>

            <!--CALCULOS FACTURACION-->
            <v-card class="mt-5">
              <v-card-title>Totales</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-col cols="12" md="12">
                  <v-row dense justify="space-between">
                    <v-col cols="6">
                      <label class="font-weight-black">Sub total:</label>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <label class="font-weight-black primary--text"
                        >${{ factura.subTotal.toFixed(2) }}</label
                      >
                    </v-col>
                  </v-row>
                  <v-row dense justify="space-between">
                    <v-col cols="6">
                      <label class="font-weight-black">ITBIS:</label>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <label class="font-weight-black primary--text"
                        >${{ factura.totalItbis.toFixed(2) }}</label
                      >
                    </v-col>
                  </v-row>

                  <v-row dense justify="space-between">
                    <v-col cols="6">
                      <label class="font-weight-black">Cobertura:</label>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <label class="font-weight-black primary--text"
                        >${{ factura.totalCobertura.toFixed(2) }}</label
                      >
                    </v-col>
                  </v-row>
                  <v-row dense justify="space-between">
                    <v-col cols="4">
                      <p class="mt-4 font-weight-black">Descuento:</p>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        dense
                        class="secondary--text mt-2"
                        outlined
                        min="0"
                        max="100"
                        type="number"
                        suffix="%"
                        id="txtPorcientoDescuento"
                        v-on:input="calcularTotales()"
                        v-model="factura.porcientoDescuento"
                        placeholder="0.00"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <p class="mt-4 font-weight-black secondary--text">
                        ${{ factura.totalDescuento.toFixed(2) }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row dense justify="space-between">
                    <v-col cols="6">
                      <label class="font-weight-black">Diferencia:</label>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <label
                        class="font-weight-black secondary--text text-right"
                        >${{ factura.totalDiferencia.toFixed(2) }}</label
                      >
                    </v-col>
                  </v-row>
                  <v-row dense justify="space-between" class="primary">
                    <v-col cols="6">
                      <label class="font-weight-black white--text"
                        >Total:</label
                      >
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <label class="font-weight-black white--text"
                        >${{ factura.total.toFixed(2) }}</label
                      >
                    </v-col>
                  </v-row>

                  <v-row dense class="justify-center mt-4">
                    <v-btn
                      outlined
                      rounded
                      :disabled="
                        articulosSeleccionados.length <= 0 ||
                          factura.total <= 0 ||
                          !hayPacienteSelecionado
                      "
                      color="success"
                      @click.prevent="abrirDialogCobro()"
                    >
                      <v-icon left>mdi-check</v-icon>Finalizar
                    </v-btn>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-dialog
        id="cobroDialog"
        width="600"
        ref="cobroDialog"
        v-model="cobroDialog"
        @click:outside="cerrarDialogCobro()"
      >
        <v-card>
          <v-card-title>
            <p class="display-1">Cobro</p>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <div class="subtitle-1">Monto a pagar</div>
            <p class="primary--text display-1">
              ${{ factura.totalDiferencia }}
            </p>

            <div>
              <v-text-field
                type="number"
                dense
                outlined
                prefix="$"
                min="0"
                :rules="montoEfectivoRules"
                v-model="montoEfectivo"
                placeholder="$0.00"
                label="Efectivo"
              ></v-text-field>
            </div>

            <div v-if="montoEfectivo <= 0">
              <div class="subtitle-1">Pendiente</div>
              <p class="display-1 secondary--text">
                ${{ factura.totalDiferencia.toFixed(2) }}
              </p>
            </div>

            <div v-else>
              <div class="subtitle-1">Devolución</div>
              <p class="display-1 primary--text">
                ${{ factura.montoDevolucion.toFixed(2) }}
              </p>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              rounded
              type="submit"
              @click="guardarFactura()"
              color="primary"
            >
              <v-icon left>mdi-check</v-icon>Aceptar
            </v-btn>
            <v-btn outlined rounded color="error" @click="cerrarDialogCobro()">
              <v-icon left>mdi-close</v-icon>Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
import MainLayout from "../../layouts/MainLayout.vue";
import PacienteHeader from "../pacientesGroup/PacienteHeader.vue";
import { imprimirFactura } from "../../utility/reporting.js";
import { parseJwt } from "../../utility/jwtHelper.js";
import axios from "../../shared/jwtAxios.js";

export default {
  name: "facturacion",
  components: {
    PacienteHeader,
  },
  created() {
    this.getNextNumeroFactura();
    this.getArticulos();
    this.factura.estado = this.estadosFacturas[0];
    this.factura.tipo = this.tiposFacturas[0];
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
    document.getElementById("cmbArticulos").focus();
  },
  data() {
    return {
      firmaDoctor:
        this.$store.state.jwtToken !== null
          ? parseJwt(this.$store.state.jwtToken).firma
          : "",
      loading: false,
      menu: false,

      //DIALOG COBRO
      cobroDialog: false,
      montoEfectivo: 0.0,

      //ARTICULOS
      articulosFromDB: [],
      articulos: [],
      articuloSeleccionado: {
        id: 0,
        uniqueId: "",
        cuentaId: 0,
        descripcion: "",
        precio: 0,
        fechaCreacion: "",
        quienRegistra: 0,
        activo: true,
      },
      articulosSeleccionados: [],
      searchArticulo: null,

      //PACIENTE
      paciente: {},
      hayPacienteSelecionado: false,
      limpiarPacienteSeleccionado: false, //PROPIEDAD QUE AFECTA EN EL COMPONENT paciente-header.

      //FACTURAS
      numeroFactura: null,
      tiposFacturas: ["CONTADO", "CREDITO"],
      estadosFacturas: ["COBRADA", "PENDIENTE", "CANCELADA"],
      factura: {
        id: 0,
        cuentaId: 0,
        pacienteId: 0,
        estado: "",
        tipo: "",
        fecha: new Date().toISOString().substr(0, 10),
        fechaCreacion: new Date().toISOString().substr(0, 10),
        numeroAutorizacion: "",
        observacion: "",
        porcientoDescuento: 0.0,
        subTotal: 0.0,
        totalCobertura: 0.0,
        totalDescuento: 0.0,
        totalDiferencia: 0.0,
        totalReclamado: 0.0,
        pendientePorPagar: 0.0,
        totalItbis: 0.0,
        total: 0.0,
        montoEfectivo: 0.0,
        montoDevolucion: 0.0,
        montoPagado: 0.0,
        quienRegistra: 0,
        activo: true,
        articulos: [],
      },
      //VALIDACIONES
      montoEfectivoRules: [
        (v) =>
          (v > 0 && this.factura.totalDiferencia > 0) ||
          "El monto es requerido para poder procesar la factura.",
      ],
      numeroAutorizacionRules: [(v) => !!v || ""],
      //TOTALES
      resultadoDescuento: 0.0,
    };
  },
  methods: {
    abrirDialogCobro() {
      this.cobroDialog = true;
    },
    cerrarDialogCobro() {
      this.cobroDialog = false;
      this.montoEfectivo = 0.0;
    },
    getNextNumeroFactura() {
      axios
        .get(`${this.$apiConfig.facturasUrl}/${this.$store.state.cuentaId}`)
        .then((response) => {
          this.factura.id = response.data;
          this.numeroFactura = response.data;
        })
        .catch((error) => window.console.log(error));
    },
    guardarFactura() {
      if (this.factura.totalDescuento >= 100) {
        this.montoEfectivoRules = [];
      }
      if (this.$refs.formFactura.validate()) {
        this.factura.cuentaId = parseInt(this.$store.state.cuentaId);
        this.factura.pacienteId = parseInt(this.paciente.id);
        this.factura.porcientoDescuento = parseFloat(
          this.factura.porcientoDescuento
        );
        this.factura.montoEfectivo = parseFloat(this.montoEfectivo);
        this.factura.montoPagado = this.factura.montoEfectivo;
        this.factura.quienRegistra = parseInt(this.$store.state.usuarioId);
        this.factura.articulos = this.articulosSeleccionados;

        axios
          .post(this.$apiConfig.facturasUrl, this.factura)
          .then(() => {
            this.$datosGuardados();
            this.getNextNumeroFactura();
            this.cerrarDialogCobro();
            imprimirFactura(this.factura, this.paciente);
            this.limpiar();
          })
          .catch((error) => window.console.log(error.response));
      }
    },
    calcularTotales() {
      this.factura.subTotal = 0.0;
      this.factura.totalItbisitbis = 0.0;
      this.factura.totalCobertura = 0.0;
      this.factura.totalDescuento = 0.0;
      this.factura.totalDiferencia = 0.0;
      this.factura.total = 0.0;

      this.articulosSeleccionados.forEach((art) => {
        art.cantidad = parseInt(art.cantidad) || 0;
        art.cobertura = parseFloat(art.cobertura) || 0;
        art.porcientoCobertura = parseFloat(art.porcientoCobertura) || 0;

        this.factura.subTotal +=
          parseFloat(parseInt(art.cantidad) * art.precio) || 0;
        this.factura.totalItbisitbis += parseFloat(art.itbis) || 0;

        if (event.target.id === "txtPorcientoCobertura") {
          art.cobertura =
            parseFloat((art.porcientoCobertura * art.total) / 100) || 0;
        }

        if (event.target.id === "txtCobertura") {
          art.porcientoCobertura =
            parseFloat((art.cobertura / art.total) * 100) || 0;
        }

        if (art.cobertura > art.total) {
          this.$warningAlert("La cobertura no puede ser mayor que el total.");
          art.porcientoCobertura = art.cobertura = 0;
          return;
        }

        art.total = parseFloat(parseInt(art.cantidad) * art.precio || 0);
        art.diferencia = art.total - art.cobertura;

        this.factura.totalCobertura += parseFloat(art.cobertura) || 0;
        this.factura.totalDiferencia += parseFloat(art.diferencia) || 0;
        this.factura.total += parseFloat(art.total) || 0;
      });
      this.factura.totalDescuento =
        parseFloat(
          (this.factura.porcientoDescuento * this.factura.totalDiferencia) / 100
        ) || 0;
      this.factura.totalDiferencia -= this.factura.totalDescuento;
      if (this.factura.totalDescuento > this.factura.total) {
        this.$warningAlert("El descuento no puede ser mayor que el total.");
        document.getElementById("txtPorcientoDescuento").focus();
        this.factura.totalDescuento = 0;
        this.factura.porcientoDescuento = 0;
        this.factura.totalDiferencia = this.factura.subTotal;
        return;
      }
    },
    getArticulos() {
      axios
        .get(`${this.$apiConfig.articulosUrl}/${this.$store.state.cuentaId}`)
        .then((response) => {
          this.articulosFromDB = response.data;
        })
        .catch((error) => {
          window.console.log(error.response.data);
        });
    },
    quitarArticulo(artSeleccionado) {
      this.articulosSeleccionados.splice(
        this.articulosSeleccionados.indexOf(artSeleccionado),
        1
      );
      this.calcularTotales();
      this.getArticulos();
    },

    seleccionarArticulo(artSeleccionado) {
      if (this.articulosSeleccionados.includes(artSeleccionado, 0)) {
        return;
      } else {
        artSeleccionado.cantidad = 1;
        this.articulosSeleccionados.push(artSeleccionado);
        this.calcularTotales();
      }
    },
    filtrarArticulos(val) {
      if (val) {
        this.loading = true;
        setTimeout(() => {
          this.articulos = this.articulosFromDB.filter((art) => {
            return art.descripcion.toLowerCase().includes(val.toLowerCase());
          });
          this.loading = false;
        }, 500);
      }
    },
    limpiarArticuloSeleccionado() {
      this.articuloSeleccionado = {
        id: 0,
        uniqueId: "",
        cuentaId: 0,
        descripcion: "",
        precio: 0,
        fechaCreacion: "",
        quienRegistra: 0,
        activo: true,
      };
    },
    onPacienteSelecionado(paciente) {
      this.paciente = paciente;
    },
    limpiar() {
      this.factura = {
        id: 0,
        cuentaId: 0,
        pacienteId: 0,
        estado: this.estadosFacturas[0],
        tipo: this.tiposFacturas[0],
        fecha: new Date().toISOString().substr(0, 10),
        fechaCreacion: new Date().toISOString().substr(0, 10),
        numeroAutorizacion: "",
        observacion: "",
        porcientoDescuento: 0.0,
        subTotal: 0.0,
        totalCobertura: 0.0,
        totalDescuento: 0.0,
        totalDiferencia: 0.0,
        totalReclamado: 0.0,
        pendientePorPagar: 0.0,
        totalItbis: 0.0,
        total: 0.0,
        montoEfectivo: 0.0,
        montoDevolucion: 0.0,
        montoPagado: 0.0,
        quienRegistra: 0,
        activo: true,
        articulos: [],
      };
      this.montoEfectivo = 0;
      this.articulosSeleccionados = [];
      this.paciente = {};
      this.limpiarPacienteSeleccionado = true;
      this.getArticulos();
    },
  },
  watch: {
    searchArticulo(val) {
      this.filtrarArticulos(val);
    },
    articuloSeleccionado(val) {
      if (val === null || val.id <= 0) {
        return;
      }
      this.seleccionarArticulo(val);
      this.limpiarArticuloSeleccionado();
    },
    montoEfectivo(value) {
      this.factura.montoDevolucion = parseFloat(
        value - this.factura.totalDiferencia
      );
    },
    paciente(value) {
      this.hayPacienteSelecionado = value ? true : false;
    },
  },
};
</script>
