<template>
  <div id="roles-usuarios">
    <registro-rol-usuario
      v-bind:dialog.sync="dialog"
      :rolUsuarioSeleccionado="rolUsuario"
      @cerrarModal="onCerrarModal"
    ></registro-rol-usuario>

    <listado-generico
      @crearItem="crear"
      @seleccionarItem="editar"
      :esRegistrar="true"
      :itemsPorPagina="itemsPorPagina"
      :encabezados="encabezados"
      :items="rolesUsuarios"
    ></listado-generico>
  </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import RegistroRolUsuario from "../components/cuentas/RegistroRolUsuario.vue";
import ListadoGenerico from "../components/indicaciones/ListadoGenerico.vue";
import jwtAxios from "../shared/jwtAxios.js";

export default {
  components: {
    RegistroRolUsuario,
    ListadoGenerico,
  },
  name: "roles-usuarios",
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  created() {
    this.get();
  },
  data() {
    return {
      encabezados: [
        { text: "Descripcion", value: "descripcion" },
        { text: "", value: "actions", sortable: false },
      ],
      rolUsuario: { id: 0, descripcion: "", activo: true },
      rolesUsuarios: [],
      itemsPorPagina: 5,
      dialog: false,
    };
  },
  methods: {
    get() {
      jwtAxios
        .get(this.$apiConfig.rolesUsuariosUrl)
        .then((response) => {
          this.rolesUsuarios = response.data;
        })
        .catch((error) => {
          this.$errorCargarDatos();
          window.console.log(error);
        });
    },
    editar(rolUsuarioParam) {
      this.rolUsuario = rolUsuarioParam;
      this.mostrarModal();
    },
    crear() {
      this.mostrarModal();
    },
    onCerrarModal() {
      this.dialog = false;
      this.limpiar();
      this.get();
    },
    mostrarModal() {
      this.dialog = true;
    },
    limpiar() {
      this.rolUsuario = { id: 0, descripcion: "", activo: true };
      this.rolUsuarioSeleccionado = {};
    },
  },
};
</script>
