<template>
  <v-container id="listado-pacientes">
    <v-layout class="mt-1">
      <v-text-field
        v-model="busqueda"
        dense
        outlined
        label="Buscar"
        :append-icon="iconoCrear"
        @keyup.enter="onBuscarPaciente()"
        @click:append="crearPaciente()"
      ></v-text-field>
    </v-layout>

    <div v-if="pacientes.length <= 0" text-center>
      <v-alert type="info" dense outlined>No hay datos disponibles.</v-alert>
    </div>

    <v-flex v-else>
      <v-data-table
        :headers="headers"
        disable-sort
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        disable-filtering
        :items="pacientesFiltrados"
        item-key="row_Id"
      >
        <template v-slot:header-item="{ item }">
          <th :class="{ colorResaltado: item.resaltado === true }">
            <p>
              {{ item.nombreCompleto }}
            </p>
          </th>
        </template>

        <template v-slot:expanded-item="{ item }">
          <v-container>
            <v-layout row no-gutters>
              <v-flex xs12 md3><p class="font-weight-black">Cédula</p> </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">{{ item.cedula }}</p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3><p class="font-weight-black">Sexo</p> </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">{{ item.sexo }}</p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3
                ><p class="font-weight-black">Seguro Médico</p>
              </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.seguroMedico.descripcion }}
                </p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3
                ><p class="font-weight-black">No. Afiliado</p>
              </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.numeroAfiliado }}
                </p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3
                ><p class="font-weight-black">Fecha Nacimiento</p>
              </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.fechaNacimiento }}
                </p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3><p class="font-weight-black">Edad</p> </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.edad }}
                </p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3
                ><p class="font-weight-black">Celular</p>
              </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.celular }}
                </p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3
                ><p class="font-weight-black">Teléfono</p>
              </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.telefono }}
                </p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3
                ><p class="font-weight-black">Dirección</p>
              </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.direccion }}
                </p>
              </v-flex>
            </v-layout>

            <v-layout row no-gutters>
              <v-flex xs12 md3
                ><p class="font-weight-black">Correo Electrónico</p>
              </v-flex>
              <v-flex xs12 md9>
                <p class="font-weight-regular">
                  {{ item.correoElectronico }}
                </p>
              </v-flex>
            </v-layout>
          </v-container>
        </template>

        <template v-slot:item.actions="{ item }" class="text-lg-right">
          <th>
            <v-tooltip bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-1"
                  small
                  rounded
                  outlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="asignarTurno(item)"
                >
                  <v-icon color="primary"> mdi-ticket</v-icon>
                </v-btn></template
              >
              <span>Asignar Turno</span>
            </v-tooltip>
          </th>
          <th>
            <v-tooltip bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-1"
                  small
                  rounded
                  outlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="resaltarPaciente(item)"
                >
                  <v-icon color="primary"> mdi-palette</v-icon>
                </v-btn></template
              >
              <span>Resaltar</span>
            </v-tooltip>
          </th>
          <th>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-1"
                  small
                  rounded
                  outlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="editarPaciente(item)"
                >
                  <v-icon color="primary"> mdi-pencil</v-icon>
                </v-btn></template
              >
              <span>Modificar Paciente</span>
            </v-tooltip>
          </th>
          <th>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-1"
                  small
                  rounded
                  outlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="registrarCita(item)"
                >
                  <v-icon color="primary"> mdi-calendar</v-icon>
                </v-btn></template
              >
              <span>Registrar Cita</span>
            </v-tooltip>
          </th>
          <th>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-1"
                  small
                  rounded
                  outlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="crearHistoriaClinica(item)"
                >
                  <v-icon color="primary"> mdi-doctor</v-icon>
                </v-btn></template
              >
              <span>Ver Consultas</span>
            </v-tooltip>
          </th>

          <th>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-1"
                  small
                  rounded
                  outlined
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="eliminarPaciente(item)"
                >
                  <v-icon color="error"> mdi-close</v-icon>
                </v-btn></template
              >
              <span>Eliminar Paciente</span>
            </v-tooltip>
          </th>
        </template>
      </v-data-table>
    </v-flex>

    <consultas-anteriores
      :paciente="paciente"
      :dialogConsultasAnteriores="dialogConsultasAnteriores"
      :historiasClinicas="historiasClinicas"
      @cerrarModalConsultasAnteriores="cerrarModalConsultasAnteriores"
      @onHistoriaClinicaCancelada="onHistoriaClinicaCancelada"
    ></consultas-anteriores>

    <overlay :overlay="this.$store.getters.viewOverlay"></overlay>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { Rol } from "@/utility/roles";
import axios from "@/shared/jwtAxios.js";
import Overlay from "../helpers/Overlay.vue";
import ConsultasAnteriores from "@/components/historiasClinicasGroup/ConsultasAnteriores";

export default {
  name: "listado-pacientes",
  components: {
    ConsultasAnteriores,
    Overlay,
  },
  mounted() {
    this.puedeConsultar = Rol.Secretaria !== this.$store.getters.rolUsuario;
  },
  data() {
    return {
      benched: 0, //PROPIEDAD UTILIZADA PARA EL V-VIRTUAL-SCROLL. FUNCIONA PARA MEJORAR EL PERFORMANCE DE DICHO CONTROL.
      busqueda: "",
      iconoCrear: "mdi-plus",
      puedeConsultar: true,
      totalPacientes: 0,
      expanded: [],
      singleExpand: false,
      paciente: {},
      historiasClinicas: [],
      dialogConsultasAnteriores: false,
      headers: [
        { text: "", value: "nombreCompleto" },
        { text: "", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  props: {
    esRegistrar: Boolean,
    itemsPorPagina: Number,
    pacientes: Array,
    encabezados: Array,
  },
  computed: {
    rows() {
      return this.pacientes !== null && this.pacientes.length;
    },
    pacientesFiltrados: function () {
      return this.pacientes.filter((paciente) => {
        return (
          paciente.nombreCompleto
            .toUpperCase()
            .includes(this.busqueda.toUpperCase()) ||
          paciente.cedula.includes(this.busqueda.trim())
        );
      });
    },
    pacienteItemHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
        case "lg":
        case "xl":
          return 100;
      }
    },
    actionsButtonsWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null;
        case "sm":
          return null;
        case "md":
          return 100;
        case "lg":
          return 100;
        case "xl":
          return 100;
      }
    },
    toggleActionsButtonsText() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
  },
  methods: {
    crearPaciente() {
      this.$emit("crearPaciente", true);
    },

    asignarTurno(pacienteParam) {
      this.$emit("asignarTurno", pacienteParam);
    },

    editarPaciente(pacienteParam) {
      this.$emit("editarPaciente", pacienteParam);
    },

    resaltarPaciente(pacienteParam) {
      this.$emit("resaltarPaciente", pacienteParam);
    },

    getConsultasAnteriores() {
      axios
        .get(
          this.$apiConfig.historiasClinicasUrl +
            "/getByPaciente/" +
            this.paciente.id
        )
        .then((response) => {
          this.historiasClinicas = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    mostrarModalConsultasAnteriores() {
      this.dialogConsultasAnteriores = true;
    },
    crearHistoriaClinica(pacienteParam) {
      this.paciente = pacienteParam;
      this.getConsultasAnteriores();
      this.mostrarModalConsultasAnteriores();
    },
    cerrarModalConsultasAnteriores() {
      this.dialogConsultasAnteriores = false;
    },
    onHistoriaClinicaCancelada() {
      this.getConsultasAnteriores();
    },

    eliminarPaciente(pacienteParam) {
      swal({
        text: "¿Desea eliminar este paciente?",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Si",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "No",
            value: null,
            visible: true,
            closeModal: true,
          },
        },
      }).then((quereEliminar) => {
        if (quereEliminar) {
          pacienteParam.activo = false;
          axios
            .post(this.$apiConfig.pacientesUrl, pacienteParam)
            .then(() => {
              this.$emit("onPacienteEliminado");
              this.$datosGuardados();
            })
            .catch((error) => {
              this.$errorGuardarDatos();
              window.console.log(error);
            });
        }
      });
    },
    registrarCita(pacienteParam) {
      this.$emit("registrarCita", pacienteParam);
    },
    seleccionarPaciente(pacienteParam) {
      this.$emit("seleccionarPaciente", pacienteParam);
    },
    onBuscarPaciente() {
      this.$emit("onBuscarPaciente", this.busqueda);
    },
  },
};
</script>

<style scoped v-lang="scss">
h1,
h2,
h3,
h4 {
  color: var(-v-primary-base);
}

.action-button {
  color: #1c7494;
  border: 1px solid #1c7494;
  padding: 6px 8px;
  margin: 0px 1px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
}

.action-button:hover {
  background-color: rgba(28, 116, 148, 0.05);
}

.colorResaltado {
  color: #c52121 !important;
}

.pacientes-container {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 10px;
}

#titulo-pacientes-cargados {
  margin-bottom: 30px;
}

@media screen and (min-width: 640px) {
  .action-button {
    width: 80px;
    margin: 0px 20px 0px 0px;
  }
}
</style>
