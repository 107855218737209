<template>
  <div>
    <v-alert dense type="info"
      >Se ha enviado un código de verificación a su correo.</v-alert
    >
    
    <mensaje v-bind:mensaje="mensajeError" v-bind:tipo-mensaje="tipoMensaje"></mensaje>
    
    <v-form  @submit.prevent="verificar"
             ref="formverificar"
             lazy-validation
      >
      
      <v-text-field
          outlined
          dense
          type="text"
          v-model="codigoVerificador"
          id="txtCodigo"
          ref="txtCodigo"
          :rules="codigoRules"
          placeholder="Digite el código"
          label="Código"
      ></v-text-field>

      <v-btn text color="primary" type="submit" >Validar</v-btn>
    </v-form>

    <v-overlay :value="this.$store.getters.viewOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Mensaje from "../helpers/Mensaje.vue";
import store from "../../state/index.js";

export default {
  name: "verificar-codigo",
  components: {
    Mensaje,
  },
  mounted() {
    document.getElementById("txtCodigo").focus();
    this.codigoVerificado = store.state.codigoVerificador;
  },
  data() {
    return {
      codigoVerificado: "",
      codigoVerificador: "",
      puedeEntrar: false,
      mensajeError: "",
      tipoMensaje: "error",
      //VALIDACIONES
      codigoRules: 
          [
              (v) => !!v || "El código es requerido.",
              (v) => v === store.state.codigoVerificador.toString() || "El código que intenta insertar es inválido."
          ],
    };
  },
  methods: {
    verificar() {
      try {
        if (this.$refs.formverificar.validate()) {
          this.onCodigoVerificado();
        }
      } catch (error) {
        this.mensajeError = error.response.data;
        this.tipoMensaje = "error";
        window.console.log(error);
      }
    },
    onCodigoVerificado() {
      this.$emit("onCodigoVerificado");
    },
  },
};
</script>

<style scoped>
  .centered-text {
    text-align: center;
  }
</style>
