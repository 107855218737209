<template>
  <div id="configuracion">
    <h1>Configuración</h1>
    <v-tabs>
      <v-tab href="#tabPerfilMedico">Perfil médico</v-tab>

      <v-tab v-if="esAdministrador" href="#tabEspecialidades"
        >Especialidades</v-tab
      >

      <v-tab href="#tabUsuarios">Usuarios</v-tab>

      <v-tab v-if="esAdministrador" href="#tabRolesUsuarios"
        >Roles de usuario</v-tab
      >

      <v-tab href="#tabConfiguracionImpresion"
        >Configuración de impresión</v-tab
      >

      <v-tab-item class="pa-10" :value="tabs.tabPerfilMedico">
        <registro-medicos></registro-medicos>
      </v-tab-item>

      <v-tab-item class="pa-10" :value="tabs.tabUsuarios">
        <usuarios-view></usuarios-view>
      </v-tab-item>

      <v-tab-item class="pa-10" :value="tabs.tabEspecialidades">
        <especialidades-view></especialidades-view>
      </v-tab-item>

      <v-tab-item class="pa-10" :value="tabs.tabRolesUsuarios">
        <roles-usuarios-view></roles-usuarios-view>
      </v-tab-item>

      <v-tab-item class="pa-10" :value="tabs.tabConfiguracionImpresion">
        <configuracion-impresion></configuracion-impresion>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import RegistroMedicos from "@/components/medicos/RegistroMedicos.vue";
import UsuariosView from "./UsuariosView.vue";
import RolesUsuariosView from "./RolesUsuariosView.vue";
import ConfiguracionImpresion from "@/components/configuraciones/ConfiguracionReporte.vue";
import EspecialidadesView from "../views/EspecialidadesView";
import { Rol } from "@/utility/roles.js";

export default {
  name: "configuracion",
  components: {
    RegistroMedicos,
    UsuariosView,
    RolesUsuariosView,
    EspecialidadesView,
    ConfiguracionImpresion,
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  data() {
    return {
      tabs: {
        tabPerfilMedico: "tabPerfilMedico",
        tabUsuarios: "tabUsuarios",
        tabConfiguracionImpresion: "tabConfiguracionImpresion",
        tabRolesUsuarios: "tabRolesUsuarios",
        tabEspecialidades: "tabEspecialidades",
      },
    };
  },
  computed: {
    esAdministrador() {
      return this.$store.getters.rolUsuario === Rol.Admin;
    },
  },
};
</script>
