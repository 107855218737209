<template>
  <div>
    <registro-articulo
      ref="registroArticulo"
      :dialog.sync="dialog"
      :articuloSeleccionado="articulo"
      @onGuardar="onGuardar"
      @cancelar="cancelar"
    ></registro-articulo>

    <listado-articulos
      :esRegistrar="true"
      :articulos="articulos"
      @crearArticulo="crearArticulo"
      @seleccionarArticulo="editarUsuario"
    ></listado-articulos>
  </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import RegistroArticulo from "../components/articulos/RegistroArticulo";
import ListadoArticulos from "../components/articulos/ListadoArticulos.vue";
import axios from "../shared/jwtAxios";
import store from "../state/index.js";

export default {
  name: "articulo-view",
  components: {
    RegistroArticulo,
    ListadoArticulos,
  },
  created() {
    this.getArticulos();
  },
  mounted() {
    this.$emit("update:layout", MainLayout);
  },
  data() {
    return {
      dialog: false,
      articulo: {},
      articulos: [],
    };
  },
  methods: {
    getArticulos() {
      axios
        .get(`${this.$apiConfig.articulosUrl}/${store.state.cuentaId}`)
        .then((response) => {
          this.articulos = response.data;
        })
        .catch((error) => {
          window.console.log(error.response.data);
        });
    },
    mostrarDialog() {
      this.dialog = true;
    },
    limpiar() {
      this.articulo = {};
    },
    cancelar() {
      this.dialog = false;
      this.limpiar();
    },
    crearArticulo() {
      this.mostrarDialog();
    },
    editarUsuario(articuloParam) {
      this.articulo = articuloParam;
      this.mostrarDialog();
    },
    onGuardar() {
      this.getArticulos();
      this.cancelar();
    },
  },
};
</script>
