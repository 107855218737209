<template>
  <div id="listado-usuarios">
    <div class="mt-2"></div>

    <div>
      <v-data-table
        :headers="encabezados"
        :items="usuariosFiltrados"
        :items-per-page="itemsPorPagina"
        :loading="isLoading"
        loading-text="Cargando datos... Por favor espere"
        class="elevation-1"
      >
        <template v-slot:top>
          <div class="mx-4">
            <v-row>
              <v-text-field
                v-model="busqueda"
                label="Buscar"
                ref="txtBuscar"
                :append-icon="iconoCrear"
                @click:append="crear()"
              ></v-text-field>
            </v-row>
          </div>
        </template>

        <template v-slot:item.activo="{ item }">
          <div>
            <label>{{ item.activo | boolaletra }}</label>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" v-if="esRegistrar" @click="seleccionar(item)"
            >mdi-pencil</v-icon
          >
          <v-icon color="primary" v-else @click="seleccionar(item)"
            >mdi-share</v-icon
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "listado-usuarios",
  mounted() {
    this.$refs.txtBuscar.focus();
  },
  data() {
    return {
      busqueda: "",
      iconoCrear: "mdi-plus",
    };
  },
  props: {
    usuarios: Array,
    esRegistrar: Boolean, //Propiedad que decide si mostrar el boton editar o el boton de seleccionar.
    encabezados: Array,
    itemsPorPagina: Number,
  },
  computed: {
    rows() {
      return this.usuarios.length;
    },
    usuariosFiltrados: function() {
      return this.usuarios.filter((usuario) => {
        return (
          usuario.nombre.toUpperCase().includes(this.busqueda.toUpperCase()) ||
          usuario.apellido
            .toUpperCase()
            .includes(this.busqueda.toUpperCase()) ||
          usuario.email.toUpperCase().includes(this.busqueda.toUpperCase())
        );
      });
    },
    isLoading() {
      return this.usuarios.length <= 0;
    },
  },
  methods: {
    crear() {
      this.$emit("crearUsuario", null);
    },
    seleccionar(usuarioParam) {
      this.$emit("seleccionarUsuario", usuarioParam);
    },
  },
};
</script>
